// @ts-nocheck
import { useEffect, useState } from 'react';

import { api } from 'helpers/api';
import { useTenants } from 'context/tenants';
import { useDashboard } from 'context/dashboard';
import { TSuccessNotifications } from 'types/index';
import { setMetrics } from 'actions/dashboard';
import { METRICS } from 'reducers/dashboard';
// import { apiUrls } from 'helpers/apiUrls';

/**
 * @description useMakeRequestGetInstancesByClick - a hook that returns a function
 * that makes an API /GET request to get all instances
 * @returns handleGetInstances
 */

export default function useGetMetrics() {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | unknown>(0);

  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  // const url = `${apiUrls.metrics}${selectedTenantId}`;
  const context = useDashboard();
  const dispatch = context[1];

  // useEffect(() => {
  //   const controller = new AbortController();
  //   const signal = controller.signal;

  //   async function loadData() {
  //     const request = api.useFetchData<[], IMetrics>(url, 'get', signal);
  //     try {
  //       setLoading(true);
  //       const getMetrics = await request;
  //       dispatch(setMetrics(getMetrics));
  //       setError('');
  //     }
  //     catch (error) {
  //       if (error instanceof Error) {
  //         setStatus(error?.cause);
  //         setError(error?.message || '');
  //       }
  //       dispatch(setMetrics(METRICS));
  //     }
  //     finally {
  //       setLoading(false);
  //     }
  //   }

  //   loadData();

  //   return () => {
  //     controller.abort();
  //     dispatch(setMetrics(METRICS));
  //   };
  // }, [url, dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function loadData() {
      setLoading(true);

      const promiseGetInstances = api.useFetchData<[], TSuccessNotifications>(`vm/vms/${selectedTenantId}?brief=true`, 'get', signal);
      const promiseGetNetworks = api.useFetchData<[], TSuccessNotifications>(`network/networks/${selectedTenantId}?brief=true`, 'get', signal);
      const promiseGetIpv4s = api.useFetchData<[], TSuccessNotifications>(`network/ipv4s/${selectedTenantId}?brief=true`, 'get', signal);
      const promiseGetSshkeys = api.useFetchData<[], TSuccessNotifications>(`sshkeys/${selectedTenantId}`, 'get', signal);

      try {
        const request = await Promise.allSettled([promiseGetInstances, promiseGetNetworks, promiseGetIpv4s, promiseGetSshkeys]);

        request.forEach((item, i) => {
          item.id = i + 1;
          item.count = item?.value?.length || 0;
        });

        const newObj = request.map((item, index) => {
          if (item.id === 1) {
            const obj = {
              ...item,
              key: 'instances',
            };

            return obj;
          };

          if (item.id === 2) {
            const obj = {
              ...item,
              key: 'networks',
            };
            return obj;
          };

          if (item.id === 3) {
            const obj = {
              ...item,
              key: 'ipv4s',
            };

            return obj;

          };

          if (item.id === 4) {
            const obj = {
              ...item,
              key: 'sshKeys',
            };

            return obj;

          };

          return item;
        });

        const instances = newObj.filter((item) => item.key === 'instances');
        const networks = newObj.filter((item) => item.key === 'networks');
        const ipv4s = newObj.filter((item) => item.key === 'ipv4s');
        const sshKeys = newObj.filter((item) => item.key === 'sshKeys');

        // const getMetrics = await request;

        const newMetrics = {
          tenant_uuid: null,
          instances: instances[0].count,
          ipv4s: ipv4s[0].count,
          networks: networks[0].count,
          created: null,
          last_updated: null,
          ssh_keys: sshKeys[0].count,
        };

        dispatch(setMetrics(newMetrics));
        setError('');
      }
      catch (error) {
        if (error instanceof Error) {
          setStatus(error?.cause);
          setError(error?.message || '');
        }
        dispatch(setMetrics(METRICS));
      }
      finally {
        setLoading(false);
      }
    }

    loadData();

    return () => {
      controller.abort();
      dispatch(setMetrics(METRICS));
    };
  }, [dispatch, selectedTenantId]);

  return { error, loading, status };
};
