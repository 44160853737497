import Typography from 'components/typography';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
import useLogout from 'hooks/useLogout';
import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';
import useGetMetricsNetworks from 'hooks/useGetMetricsNetworks';

import Content from './Content';

import classes from '../../overview.module.scss';
import glossary from '../../../../data/glossary.json';
import EmptyBlock from '../../emptyBlock';

export default function NetworksSection(){
  // variables
  const { handlePagePaths } = useGetRoutesPagePath();
  const { error, loading, status, data } = useGetMetricsNetworks();
  const { handleLogout } = useLogout();

  // style
  const { grayWrapperSectionClass, grayWrapperSectionContentClass } = classes;

  if (status === 403) {
    handleLogout();
    return <Info status='error' msg={error} />;
  };

  return (
    <div className={`${grayWrapperSectionClass}`}>
      <Typography
        text={glossary.networks_plural}
        fontSize='sm'
        customStyle='m-6'
      />
      <hr />
      {(error && status !== 404) ?
        <Info status='error' msg={error} /> :
        null
      }
      {loading ?
        <Spinner spaceAroundSpinner />
        : null
      }
      <div className={`${grayWrapperSectionContentClass}`}>
        {(!loading && !error) ?
          <>
            {data?.count === 0 ?
              <EmptyBlock
                btnTitle={`${glossary.actions.create} ${glossary.network}`}
                redirectTo={handlePagePaths().newNetwork}
              />
              :
              <Content
                data={data}
              />
            }
          </>
          :
          null
        }
      </div>
    </div>
  );
};
