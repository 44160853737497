import React, { forwardRef, useEffect } from 'react';

import Spinner from 'components/spinners/Spinner';
import { useInstance } from 'context/instance';
import { useDashboard } from 'context/dashboard';
import { setInstance, setInstanceValidation } from 'actions/instance';
import { INetwork } from 'types';
import { useCleanState } from 'hooks/useCleanState';
import CustomSelect from 'components/input/Select';

import classes from '../instance.module.scss';

import { useFilterNetworks } from '../../../hooks/useFilterNetworks';
import glossary from '../../../data/glossary.json';

const CustomNetworkSelect = forwardRef<HTMLDetailsElement, {dataTestId: {name: string}}>((props, ref) => {
  // props
  const { dataTestId } = props;

  // variables
  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const {
    instance,
    validation,
  } = stateInstance;
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { loading } = fetchFunc.networks.get;
  const cleanState = useCleanState();
  const filteredNetwork = useFilterNetworks();
  const networks = instance.location === 'Sweden' ? filteredNetwork.sweden : filteredNetwork.norway;
  const clusterName = instance.location === 'Sweden' ? 'ndcloud-arcticcircle' : 'ndcloud-underground';
  const { sectionWrapperClass } = classes;

  useEffect(() => {
    return () => {
      cleanState('network', 'post');
    };
  }, [cleanState]);

  // func
  const handleSelect = (name: string, uuid: string) => {
    const networkArray = networks.filter((network: INetwork) => network.uuid === uuid);
    const networkObject = {};
    for(let i = 0; i < networkArray.length; i++ ) {
      Object.assign(networkObject, networkArray[i]);
    }
    dispatchInstance(setInstance(networkObject, 'network'));
    dispatchInstance(setInstanceValidation(false, 'network'));
    dispatchInstance(setInstance('', 'error'));
  };

  return (
    <>
      {loading ?
        <div className={`${sectionWrapperClass} justify-center`}>
          <Spinner />
        </div>
        :
        <CustomSelect
          handleChange={handleSelect}
          name='Network'
          label='Network'
          value={instance.network.network_name}
          isShowLabel={false}
          classNames='w-full h-[42px] px-4 py-2 font-medium'
          disabled={networks.length === 0 ? true : false}
          textDisabled={networks.length === 0 ? glossary.empty_state.you_dont_have_networks : glossary.network}
          selectFeedback={validation.network}
          options={networks}
          optionValue='network_name'
          dataTestId={dataTestId.name}
        />
      }
      <p className='flex items-start text-xxxs font-normal mt-5px ml-4'>
        Network option for <b className='ml-1'>{clusterName} - {instance.location}</b>
      </p>
    </>
  );
});

export default CustomNetworkSelect;
