import React, { useCallback, useEffect, useState }  from 'react';
import { useHistory } from 'react-router-dom';

import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { useInstance } from 'context/instance';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
import { useTenants } from 'context/tenants';
import { useChangeTenant } from 'hooks/useChangeTenant';

/**
 * @description ConfirmationDialog - a component that blocks the transition to another location,
 * is used only for Create new vm, checks if the tenant is the same
 * @returns React Fragment | Portal Modal Window
 */

export default function ConfirmationDialog() {
  // state
  const [showWindowLeavePage, setShowWindowIsLeavePage] = useState<boolean>(false);
  const [transitionLeave, setTransitionLeave] = useState({
    onOk: false,
    path: '',
  });

  // variables
  const history = useHistory();
  const context = useInstance();
  const state = context[0];
  const { validation } = state;
  const { handlePagePaths } = useGetRoutesPagePath();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId, preselectedTenant } = stateTenants;
  const { changeTenant, resetTenant } = useChangeTenant();

  useEffect(() => {
    if (Object.values(validation).some(item => item === false)) {
      setShowWindowIsLeavePage(true);
    };
    return () => {
      setShowWindowIsLeavePage(false);
    };
  }, [validation]);

  const handleCloseModal = () => {
    setShowWindowIsLeavePage(false);
    resetTenant();
  };

  const handleGoToIntendedPage = useCallback((location: string) => {
    history.push(location);
  }, [history]);

  useEffect(() => {
    if (transitionLeave.onOk) {
      handleGoToIntendedPage(transitionLeave.path);
    };

    const unblock = history.block((location) => {
      if (!location.pathname.includes(handlePagePaths(selectedTenantId).newInstance)) {
        setShowWindowIsLeavePage(true);
      };

      setTransitionLeave((obj) => ({ ...obj, path: location.pathname }));

      if (transitionLeave.onOk) {
        return unblock();
      };

      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    transitionLeave.onOk,
    transitionLeave.path,
    handlePagePaths,
    selectedTenantId,
  ]);

  useEffect(() => {
    if (!showWindowLeavePage) {
      setTransitionLeave({
        path: '',
        onOk: false,
      });
    }
  }, [showWindowLeavePage]);

  const handleLeavePage = () => {
    setShowWindowIsLeavePage(false);
    setTransitionLeave((obj) => ({
      ...obj,
      onOk: true,
    }));

    if (selectedTenantId !== preselectedTenant.uuid) {
      changeTenant(preselectedTenant.customer_name, preselectedTenant.uuid);
    };
  };

  return (
    <>
      {showWindowLeavePage &&
        <PortalModal
          open={showWindowLeavePage}
          onCancel={handleCloseModal}
        >
          <FormModalTemplate
            handleSubmitButtonName={selectedTenantId !== preselectedTenant.uuid ? 'Change' : 'Leave'}
            onCancel={handleCloseModal}
            header='Confirmation Dialog'
            redBtn={true}
            handleSubmit={handleLeavePage}
          >
            {selectedTenantId !== preselectedTenant.uuid ?
              <div className='mt-8 ml-6 text-sm'>
                <p>
                  Do you really want to change your tenant?
                </p>
                <p>
                  Your data will be lost.
                </p>
              </div>
              :
              <p className='mt-8 ml-6 text-sm'>
                Do you really want to leave? Your data will be lost.
              </p>
            }
          </FormModalTemplate>
        </PortalModal>
      }
    </>
  );
};
