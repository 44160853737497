import React from 'react';

import { useDashboard } from 'context/dashboard';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import ContentViewMultyItems from 'feature/bulk-operations/delete/ContentViewMultyItems';
import useRemoveItems from 'feature/bulk-operations/delete/useRemoveItems';
import useCreateErrorsObjects from 'feature/bulk-operations/delete/useCreateErrorsObjects';
import useCreateSuccesObjects from 'feature/bulk-operations/delete/useCreateSuccesObjects';
import { TModalActionsItemsName } from 'types/index';

type TProps = {
  openPortalModalWindow: boolean,
  setOpenPortalModalWindow: (value: boolean) => void;
  page: 'ips' | 'sshKeys' | 'virtualmachine' | 'baremetal' | 'networks' | 'instances',
  itemsName: TModalActionsItemsName,
};

export default function ModalWindowBulkOperations(props: TProps) {
  // props
  const {
    openPortalModalWindow,
    setOpenPortalModalWindow,
    page,
    itemsName,
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];

  const { fetchFunc, notifi, preselectedRemovingItems } = state;
  const { loading: deletingLoading } = fetchFunc[page].delete;
  const newErrorsObject = useCreateErrorsObjects(notifi.errors);
  const newSuccessObject = useCreateSuccesObjects(notifi.success);
  const howManyItemsSelected = preselectedRemovingItems[page].uuid.length;
  const hideBtns = deletingLoading || (newErrorsObject.length !== 0 || newSuccessObject.length !== 0);
  const headerTitle = `Delete ${howManyItemsSelected > 1 ? itemsName + 's' : itemsName}`;

  // func
  const handleCloseModalWindow = () => {
    setOpenPortalModalWindow(false);
  };

  const handleRemoveItems = useRemoveItems(
    handleCloseModalWindow,
    page,
  );

  return (
    <>
      {openPortalModalWindow &&
        <PortalModal
          open={openPortalModalWindow}
          onCancel={handleCloseModalWindow}
        >
          <FormModalTemplate
            handleSubmitButtonName='Delete'
            onCancel={handleCloseModalWindow}
            handleSubmit={handleRemoveItems}
            hideBtns={hideBtns}
            redBtn={true}
            header={headerTitle}
          >
            <ContentViewMultyItems
              actionName='DELETED'
              itemsName={itemsName}
              fetchMethod='delete'
              page={page}
              numberOfItems={howManyItemsSelected || 0}
              errorsObject={newErrorsObject}
              successObject={newSuccessObject}
            />
          </FormModalTemplate>

        </PortalModal>
      }
    </>
  );
};
