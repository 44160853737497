import React, { forwardRef, useEffect, useState } from 'react';

import Info from 'components/Info';
import { useDashboard } from 'context/dashboard';
import { useCleanState } from 'hooks/useCleanState';
import { setInstance, setInstanceValidation } from 'actions/instance';
import { useInstance } from 'context/instance';
import { defaultNetwork } from 'reducers/dashboard';
import { TDataTestIdsNewVmMainNetwork } from 'data-test-ids/types/new-vm';
import { useTenants } from 'context/tenants';

import CustomNetworkSelect from './CustomNetworkSelect';
import CreateNewNetworkSection from './CreateNewNetworkSection';

import TitleSection from '../TitleSection';

import glossary from '../../../data/glossary.json';

const Network = forwardRef<HTMLDetailsElement, {dataTestIds: TDataTestIdsNewVmMainNetwork}>((props, ref) => {
  // state
  const [warning, setWarning] = useState<string>('');

  // props
  const { dataTestIds } = props;

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { error } = fetchFunc.networks.get;
  const cleanState = useCleanState();
  const contextInstance = useInstance();
  const dispatchInstance = contextInstance[1];

  useEffect(() => {
    return () => {
      cleanState('network', 'post');
    };
  }, [cleanState]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatchInstance(setInstance(defaultNetwork, 'network'));
      dispatchInstance(setInstanceValidation('', 'network'));
    }
  }, [selectedTenantId, dispatchInstance]);

  return (
    <div
      className='col-span-6'
      data-testid={dataTestIds.wrapper.name}
    >
      <TitleSection
        dataTestId={dataTestIds.wrapper.title.name}
        title={glossary.network}
      />
      <CustomNetworkSelect
        ref={ref}
        dataTestId={dataTestIds.wrapper.select}
      />
      <CreateNewNetworkSection
        handleWarning={setWarning}
        dataTestIds={dataTestIds.wrapper.newNetwork}
      />
      {warning && <Info status='warning' msg={warning} />}
      {error && <Info status='error' msg={error} />}
    </div>
  );
});

export default Network;
