import Typography from 'components/typography';
import getStyleOverviewSection from 'helpers/getStyleOverviewSection';

import classes from '../../overview.module.scss';

import ChartPie from '../../chartPie';

export default function Content(props: {data: {clusters: [], count: number}}){
  // variables
  const { data } = props;
  // const customData = data?.clusters?.map((item: {name: string, uuid: string, value: string, color: string}) => {
  //   return {
  //     ...item,
  //     color: getStyleOverviewSection(item.name).color,
  //   };
  // });
  const customData = [
    {
      name: 'all',
      uuid: '1',
      value: `${data.count}`,
      color: getStyleOverviewSection('all').color,
    },
  ];

  // style
  const {
    networkSectionContainerWrapperClass,
    networkSectionContentListClass,
    networkSectionContentListItemClass,
    networkSectionContentListItemTextClass,
  } = classes;

  return (
    <>
      <div className={networkSectionContainerWrapperClass}>
        <div className={networkSectionContentListClass}>
          {customData?.map((item: {name: string, uuid: string, value: string, color: string}) => (
            <div
              key={item.uuid}
              className={networkSectionContentListItemClass}
            >
              <div className={networkSectionContentListItemTextClass}>
                <div className={`w-[10px] h-[10px] mb-[0.1rem] mr-2 rounded-full ${getStyleOverviewSection(item.name).bg}`} />
                <Typography
                  text={item.name}
                  fontSize='xxxs'
                  fontWeight='medium'
                  color='customColorDarkBlue60'
                />
              </div>
              <Typography
                text={item.value}
                fontSize='xxxs'
                fontWeight='medium'
                color='customColorDarkBlue60'
              />
            </div>
          ))}
        </div>
        <div>
          <ChartPie
            customData={customData}
            customHeight={57}
            customSize={10}
            customWidth={57}
            totalNumber={data.count}
            customFontSize={11}
            dy={5}
          />
        </div>
      </div>
    </>
  );
};
