import { useCallback } from 'react';

import { useDashboard } from 'context/dashboard';
import { setFetchFunc } from 'actions/dashboard';
import { TFetchFuncPage, TFetchFuncRequestMethod, TFetchFuncStatus } from 'types';
import { useCleanState } from 'hooks/useCleanState';

import glossary from '../data/glossary.json';

export default function useHandleFetchRequest() {
  const context = useDashboard();
  const dispatch = context[1];
  const cleanState = useCleanState();

  const handleFetchRequest = useCallback(
    (
      page: TFetchFuncPage,
      fetchMethod: TFetchFuncRequestMethod,
      fetchRequest: Promise<unknown>,
    ) => {
      const setState = (data: unknown, status: TFetchFuncStatus ) => {
        dispatch(setFetchFunc(data, page, fetchMethod, status));
      };

      (async () => {
        cleanState(page, fetchMethod);
        try {
          setState(true, 'loading');
          const response = await fetchRequest;
          if ((page === 'instances' || page === 'baremetal' || page === 'virtualmachine') && fetchMethod === 'get') {
            // @ts-ignore
            const newList = response?.map((item) => {
              if (page === 'virtualmachine') {
                const updatedItem = {
                  ...item,
                  instanceType: 'virtualmachine',
                };

                return updatedItem;
              }
              if (page === 'baremetal') {
                const updatedItem = {
                  ...item,
                  instanceType: 'baremetal',
                };

                return updatedItem;
              }
              return item;
            });
            setState(newList, 'data');
          }
          else {
            setState(response, 'data');
          }
          setState(200, 'status');
          setState(glossary.notifications.success.successfully, 'message');
          setState('', 'error');
        }
        catch (error) {
          if (error instanceof Error) {
            setState(error?.cause, 'status');
            setState(error?.message || '', 'error');
          }
          setState([], 'data');
        }
        finally {
          setState(false, 'loading');
        }
      })();
    }, [dispatch, cleanState],
  );

  return handleFetchRequest;
};
