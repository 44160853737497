import React, { RefObject, useCallback, useEffect, useState } from 'react';

import Info from 'components/Info';
import { setInstanceValidation } from 'actions/instance';
import { useInstance } from 'context/instance';
import Button from 'components/buttons';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { useDashboard } from 'context/dashboard';
import { IVm } from 'types';
import { IIpv4 } from 'types';
import { TEST_ID_VMS_PAGE_START_NEW_VM_BTN } from 'data-test-ids/data-testid-variables';
import { apiUrls } from 'helpers/apiUrls';

import Items from './Items';

import glossary from '../../../data/glossary.json';

type TProps = {
  nameRef: RefObject<HTMLDivElement | null>,
  flavorsRef: RefObject<HTMLDivElement | null>,
  imagesRef: RefObject<HTMLDivElement | null>,
  sshKeysRef: RefObject<HTMLDetailsElement | null>,
  networksRef: RefObject<HTMLDetailsElement | null>,
};

type TScrollRefError = RefObject<HTMLDivElement | HTMLDetailsElement | null>;

type TNewVm = {
  tenant_uuid: string,
  sshkeys: { uuid: string}[],
  cloud_init_user_data: {},
  name: string,
  network_uuid: string,
  flavor_uuid: string,
  image_uuid: string,
  addresses4?: IIpv4[],
  ipv4s?: IIpv4[],
  cluster_uuid: string,
};

const Summary = ((props: TProps) => {
  const [disabled , setDisabled] = useState<boolean>(false);

  const {
    nameRef,
    flavorsRef,
    imagesRef,
    sshKeysRef,
    networksRef,
  } = props;

  const context = useInstance();
  const state = context[0];
  const dispatch = context[1];
  const { instance, validation } = state;
  const { name, sshKeys, network, image, ips, flavor } = instance;
  const url = apiUrls.instances;
  const handleFetchRequest = useHandleFetchRequest();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc, instanceName } = stateDashboard;
  const { error } = fetchFunc.instance.post;

  const scrollToSection = (ref: TScrollRefError, error: string, section: string) => {
    ref && ref.current && ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    dispatch(setInstanceValidation(true, section));
  };

  const handleValidation = () => {
    if (!name) {
      scrollToSection(nameRef, 'Please enter name', 'name');
    };

    if (!flavor.uuid) {
      scrollToSection(flavorsRef, 'Please choose the flavor', 'flavor');
    };

    if (!image.uuid) {
      scrollToSection(imagesRef, 'Please choose the image', 'image');
    };

    if (sshKeys.length === 0) {
      scrollToSection(sshKeysRef, 'Please choose the sshkey', 'sshKey');
    };

    if (!network.uuid) {
      scrollToSection(networksRef, 'Please choose the network', 'network');
    };
  };

  const handleCreateVm = () => {
    const cloudData = {};
    const sshkeys = sshKeys.map(key => ({ uuid: key.uuid }));
    const data: TNewVm = {
      tenant_uuid: network?.tenant_uuid,
      sshkeys,
      cloud_init_user_data: cloudData,
      name,
      network_uuid: network?.uuid,
      flavor_uuid: flavor?.uuid,
      image_uuid: image?.uuid,
      addresses4: ips,
      cluster_uuid: network?.cluster_uuid,
    };

    const request = api.useFetchData<TNewVm, IVm>(url, 'post', null, data);
    handleFetchRequest('instance', 'post', request);
  };

  const handleCheckValues = useCallback(() => {
    if (Object.values(validation).every(item => item === false) || Object.values(validation).some(item => item === '')) {
      setDisabled(false);
    }
    if (Object.values(validation).some(item => item === true)) {
      setDisabled(true);
    }
  }, [validation]);

  useEffect(() => {
    handleCheckValues();
  }, [validation, handleCheckValues]);

  const handleSubmit = () => {
    handleValidation();
    handleCheckValues();
    if (Object.values(validation).every(item => item === false)) {
      handleCreateVm();
    }
  };

  return (
    <div data-testid='vm_summary'>
      <Items />
      <div className='px-4 pt-[50px] pb-4 w-full m-0'>
        {error && <Info status='error' msg={error} />}
        <Button
          type='button'
          disabled={disabled}
          handleClick={handleSubmit}
          title={`${glossary.actions.create} ${instanceName}`}
          customStyle='w-full'
          uiStyle='primary'
          dataTestId={TEST_ID_VMS_PAGE_START_NEW_VM_BTN}
        />
      </div>
    </div>
  );
});

export default Summary;
