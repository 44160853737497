import useImportScript from 'hooks/useImportScript';
import Spinner from 'components/spinners/Spinner';

type TProps = {
  url: string,
};

export default function SpiceConsole({url}: TProps) {

  useImportScript('/hasModuleSupport.js', 'module');
  useImportScript('/moduleLoader.js', '');

  return (
    <>
      <div id='spice-area'>
        <Spinner spaceAroundSpinner />
        <div id='spice-screen' className='spice-screen' />
      </div>
      <input readOnly={true} type='text' hidden id='host' value={url}/>
      <input readOnly={true} type='text' hidden id='port' value='' />
      <input readOnly={true} type='text' hidden id='password' value='' />
    </>
  );
}
