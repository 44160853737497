import { useDashboard } from 'context/dashboard';
import { setPreselectedRemovingItems } from 'actions/dashboard';

type TPage = 'ips' | 'networks' | 'sshKeys' | 'virtualmachine' | 'baremetal' | 'instances';

export default function useGetNameAndUuidSelectedItems (
  page: TPage = 'networks',
) {
  const context = useDashboard();
  const dispatch = context[1];
  const state = context[0];
  const { preselectedRemovingItems } = state;

  const preselectedRemovingItemsUuid = preselectedRemovingItems[page].uuid;
  const preselectedRemovingItemsName = preselectedRemovingItems[page].name;

  const handleUuidItems = (uuid: string) => {

    if (!preselectedRemovingItemsUuid.includes(uuid)) {
      const newArray = [...preselectedRemovingItemsUuid, uuid];

      dispatch(setPreselectedRemovingItems(page, 'uuid', newArray));

    } else {
      const filteredItems = preselectedRemovingItemsUuid.filter(item => item !== uuid);
      dispatch(setPreselectedRemovingItems(page, 'uuid', filteredItems));
    }
  };

  const handleNameItems = (value: string) => {

    if (!preselectedRemovingItemsName.includes(value)) {
      const newArray = [...preselectedRemovingItemsName, value];
      dispatch(setPreselectedRemovingItems(page, 'name', newArray));
    } else {
      const filteredItems = preselectedRemovingItemsName.filter(item => item !== value);
      dispatch(setPreselectedRemovingItems(page, 'name', filteredItems));
    }
  };

  const handleGetArrayWithNameAndUuidItems = (value: string, uuid: string) => {
    handleUuidItems(uuid);
    handleNameItems(value);
  };

  return handleGetArrayWithNameAndUuidItems;
};
