const settings = {
  oidc: {
    issuer: process.env.REACT_APP_API_OKTA_ISSUER,
    clientId: process.env.REACT_APP_API_OKTA_CLIENT_ID,
    scopes: ['openid', 'profile', 'groups', 'email'],
    redirectUri: `${window.location.origin}/login/callback`,
  },
  widget: {
    issuer: process.env.REACT_APP_API_OKTA_ISSUER,
    clientId: process.env.REACT_APP_API_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'groups', 'email'],
    useInteractionCodeFlow: false,
  },
};

export default settings;
