import { useHistory } from 'react-router-dom';

import Button from 'components/buttons';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
import { useTenants } from 'context/tenants';


import glossary from '../../../../data/glossary.json';

export default function RedirectBtnCreateVm(){
  // variables
  const history = useHistory();
  const { handlePagePaths } = useGetRoutesPagePath();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const btnTitleVm = `${glossary.actions.create} ${glossary.virtual_machine}`;

  // func
  const handleRedirectTo = () => {
    return history.push(handlePagePaths(selectedTenantId).newInstance);
  };

  return (
    <Button
      title={btnTitleVm}
      uiStyle='primary'
      handleClick={handleRedirectTo}
      customStyle='min-w-[155px]'
      size='small'
    />
  );
};
