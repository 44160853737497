// @ts-nocheck
import { useEffect, useState } from 'react';

// import { IExtrasFlavors } from 'types';
import { api } from 'helpers/api';
import { useDashboard } from 'context/dashboard';
import { setFlavors } from 'actions/dashboard';
import { useTenants } from 'context/tenants';
import { cacheable } from 'helpers/cacheable';
import checkCache from 'helpers/checkCache';
import { apiUrls } from 'helpers/apiUrls';

import useSelectFlavorTab from './useSelectFlavorTab';

/**
 * @description
 * @returns error, loading
 */

export const useGetFlavors = () => {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  // variables
  const context = useDashboard();
  const dispatch = context[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  // const url = `${apiUrls.flavors}${selectedTenantId}`; // const url = 'info/images';
  const url = `${apiUrls.flavors}`; // const url = 'info/images';
  const { handleSelectedFlavorTab } = useSelectFlavorTab();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      async function loadData() {
        setError('');

        const cachedResult = checkCache('cache-f');
        let result;

        if (cachedResult) {
          result = cachedResult;
          dispatch(setFlavors(result));
          handleSelectedFlavorTab(result?.use_fe[0]?.name, result?.use_fe[0]?.items[0]);
        } else {
          // const request = api.useFetchData<[], IExtrasFlavors>(url, 'get', signal);
          const request = api.useFetchData<[], any>(url, 'get', signal);
          try {
            setLoading(true);
            setError('');
            const getFlavors = await request;

            const newFlavors = {
              count: getFlavors.length,
              use_fe: [{
                id: 0,
                name: 'RTXA6000',
                items: getFlavors.filter((item) => item.gpu_type === 'A6000').map((item) =>  ({
                  ...item,
                  'cluster': {
                    'uuid': '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
                    'name': 'ndcloud-underground',
                  },
                })),
              }],
              display_fe: getFlavors,
            };
            dispatch(setFlavors(newFlavors));
            handleSelectedFlavorTab(newFlavors?.use_fe[0]?.name, newFlavors?.use_fe[0]?.items[0]);

          } catch (error) {
            if (error instanceof Error) {
              setError(error?.message || '');
            }
            dispatch(setFlavors({count: 0, display_fe: [], use_fe: []}));
          }

          result = await cacheable(() => request, [], 'cache-f');
          const newFlavors = {
            count: result.length,
            use_fe: [{
              id: 0,
              name: 'RTXA6000',
              items: result.filter((item) => item.gpu_type === 'A6000').map((item) =>  ({
                ...item,
                'cluster': {
                  'uuid': '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
                  'name': 'ndcloud-underground',
                },
              })),
            }],
            display_fe: result,
          };
          dispatch(setFlavors(newFlavors));
          handleSelectedFlavorTab(newFlavors?.use_fe[0]?.name, newFlavors?.use_fe[0]?.items[0]);
          setLoading(false);
        }
      }

      loadData();

    }

    return () => {
      controller.abort();
      dispatch(setFlavors({count: 0, display_fe: [], use_fe: []}));
    };
  }, [url, dispatch, handleSelectedFlavorTab, selectedTenantId]);

  return { error, loading };
};
