import React, { ChangeEvent, useCallback } from 'react';

import { useDashboard } from 'context/dashboard';
import CustomInput from 'components/input/Input';
import CustomTextarea from 'components/input/Textarea';
import { TDataTestIdsPortalModalFormContent } from 'data-test-ids/types/general-pages/portalModal';
import { TNewSshKey } from 'types/index';
import { useTenants } from 'context/tenants';

import Spinner from '../../spinners/Spinner';
import Info from '../../Info';

import glossary from '../../../data/glossary.json';

type TProps = {
  dataTestIds: TDataTestIdsPortalModalFormContent,
  inputNameError: string,
  sshKeyError: string,
  handleInputNameError: (value: string) => void,
  handleSshKeyError: (value: string) => void,
  handleUpdateForm: (value: TNewSshKey) => void,
  formData: TNewSshKey;
};

export default function Content(props: TProps) {
  // props
  const {
    formData,
    inputNameError,
    sshKeyError,
    handleInputNameError,
    handleSshKeyError,
    handleUpdateForm,
    dataTestIds,
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = state;
  const { loading, error } = fetchFunc.sshKey.post;

  // func
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    handleInputNameError('');
    handleSshKeyError('');
    handleUpdateForm({
      ...formData,
      tenant_uuid: selectedTenantId,
      [name]: value.trim(),
    });
  }, [
    formData,
    selectedTenantId,
    handleInputNameError,
    handleUpdateForm,
    handleSshKeyError,
  ]);

  if (loading) return  <Spinner spaceAroundSpinner />;
  if (error) return <Info status='error' msg={error} />;

  return (
    <div className='pt-8 px-6'>
      <div className='col-span-6'>
        <CustomInput
          name='name'
          placeholder='Name'
          type='text'
          handleChange={handleChange}
          inputFeedback={inputNameError}
          inputStatus='validation'
          label='Name'
          value={formData.name}
          testDataId={dataTestIds.inputText}
        />
      </div>
      <div className='mt-6'>
        <CustomTextarea
          name='key'
          placeholder='ssh-rsa | ssh-ed | ...'
          handleChange={handleChange}
          textareaError={sshKeyError}
          label={glossary.ssh_key}
          dataTestId={dataTestIds.inputSelect}
        />
      </div>
    </div>
  );
};
