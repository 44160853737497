import { IVm } from 'types';
import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setPreselectedRemovingItems } from 'actions/dashboard';

import useCheckVmsStatusForDeletion from './useCheckVmsStatusForDeletion';
import useFilterVms from './useFilterVms';

export default function useSelectedOrDeselectedAllRemovingItemsVms(isSelected: boolean, page: 'instances' | 'virtualmachine' | 'baremetal') {

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc } = state;

  const isStatusOkay = useCheckVmsStatusForDeletion();
  const vmsWihtoutDeletedItems = useFilterVms(page);

  const handlePreselectedItems = () => {
    const newArray = [];

    for(let i = 0; i < vmsWihtoutDeletedItems.length; i++ ) {
      newArray.push(vmsWihtoutDeletedItems[i].uuid);
    }

    dispatch(setPreselectedRemovingItems(page, 'uuid', newArray));
  };

  const handleSelectedAllItems = () => {
    const newList = fetchFunc[page].get.data.map((vm: IVm) => {
      if (isStatusOkay(vm.status)) {
        const updatedItem = {
          ...vm,
          isSelected: isSelected,
        };
        return updatedItem;
      }
      return vm;
    });

    dispatch(setFetchFunc(newList, page, 'get', 'data'));

    if (isSelected) {
      handlePreselectedItems();
    } else {
      dispatch(setPreselectedRemovingItems(page, 'uuid', []));
      dispatch(setPreselectedRemovingItems(page, 'name', []));
    }
  };

  return handleSelectedAllItems;
};
