import React from 'react';

import { useDashboard } from 'context/dashboard';
import IconBlinkerDot from 'assets/icons/IconBlinkerDot';

import classes from './index.module.scss';

export default function IncidentComponent() {
  const {
    statusIncidentClass,
    statusIncidentTextClass,
    statusIncidentTitleClass,
    blinkerDotBig,
  } = classes;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { maintenance } = stateDashboard;
  const incidents = maintenance && maintenance.incidents;

  return (
    <a
      href='https://status-cloudservices.northerndata.de'
      rel='noreferrer noopener'
      target='_blank'
      className={statusIncidentClass}
    >
      <IconBlinkerDot
        blinkerDotBig={blinkerDotBig}
        customColor='#EC4D4D'
      />
      <div className='col ml-3'>
        <p className={statusIncidentTitleClass}>
          {incidents[0]?.name}
        </p>
        <p className={statusIncidentTextClass}>
          More Information
        </p>
      </div>
    </a>
  );
};
