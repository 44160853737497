import { Link } from 'react-router-dom';

import Typography from 'components/typography';
import { homePage } from 'Routes';

export default function NotFound() {
  return (
    <div className='col all-items-center mt-[6.25rem]'>
      <Typography
        text='404 Not found'
        fontSize='x2l'
      />
      <Link
        to={homePage}
        className='border-customColorLightGray bg-customGray4 px-6 py-4 rounded-md m-8'
      >
        <Typography
          text='Go to Home'
          fontSize='s'
          fontWeight='thin'
        />
      </Link>
    </div>
  );
};
