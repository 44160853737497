import { ChangeEvent, ReactNode } from 'react';

import Head from './Head';
import classes from './table.module.scss';

type TProps = {
  headData: string[],
  children: ReactNode,
  handleDeselectItems: (event: ChangeEvent<HTMLInputElement>) => void,
  isAllSelected: boolean,
  isShowCheckbox: boolean,
  handleSelectedAllItems: () => void,
  disabledCheckbox?: boolean,
  dataTestId?: string,
};

const Table = (props: TProps) => {
  const {
    headData,
    children,
    handleDeselectItems,
    isAllSelected,
    isShowCheckbox,
    handleSelectedAllItems,
    disabledCheckbox,
    dataTestId,
  } = props;

  return (
    <div className='mt-8 col'>
      <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8'>
        <div className='min-w-full py-2 md:px-6 lg:px-8'>
          <table className={`min-w-full ${classes.tableClass}`}>
            <Head
              isShowCheckbox={isShowCheckbox}
              isAllSelected={isAllSelected}
              handleDeselectItems={handleDeselectItems}
              handleSelectedAllItems={handleSelectedAllItems}
              data={headData}
              disabledCheckbox={disabledCheckbox}
              dataTestId={dataTestId}
            />
            {children}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
