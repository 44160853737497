import { ReactNode } from 'react';

type TProps = {
  children: ReactNode,
};

const Row: React.FC<TProps> = ({ children }) => (
  <tr>
    {children}
  </tr>
);

export default Row;
