import { TDetailsPageContext } from 'layouts/details-page';
import { createContext, useContext } from 'react';

export const DetailsPageLayoutContext = createContext<TDetailsPageContext>(
  {
    detailsPageName: 'instance',
    routeNav: '',
  },
);
export const useDetailsPageLayoutContext = () => useContext(DetailsPageLayoutContext);
