import React from 'react';

import { useDashboard } from 'context/dashboard';
import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';
import getVMsStatus, { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';
import Typography from 'components/typography';

import glossary from '../../data/glossary.json';

type TProps = {
  actionName: TActionsMenuComponent,
  itemName: string,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
};

export default function ContentView(props: TProps) {
  // props
  const {
    actionName,
    itemName,
    fetchMethod,
    page,
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { error, loading, message } = fetchFunc[page][fetchMethod];
  const question = `${glossary.questions.do_you_really_want_to} ${getVMsStatus(actionName).lowercase}`;

  if (error) {
    return  <Info status='error' msg={error} />;
  };

  if (loading) {
    return  <Spinner spaceAroundSpinner />;
  };

  if (message) {
    return  <Info status='success' msg={message} />;
  };

  return (
    <div className='pt-6 px-6 pb-[61px] row'>
      <Typography
        text={question}
        fontSize='sm'
        fontWeight='thin'
        customStyle='text-nowrap'
      />
      &#160;
      <Typography
        text={`${itemName}`}
        fontSize='sm'
        fontWeight='medium'
        truncate='truncate'
        width={80}
      />
      <Typography
        text='?'
        fontSize='sm'
        fontWeight='thin'
      />
    </div>
  );
};
