import React from 'react';

import classes from '../instance.module.scss';

import glossary from '../../../data/glossary.json';

export default function Ipv6Section({dataTestId}: {dataTestId: string}) {
  const { sectionWrapperClass } = classes;

  return (
    <div data-testid={dataTestId} className={`${sectionWrapperClass} justify-start pl-6 bg-[#F9FAFB]`}>
      <p className='mr-6 text-sm font-medium'>{glossary.ipv6}</p>
      <p className='text-customGray3 text-sm font-medium'>({glossary.descriptions.you_will_receive_this_ip_automatically})</p>
    </div>
  );
};
