import React, { useState } from 'react';

import { setFetchFunc, setNewIpv4 } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { IModalForm, TNewIPv4 } from 'types';
import { IIpv4 } from 'types';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { TDataTestIdsPortalModal } from 'data-test-ids/types/general-pages/portalModal';
import { useTenants } from 'context/tenants';
import { apiUrls } from 'helpers/apiUrls';

import Content from './Content';

import glossary from '../../../data/glossary.json';

type TProps = IModalForm & {
  dataTestIds: TDataTestIdsPortalModal,
};

export default function CreateNewIpv4ModalForm(props: TProps) {
  // state
  const [formData, updateFormData] = useState<TNewIPv4>({
    tenant_uuid: '',
    cluster_uuid: '',
  });
  const [clusterError, setClusterError] = useState<string>('');
  const [selectedClusterName, setSelectedClusterName] = useState<string>('');

  // props
  const { onCancel, open, dataTestIds } = props;

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { loading, status: statusNewIpv4 } = fetchFunc.ips.post;
  const { data: allIpv4 } = fetchFunc.ips.get;
  const url = `${apiUrls.ipv4}${formData.cluster_uuid}/${selectedTenantId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const hideBtns = (statusNewIpv4 > 0) || loading ? true : false;

  // func
  const handleCreateNewIpv4 = async() => {
    if (!formData.cluster_uuid) {
      return setClusterError(glossary.notifications.errors.please_select_cluster);
    };
    setClusterError('');
    if (selectedTenantId) {
      const request =  api.useFetchData<TNewIPv4, IIpv4>(url, 'post', null, formData);
      handleFetchRequest('ip', 'post', request);
      const newIpv4: IIpv4 = await request;
      const correctIpAddressFormat = { ...newIpv4, address4: newIpv4?.address4.replace('/32', '')};
      dispatchDashboard(setFetchFunc([...allIpv4, correctIpAddressFormat], 'ips', 'get', 'data'));
      onCancel();
    };
  };

  const handleCloseModalForm = () => {
    dispatchDashboard(
      setNewIpv4({
        tenant_uuid: '',
        cluster_uuid: '',
      }),
    );
    onCancel();
  };

  return (
    <PortalModal
      open={open}
      onCancel={handleCloseModalForm}
      dataTestId={dataTestIds.portalModal.name}
    >
      <FormModalTemplate
        handleSubmitButtonName={glossary.actions.create}
        onCancel={handleCloseModalForm}
        handleSubmit={handleCreateNewIpv4}
        hideBtns={hideBtns}
        header={`${glossary.actions.create} ${glossary.ipv4}`}
        dataTestIds={dataTestIds.portalModal.form}
      >
        <Content
          handleClusterNameError={setClusterError}
          handleSelectedClusterName={setSelectedClusterName}
          handleUpdateFormData={updateFormData}
          formData={formData}
          dataTestIds={dataTestIds.portalModal.form.content}
          selectedClusterName={selectedClusterName}
          clusterError={clusterError}
        />
      </FormModalTemplate>
    </PortalModal>
  );
};
