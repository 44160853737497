type TProps = {
  description: string,
};

export default function Empty(props: TProps) {
  // props
  const { description } = props;

  return (
    <div className='bg-white border border-customColorLightGray h-[76px] rounded-md all-items-center'>
      <p className='text-s font-medium'>No <span className='capitalize'>{description}</span> created yet </p>
    </div>
  );
};
