import React from 'react';

import { useDashboard } from 'context/dashboard';
import Typography from 'components/typography';
import CopyComponent from 'feature/copy-func';

import classes from '../index.module.scss';
import glossary from '../../../data/glossary.json';

/**
 * @description The Ipv6Section component
 * @returns div
*/

export default function Ipv6Section() {
  //  hooks
  const contextDashboard = useDashboard();

  // variables
  const state = contextDashboard[0];
  const { data } = state.fetchFunc.instance.get;
  const ipv6 = data?.address6?.address6 || '/64';
  const correctIpv6 = ipv6.replace('/64', '');

  // style
  const { ipv6SectionClass, ipv6Class, ipv6TextClass } = classes;

  return (
    <div className={ipv6SectionClass}>
      <Typography
        text={glossary.ipv6}
        fontWeight='medium'
        fontSize='s'
      />
      <div className={ipv6Class}>
        <Typography
          text={correctIpv6}
          fontWeight='thin'
          fontSize='s'
          color='customColorDarkBlue80'
          customStyle={ipv6TextClass}
        />
        <CopyComponent
          value={correctIpv6}
          dataTestId='test_id_vms_ipv6_tooltip'
          iconColor='fill-customColorDarkBlue60'
        />
      </div>
    </div>
  );
};
