import React from 'react';

import { TDataTestIdsOverviewPageContentMainInfo } from 'data-test-ids/types/overview-page';

import Items from './Items';
import VmsSection from './attached-vms/VmsSection';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainInfo,
};

/**
  * @description The InfoSection
  * @augments Network_page
  * @augments Content
  * @augments Main_section
  *
  *
  * @param props dataTestIds
  * @param dataTestIds required
  * @returns div
*/

export default function InfoSection(props: TProps) {
  const { dataTestIds } = props;

  return (
    <div
      data-testid={dataTestIds.name}
      className='mt-6 pl-6 flex row items-baseline justify-between'
    >
      <Items dataTestIds={dataTestIds.items}/>
      <VmsSection dataTestIds={dataTestIds.aside}/>
    </div>
  );
};
