//@ts-nocheck
import { useEffect, useState } from 'react';

// import { IExtrasImages } from 'types';
import { api } from 'helpers/api';
import { useDashboard } from 'context/dashboard';
import { setImages } from 'actions/dashboard';
import { useTenants } from 'context/tenants';
import { cacheable } from 'helpers/cacheable';
import checkCache from 'helpers/checkCache';
import { apiUrls } from 'helpers/apiUrls';

import useSelectImage from './useSelectImage';

const useGetImages = () => {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  // variables
  const context = useDashboard();
  const dispatch = context[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  // const url = `${apiUrls.images}${selectedTenantId}`; // const url = 'info/images';
  const url = `${apiUrls.images}`; // const url = 'info/images';
  const { handleSelectedImage } = useSelectImage();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      async function loadData() {
        setError('');

        const cachedResult = checkCache('cache-i');
        let result;

        if (cachedResult) {
          result = cachedResult;
          dispatch(setImages(result));
          handleSelectedImage(result?.use_fe[0]?.versions[0]);
        } else {
          // const request = api.useFetchData<[], IExtrasImages>(url, 'get', signal);
          const request = api.useFetchData<[], any>(url, 'get', signal);
          try {
            setLoading(true);
            setError('');
            const getAllImages = await request;

            const allUbuntu = {
              'id': 0,
              'name': 'ubuntu',
              'versions': getAllImages.filter((img) => img.name.toLowerCase().includes('ubuntu')).map((img) =>  ({
                ...img,
                image_group_id: 0,
                image: 'ubuntu',
              })),
            };
            const allDebian = {
              'id': 2,
              'name': 'debian',
              'versions': getAllImages.filter((img) => img.name.toLowerCase().includes('debian')).map((img) =>  ({
                ...img,
                image_group_id: 2,
                image: 'debian',
              })),
            };
            const allRocky = {
              'id': 3,
              'name': 'rocky',
              'versions': getAllImages.filter((img) => img.name.toLowerCase().includes('rocky')).map((img) =>  ({
                ...img,
                image_group_id: 3,
                image: 'rocky',
              })),
            };
            const newImages = {
              count: getAllImages.length,
              use_fe: [
                allUbuntu,
                allDebian,
                allRocky,
              ],
              display_fe: getAllImages,
            };
            dispatch(setImages(getAllImages));
            handleSelectedImage(newImages?.use_fe[0]?.versions[0]);

          } catch (error) {
            if (error instanceof Error) {
              setError(error?.message || '');
            }
            dispatch(setImages({count: 0, display_fe: [], use_fe: []}));
          }

          result = await cacheable(() => request, [], 'cache-i');
          const allUbuntu = {
            'id': 0,
            'name': 'ubuntu',
            'versions': result.filter((img) => img.name.toLowerCase().includes('ubuntu')).map((img) =>  ({
              ...img,
              image_group_id: 0,
              image: 'ubuntu',
            })),
          };
          const allDebian = {
            'id': 2,
            'name': 'debian',
            'versions': result.filter((img) => img.name.toLowerCase().includes('debian')).map((img) =>  ({
              ...img,
              image_group_id: 2,
              image: 'debian',
            })),
          };
          const allRocky = {
            'id': 3,
            'name': 'rocky',
            'versions': result.filter((img) => img.name.toLowerCase().includes('rocky')).map((img) =>  ({
              ...img,
              image_group_id: 3,
              image: 'rocky',
            })),
          };
          const newImages = {
            count: result.length,
            use_fe: [
              allUbuntu,
              allDebian,
              allRocky,
            ],
            display_fe: result,
          };
          dispatch(setImages(newImages));
          handleSelectedImage(newImages?.use_fe[0].versions[0]);
          setLoading(false);
        }
      }

      loadData();
    }

    return () => {
      controller.abort();
      dispatch(setImages({count: 0, display_fe: [], use_fe: []}));
    };
  }, [url, dispatch, handleSelectedImage, selectedTenantId]);

  return { error, loading };
};

export default useGetImages;
