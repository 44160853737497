import { useInstance } from 'context/instance';
import { ITabsItemFlavor } from 'types';
import useSelectFlavorTab from 'hooks/useSelectFlavorTab';

import classes from './flavor.module.scss';

type TProps = {
  item: ITabsItemFlavor
};

export default function Item({item}: TProps) {
  const { name, gpu_count, storage_size, core_count, ram, nvswitch_count } = item;
  const context = useInstance();
  const state = context[0];
  const { instance: { flavor }, tab } = state;
  const { handleSelectedFlavor } = useSelectFlavorTab();

  const data = [
    {
      id: 1,
      title: tab === 'NVIDIA HGX H100' ? 'TB' : 'GB',
      value: storage_size,
    },
    {
      id: 2,
      title: 'CPUs',
      value: core_count,
    },
    {
      id: 3,
      title: tab === 'NVIDIA HGX H100' ? 'TB' : 'GB',
      value: ram,
    },
  ];

  return (
    <tr
      onClick={() => handleSelectedFlavor(item)}
      className={[
        'text-xs',
        name === flavor.name ?
          'font-medium bg-customBgLightGray2' :
          'bg-white',
      ].join(' ')}
      data-testid={item.uuid}
    >
      <td
        className={[
          name === flavor.name ? classes.selected : '',
        ].join(' ')}
      >
        <div className='row all-items-center text-s font-bold'>
          <input
            aria-label='gpu'
            type='checkbox'
            className='mr-4'
            checked={flavor.uuid === item.uuid && flavor.selected}
            onChange={() => handleSelectedFlavor(item)}
          />
          {gpu_count} GPU
        </div>
      </td>
      {(tab === 'NVIDIA A100 NVSWICH' || tab === 'NVIDIA RTX A6000 NVSWICH') &&
        <td
          className={[
            name === flavor.name ? classes.selected : '',
          ].join(' ')}
        >
          {nvswitch_count} NV switch
        </td>
      }
      {data.map(tr => (
        <td
          key={tr.id}
          className={[
            name === flavor.name ? classes.selected : '',
          ].join(' ')}
        >
          {tr.value} {tr.title}
        </td>
      ))}
    </tr>
  );
};
