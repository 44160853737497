import { useDashboard } from 'context/dashboard';
import { IVm } from 'types';

import useCheckVmsStatusForDeletion from './useCheckVmsStatusForDeletion';

export default function useFilterVms(page: 'instances' | 'virtualmachine' | 'baremetal'): IVm[] {
  const context = useDashboard();
  const state = context[0];

  const { fetchFunc } = state;
  const { data } = fetchFunc[page].get;
  const isStatusOkay = useCheckVmsStatusForDeletion();
  const vmsWihtoutDeletedItems = data.filter((vm: IVm) => isStatusOkay(vm.status));

  return vmsWihtoutDeletedItems;
};
