import React, { forwardRef } from 'react';

import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';
// import { ITabsItemFlavor, TFlavorTabs } from 'types';
import { useGetFlavors } from 'hooks/useGetFlavors';
// import { useInstance } from 'context/instance';
import { useDashboard } from 'context/dashboard';

import Tabs from './Tabs';

import TitleSection from '../TitleSection';

/*
 *
 * @returns div
 */

const Flavor = forwardRef<HTMLDivElement>((props, ref) => {
  // variables
  const { error, loading } = useGetFlavors();
  // const context = useInstance();
  // const state = context[0];
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  // const { filterFlavors: { A6000, H100, A100 } } = state;

  // const tabsObjectDemo: {
  //   name: TFlavorTabs,
  //   items: Array<ITabsItemFlavor>,
  // }[] = [
  //   {
  //     name: 'NVIDIA RTX A6000',
  //     items: A6000,
  //   },
  //   {
  //     name: 'NVIDIA HGX H100',
  //     items: H100,
  //   },
  //   {
  //     name: 'NVIDIA HGX A100',
  //     items: A100,
  //   },
  // ];

  // const tabsObject: {
  //   name: TFlavorTabs,
  //   items: Array<ITabsItemFlavor>,
  // }[] = [
  //   {
  //     name: 'NVIDIA RTX A6000',
  //     items: A6000,
  //   },
  // ];

  // func
  const returnContent = () => {
    if (error) return <Info status='error' msg={error} />;
    if (loading) return <Spinner />;
    return (
      <Tabs
        // @ts-ignore
        tabs={stateDashboard.flavors.use_fe}
      />
    );
  };

  return (
    <div
      className='col-span-6'
    >
      <TitleSection title='Flavor' />
      <div className='mt-6'>
        {returnContent()}
      </div>
    </div>
  );
});

export default Flavor;
