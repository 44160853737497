import React from 'react';

import { useDashboard } from 'context/dashboard';

import Ipv4 from './Ipv4';

import classes from '../../index.module.scss';

/**
 * @description The Ipv4s component
 * @returns div
*/

export default function Ipv4s() {
  // hooks
  const contextDashboard = useDashboard();

  // variables
  const state = contextDashboard[0];
  const { data } = state.fetchFunc.instance.get;
  const { status, addresses4 } = data;
  const styleIpv4Box = addresses4?.length > 4
    ? 'overflow-scroll'
    : '';

  // style
  const { ipv4sClass } = classes;

  return (
    <div className={`${ipv4sClass} ${styleIpv4Box}`}>
      {addresses4.map(four => (
        <Ipv4
          key={four.uuid}
          ipv4object={four}
          ipv4={four.address4}
          status={status}
        />
      ))}
    </div>
  );
};

