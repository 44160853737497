import React, { useEffect } from 'react';

import Typography from 'components/typography';

import UserSection from './userSection';
import TenantSection from './tenantSection';
import UuidsSection from './uuidsSection';

import glossary from '../../data/glossary.json';

/**
 * @description The Profile page

 * @returns div
*/

export default function Profile() {
  // func
  useEffect(() => {
    document.title = `${glossary.name} | ${glossary.profile}`;
  }, []);

  return (
    <div
      className='col'
      data-testid='test_id_tenants_page'
    >
      <Typography
        text={glossary.profile}
        customStyle='mt-12'
        fontSize='base'
        fontWeight='medium'
      />
      <UserSection />
      <TenantSection />
      <UuidsSection />
    </div>
  );
};
