interface IOverviewSectionNamesColors {
  // text: string,
  color: string,
  bg: string,
};

const ndcloudArcticband = {
  // text: 'ndcloud-arcticband',
  color: '#f72585',
  bg: 'bg-[#f72585]',
};

const ndcloudArctictheory = {
  // text: 'ndcloud-arctictheory',
  color: '#ff8a5b',
  bg: 'bg-[#ff8a5b]',
};

const ndcloudHeimdall = {
  // text: 'ndcloud-heimdall',
  color: '#C280F6',
  bg: 'bg-[#C280F6]',
};

const ndcloudLoki = {
  // text: 'ndcloud-loki',
  color: '#8fc93a',
  bg: 'bg-[#8fc93a]',
};

const ndcloudOdin = {
  // text: 'ndcloud-odin',
  color: '#5BCCDF',
  bg: 'bg-[#5BCCDF]',
};

const ndcloudThor = {
  // text: 'ndcloud-thor',
  color: '#a51c30',
  bg: 'bg-[#a51c30]',
};

const other = {
  // text: 'ndcloud-cluster(unknown)',
  color: '#7c7c7c',
  bg: 'bg-[#7c7c7c]',
};

const ndcloudUnderground = {
  // text: 'ndcloud-underground',
  // text: 'attached',
  color: '#27A9BF',
  bg: 'bg-[#27A9BF]',
};

const ndcloudArcticcircle = {
  // text: 'ndcloud-arcticcircle',
  // text: 'unattached',
  color: '#745BE6',
  bg: 'bg-[#745BE6]',
};

// const attached = {
//   color: '#27A9BF',
//   bg: 'bg-[#27A9BF]',
// };

// const unattached = {
//   bg: 'bg-[#745BE6]',
//   text: 'unattached',
// };

export default function getStyleOverviewSection(name: string): IOverviewSectionNamesColors {
  switch (name) {
  case 'ndcloud-arcticband':
    return ndcloudArcticband;

  case 'ndcloud-arctictheory':
    return ndcloudArctictheory;

  case 'ndcloud-heimdall':
    return ndcloudHeimdall;

  case 'ndcloud-loki':
    return ndcloudLoki;

  case 'ndcloud-odin':
    return ndcloudOdin;

  case 'ndcloud-thor':
    return ndcloudThor;

  case 'cluster (unknown)':
    return other;

  case 'ndcloud-underground':
  case 'attached':
    return ndcloudUnderground;

  case 'ndcloud-arcticcircle':
  case 'unattached':
    return ndcloudArcticcircle;

  default:
    return  {
      // text: 'test',
      color: '#543fb6',
      bg: 'bg-[#543fb6]',
    };
  };
};
