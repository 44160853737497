import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
import { IIpv4 } from 'types';
import CopyComponent from 'feature/copy-func';
import Typography from 'components/typography';
import { useDashboard } from 'context/dashboard';

import DetachIpv4Btn from './DetachIpv4Btn';

import classes from '../../index.module.scss';

type TProps = {
  ipv4object: IIpv4,
  ipv4: string,
  status: string,
};

/**
 * @description The Ipv4 component shows the address and
 * the ability to copy it and detach it from the machine
 *
 * @param props: ipv4object, ipv4, index, status
 * @param ipv4object required
 * @param ipv4 required
 * @param index required
 * @param status required
 *
 * @returns div with ipv4, copy icon and detach button
*/

export default function Ipv4(props: TProps) {
  // props
  const {ipv4object, ipv4, status} = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const vmStatus = status === 'DELETED' || status === 'DELETION_REQUESTED';
  const resetStatus = status === 'RESET_REQUESTED' || status === 'REBOOT_REQUESTED';
  const correctIpv4 = ipv4.replace('/32', '');
  const instanceType = localStorage.getItem('selectedInstanceType');
  const getType = fetchFunc.instance.get.data?.mtype ? fetchFunc.instance.get.data?.mtype : instanceType;
  const { handlePagePaths } = useGetRoutesPagePath();
  const { pathname } = useLocation();
  const { ipv4Id } = useParams<{tenantId: string, ipv4Id: string}>();

  // style
  const { ipv4Class, ipv4IconSectionClass } = classes;

  return (
    <div className={ipv4Class}>
      {((ipv4Id === ipv4object.uuid) && pathname.includes('/ipv4s/')) ?
        <Typography
          text={correctIpv4}
          fontSize='s'
          fontWeight='thin'
          color='customColorDarkBlue80'
        />
        :
        <Link
          to={handlePagePaths(ipv4object?.uuid).ipv4}
          className='cursor-pointer'>
          <Typography
            text={correctIpv4}
            fontSize='s'
            fontWeight='thin'
            color='customColorDarkBlue80'
            customStyle='hover:text-primaryColorEasternBlueBtnHover'
          />
        </Link>
      }
      <div className={ipv4IconSectionClass}>
        <CopyComponent
          value={correctIpv4}
          dataTestId='test_id_vms_ipv6_tooltip'
          iconColor='fill-customColorDarkBlue60'
        />
        {(getType === 'baremetal') ?
          null :
          <>
            {(!vmStatus && !resetStatus) &&
              <DetachIpv4Btn
                ipv4object={ipv4object}
              />
            }
          </>
        }
      </div>
    </div>
  );
};
