import { useDashboard } from 'context/dashboard';
import { INetwork } from 'types';

import { useMakeRequestGetNetworksImmediately } from './useGetNetworks';

export const useFilterNetworks = () => {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data } = fetchFunc.networks.get;
  useMakeRequestGetNetworksImmediately();

  const norway = data.filter(
    (item: INetwork) => item.cluster_uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5');
  const sweden = data.filter(
    (item: INetwork) => item.cluster_uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7');

  return {
    norway,
    sweden,
    amount: {
      count: data.length,
      norway: norway.length,
      sweden: sweden.length,
    },
  };
};
