import { PAGE_INDEX, TCustomIndexPage, TDataTestIdsOverviewPage } from 'data-test-ids/types/overview-page';

function returnIds(page: PAGE_INDEX): TDataTestIdsOverviewPage {
  const INDEX: TCustomIndexPage = `test_id_${page}`;

  const DATA_TEST_IDS_OVERVIEW_PAGE: TDataTestIdsOverviewPage = {
    info: `${INDEX}_info`,
    content: {
      header: {
        name: `${INDEX}_content_header`,
        title: `${INDEX}_content_header_title`,
      },
      main: {
        name: `${INDEX}_content_main`,
        header: {
          name: `${INDEX}_content_main_header`,
          vmPage: {
            name: `${INDEX}_content_main_header_mainSection_name`,
            status: `${INDEX}_content_main_header_mainSection_status`,
            tooltips: {
              tooltipReset: `${INDEX}_content_main_header_mainSection_tooltip_reset`,
              tooltipReboot: `${INDEX}_content_main_header_mainSection_tooltip_reboot`,
            },
            btnOpenTerminal: `${INDEX}_content_main_header_mainSection_tooltip_reboot`,
            menu: {
              name: '',
              powerOff: '',
              powerOn: '',
              delete: '',
            },
          },
          networkPage: {
            name: `${INDEX}_content_main_header_mainSection_name`,
            tooltips: {
              tooltipRename: `${INDEX}_content_main_header_mainSection_tooltip`,
              tooltipDelete: `${INDEX}_content_main_header_mainSection_tooltip`,
            },
            buttonRename: `${INDEX}_content_main_header_mainSection_buttonRename`,
            buttonDelete: `${INDEX}_content_main_header_mainSection_buttonDelete`,
            modalFormDeleteItem: {
              name: `${INDEX}_content_main_header_modalFormDeleteItem`,
            },
            modalFormRenameItem: {
              name: `${INDEX}_content_main_header_modalFormRenameItem`,
            },
          },
        },
        info: {
          name: `${INDEX}_content_main_info`,
          items: {
            name: `${INDEX}_content_main_info_items_name`,
            itemsSection: {
              name: `${INDEX}_content_main_info_items_itemsSection_name`,
              title: `${INDEX}_content_main_info_items_itemsSection_title`,
              value: `${INDEX}_content_main_info_items_itemsSection_value`,
            },
          },
          aside: {
            name: `${INDEX}_content_main_info_aside_name`,
            header: {
              name: `${INDEX}_content_main_info_aside_header_name`,
              title: `${INDEX}_content_main_info_aside_header_title`,
            },
            children: {
              name: `${INDEX}_content_main_info_aside_children_name`,
              noVms: {
                wrapper: `${INDEX}_content_main_info_aside_children_no_vm_name`,
                text: `${INDEX}_content_main_info_aside_children_no_vm_text`,
              },
              items: {
                name: `${INDEX}_content_main_info_aside_children_items_name`,
                item: {
                  title: `${INDEX}_content_main_info_aside_children_items_item_name`,
                  value: `${INDEX}_content_main_info_aside_children_items_item_value`,
                },
              },
            },
          },
        },
      },
    },
  };

  return DATA_TEST_IDS_OVERVIEW_PAGE;
};

export { returnIds };
