import React, { ComponentType, ReactNode, HTMLProps, SVGProps } from 'react';

// IntrinsicElements contains a list of all possible native JSX tags
type ValidTags = keyof JSX.IntrinsicElements;
interface DynamicComponentProps<T extends ValidTags> {
  // We will also want to support other react components
  tag?: T | ComponentType;
  children?: ReactNode;
  dataTestId?: string | string[];
}

// Conditionally use the correct props based on the 'tag' prop
type DyntamicComponentProps<
  T extends ValidTags
> = T extends keyof HTMLElementTagNameMap // Used if the tag is part of HTML
  ? HTMLProps<HTMLElementTagNameMap[T]> // if it's not HTML, let's check if it's an SVG instead
  : T extends keyof SVGElementTagNameMap
  ? SVGProps<SVGElementTagNameMap[T]> // If it's not an SVG, we don't really have any other options
  : {};

const TagComponent = <T extends ValidTags>({
  // Casting 'div' into a valid tag name to prevent TS from infering it as a string.
  tag: Tag = 'div' as T,
  dataTestId = 'data-testid',
  ...elementProps
}: DynamicComponentProps<T> & DyntamicComponentProps<T>) => {
  return <Tag {...elementProps} />;
};

export default TagComponent;
