// tests
const apiPrefix = false ? 'extras/' : '';

export const apiUrls = {
  instance: apiPrefix + 'vms/vm/',
  instances: apiPrefix + 'vm/vms/',
  tenants: apiPrefix + 'info/customers',
  network: apiPrefix + 'network/network/',
  networks: apiPrefix + 'network/networks/',
  ipv4: apiPrefix + 'network/ipv4/',
  ipv4s: apiPrefix + 'network/ipv4s/',
  sshKeyNew: 'sshkeys/sshkey/',
  sshKeys: apiPrefix + 'sshkeys/',
  // metrics: 'extras/metrics/',
  // images: 'extras/images/',
  images: 'info/images/',
  // flavors: 'extras/flavors/',
  flavors: 'info/flavors/',
  clusters: 'info/clusters',
  maintenance: `${process.env.REACT_APP_STATUS_PAGE_API_ENV}/maintenance_and_incidents_and_scheduled`,
};
