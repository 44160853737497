import { useCallback } from 'react';

import { useInstance } from 'context/instance';
import { setInstance, setInstanceValidation, setImageGroup} from 'actions/instance';
import { ITabsItemImage } from 'types';

export default function useSelectImage() {
  // variables
  const context = useInstance();
  const dispatch = context[1];

  // func
  const handleSelectedImage = useCallback((item: ITabsItemImage ) => {
    dispatch(setInstance(item, 'image'));
    dispatch(setInstance('', 'error'));
    dispatch(setInstanceValidation(false, 'image'));
  }, [dispatch]);

  const handleSelectedImageGroup = useCallback((id: number) => {
    dispatch(setImageGroup(id));
  }, [dispatch]);

  return { handleSelectedImageGroup, handleSelectedImage };
};
