// import dataImages from 'data/availableImages.json';
// import dataFlavors from 'data/flavors.json';
// import dataClusters from 'data/publicClusters.json';
import { IExtrasFlavors, IExtrasImages } from 'types/index';

import checkCache from './checkCache';
import getListOfClusters from './getListOfClusters';

type TElement = {
  uuid: string,
  name: string,
  value?: number,
  location?: {
    city: string,
    country: string,
    region: string,
    full_location: string,
  }
};

function handleMatchItemUUID(items: TElement[], uuid: string) {
  const matched = items?.find((element: any) => {
    return element?.uuid === uuid;
  });
  return matched;
};

function matchClusterUUID(uuid: string, withCountry: boolean): string {
  const allClusters = getListOfClusters();
  const item = handleMatchItemUUID(allClusters, uuid);
  if (item && withCountry) {
    return `${item.location?.country}, Europe`;
  }
  if (item) {
    return `${item.name}`;
  }
  return uuid;
};

function matchFlavorUUID(uuid: string): string {
  const flavors: IExtrasFlavors = checkCache('cache-f');
  const item = handleMatchItemUUID(flavors.display_fe, uuid);
  if (item) {
    return `${item.name}`;
  }
  return uuid;
};

function matchImageUUID(uuid: string): string {
  const images: IExtrasImages = checkCache('cache-i');
  const item = handleMatchItemUUID(images.display_fe, uuid);
  if (item) {
    return `${item.name}`;
  }
  return uuid;
};

export { matchClusterUUID, matchFlavorUUID, matchImageUUID };
