import { TCustomIndexPage, TDataTestIds, TPageName } from '../types/general-pages';

function returnIds(namePage: TPageName): TDataTestIds {
  const SUBHEADER_INDEX: TCustomIndexPage = `${namePage}_subheader`;
  const MODAL_FORM_INDEX: TCustomIndexPage = `${namePage}_modal_form`;

  // const PAGE_LAYOUT_INDEX: TCustomIndexPage = `${namePage}_section_bulk_operation`;
  // const SECTION_BULK_OPERATION_INDEX: TCustomIndexPage = `${namePage}_section_bulk_operation`;
  // const MODAL_BULK_OPERATIONS_INDEX: TCustomIndexPage = `${namePage}_modal_bulk_operations`;

  const DATA_TEST_IDS_PAGES: TDataTestIds = {
    subheader: {
      wrapper: {
        name: `${SUBHEADER_INDEX}_wrapper`,
        info: {
          name: `${SUBHEADER_INDEX}_info`,
          title: `${SUBHEADER_INDEX}_title`,
          description: `${SUBHEADER_INDEX}_description`,
        },
      },
      btnWrapper: {
        name: `${SUBHEADER_INDEX}_btn_wrapper`,
        button: `${SUBHEADER_INDEX}_create_btn`,
      },
    },
    modalForm: {
      portalModal: {
        name: `${MODAL_FORM_INDEX}_modal`,
        form: {
          header: `${MODAL_FORM_INDEX}_new`,
          btnWrapper: {
            name: `${MODAL_FORM_INDEX}_btn_wrapper`,
            btnSubmit: `${MODAL_FORM_INDEX}_submit_btn`,
            btnCancel: `${MODAL_FORM_INDEX}_cancel_btn`,
          },
          content: {
            wrapper: `${MODAL_FORM_INDEX}_content_wrapper`,
            inputSelect: `${MODAL_FORM_INDEX}_input_select`,
            inputText: `${MODAL_FORM_INDEX}_input_text`,
          },
        },
      },
    },
    bulkOperationSection: {

    },
    pageLayout: {

    },
    modalBulkOperations: {

    },
  };

  return DATA_TEST_IDS_PAGES;
};

export { returnIds };

// data-testid={dataTestId}
