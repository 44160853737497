import { useTenants } from 'context/tenants';
import { api } from 'helpers/api';
import { TSuccessNotifications } from 'types';

type TPage = 'ips' | 'networks' | 'sshKeys' | 'virtualmachine' | 'baremetal' | 'instances';
type TPreselectedRemovingItemsUuid = string[];

export default function useCreatePromises(page: TPage, preselectedRemovingItemsUuid: TPreselectedRemovingItemsUuid) {

  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  const getUrl = (uuid: string) => {
    if (page === 'ips') return `network/ipv4/${uuid}`;
    if (page === 'networks') return `network/network/${uuid}`;
    if (page === 'sshKeys') return `sshkeys/sshkey/${selectedTenantId}/${uuid}`;
    if (page === 'instances' || page === 'virtualmachine' || page === 'baremetal') return `vm/vm/${uuid}`;
    return 'pass correct path';
  };

  const preselectedRemovingItemsPromise: Promise<TSuccessNotifications>[] = [];

  const createPromises = () => {
    for (let i = 0; i < preselectedRemovingItemsUuid.length; i++) {
      const promises = api.useFetchData<[], TSuccessNotifications>(getUrl(preselectedRemovingItemsUuid[i]), 'delete');
      preselectedRemovingItemsPromise.push(promises);
    }
  };

  return { createPromises, preselectedRemovingItemsPromise };
};
