import React from 'react';

import Typography from 'components/typography';

import glossary from '../../../../data/glossary.json';

type TProps = {
  dataTestIds?: any,
};

/**
 * @description The NoVms component
 * @param props dataTestIds
 * @param dataTestIds required
 *
 * @returns div | Typography
*/

export default function NoVms(props: TProps) {
  // props
  const { dataTestIds = 'test_id_no_vms_text' } = props;

  return (
    <div
      data-testid='test_id_no_vms_wrapper'
      className='pl-6 py-4'
    >
      <Typography
        text={glossary.empty_state.no_instances}
        fontWeight='medium'
        fontSize='s'
        dataTestId={dataTestIds}
      />
    </div>
  );
};
