import { useLocation } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import { matchClusterUUID, matchFlavorUUID, matchImageUUID } from 'helpers/MatchUUIDs';

import glossary from '../data/glossary.json';

export type TItems = {title: string, value: string}[]

export default function useGetItemsDetailPage() {
  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.instance.get;
  const { status, uuid: vmUUID = '', flavor_uuid = '', image_uuid = '', network_uuid = '' } = data;
  const cluster = `${matchClusterUUID(data.cluster_uuid, false)} ${matchClusterUUID(data.cluster_uuid, true)}`;
  const location = useLocation();
  const instanceType = localStorage.getItem('selectedInstanceType') || '';

  const showCorrectTypeName = (type: string) => {
    if(type === 'baremetal') {
      return glossary.bare_metal;
    };
    if(type === 'virtualmachine') {
      return glossary.virtual_machine;
    };
    return '-';
  };

  const infoNetwork = {
    title: 'Network UUID',
    value: data?.network ? data?.network?.network_name : network_uuid,
  };

  const infoStatus = {
    title: 'Status',
    value: status,
  };

  const items: TItems = [
    {
      title: glossary.uuid,
      value: vmUUID,
    },
    {
      title: glossary.flavor,
      value: matchFlavorUUID(flavor_uuid) || '',
    },
    {
      title: glossary.cluster,
      value: cluster,
    },
    {
      title: glossary.image,
      value: matchImageUUID(image_uuid) || '',
    },
    {
      title: 'Type',
      value: data?.mtype ? showCorrectTypeName(data?.mtype) : showCorrectTypeName(instanceType),
    },
  ];

  const ITEMS = location.pathname.includes('/instances') ?
    items.toSpliced(1, 0, infoNetwork) : items.toSpliced(1, 0, infoStatus);

  const networkUuid = network_uuid;

  return {ITEMS, networkUuid};
};
