import React from 'react';

import InfoSectionVm from 'components/info-section';
import { TDataTestIdsOverviewPageContentMain } from 'data-test-ids/types/overview-page';
import Subheader from 'feature/vm/Subheader';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMain,
};

/**
  * @description The MainSection
  *
  * @param props dataTestIds
  * @param dataTestIds required
  * @returns div
*/

export default function MainSection(props: TProps) {
  // props
  const {dataTestIds} = props;

  return (
    <div
      id='main-wrapper'
      data-testid={dataTestIds.name}
    >
      <div
        data-testid={dataTestIds.name}
        className={'lightGrayWrapperClass mt-4'}
      >
        <Subheader
          dataTestIds={dataTestIds.header.vmPage}
        />
      </div>
      <InfoSectionVm
        dataTestIds={dataTestIds.info}
      />
    </div>
  );
};
