import { ReactNode } from 'react';

type TProps = {
  children: ReactNode,
};

const Cell: React.FC<TProps> = ({children}) => (
  <td>
    {children}
  </td>
);

export default Cell;
