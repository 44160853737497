type TInfo = {
  status: 'error' | 'validation' | 'warning' | 'success',
  msg: string | number,
  dataTestId?: string,
};

export default function Info({status, msg, dataTestId}: TInfo) {

  const returnStyle = () => {
    if (status === 'error') {
      return 'text-red-600 bg-red-50 border border-red-300 p-2 m-1 ';
    }
    if (status === 'validation') {
      return 'text-customDarkRed border-0 mb-1.5 absolute mt-[5px] ml-5';
    }
    if (status === 'warning') {
      return 'text-customDarkYellow border-0 mb-1.5 absolute mt-[5px] ml-5';
    }
    if (status === 'success') {
      return 'text-green-600 bg-green-50 border border-green-300 p-2 m-1 ';
    }
    return 'text-cyan-600 bg-cyan-50 border border-cyan-300 p-2 m-1 ';
  };

  return (
    <div
      data-testid={dataTestId}
      className={`${returnStyle()} text-s font-medium leading-relaxed rounded-md`}
    >
      {msg}
    </div>
  );
};
