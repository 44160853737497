import { useDashboard } from 'context/dashboard';
import { matchClusterUUID } from 'helpers/MatchUUIDs';

import glossary from '../data/glossary.json';

export default function useGetItemsIp() {
  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.ip.get;
  const cluster = matchClusterUUID(data.cluster_uuid, false);

  const ITEMS: {title: string, value: string}[] = [
    // {
    //   title: 'Ip address',
    //   value: data.address4,
    // },
    {
      title: glossary.uuid,
      value: data.uuid,
    },
    {
      title: glossary.cluster,
      value: cluster,
    },
  ];

  return ITEMS;
};
