import { INotification } from 'types';

import classess from './notification.module.scss';
import NotificationComponent from './NotificationComponent';
import Notifications from './Notifications';

type TProps = {
  isOneNitification: boolean,
  text: 'success' | 'error' | 'warning' | 'info',
  description?: string,
  notifications?: INotification[],
};

export default function NotificationWrapper(props: TProps) {
  const {
    text,
    description,
    notifications,
    isOneNitification,
  } = props;

  const {
    notificationWrapperSpaceClass,
  } = classess;

  return (
    <div
      aria-live='assertive'
      className={notificationWrapperSpaceClass}
    >
      {isOneNitification ?
        <NotificationComponent
          text={text}
          description={description}
        />
        :
        <Notifications
          notifications={notifications}
        />
      }
    </div>
  );
};
