import { TBodyStatusUpdateVm } from 'feature/actions/Content';
import { api } from 'helpers/api';
import { TActionsDependsOfStatus, TFetchFuncRequestMethod, TSuccessNotifications } from 'types';

type TPage = 'ips' | 'networks' | 'sshKeys' | 'virtualmachine' | 'baremetal' | 'instances';
type TPreselectedRemovingItemsUuid = string[];

export default function useCreatePromisesForUpdateStatusVms(
  page: TPage,
  preselectedRemovingItemsUuid: TPreselectedRemovingItemsUuid,
  fetchMethod: TFetchFuncRequestMethod = 'delete',
  status: TActionsDependsOfStatus = 'VM_ACTIVE') {

  const preselectedRemovingItemsPromise: Promise<TSuccessNotifications>[] = [];

  const createPromises = () => {
    for (let i = 0; i < preselectedRemovingItemsUuid.length; i++) {
      const promisesForUpdateVmsStatus = api.useFetchData<TBodyStatusUpdateVm, TSuccessNotifications>(`vm/vm/${preselectedRemovingItemsUuid[i]}/status`, 'post', null, { status });
      const promises = promisesForUpdateVmsStatus;
      preselectedRemovingItemsPromise.push(promises);
    }
  };

  return { createPromises, preselectedRemovingItemsPromise };
};
