import React from 'react';

import { useInstance } from 'context/instance';
import { setInstance, setInstanceValidation } from 'actions/instance';
import norwayIcon from 'assets/images/norway.png';
import { TLocation } from 'types';

import classes from './locationSection.module.scss';

import TitleSection from '../TitleSection';

type TItem = {
  title: TLocation,
  icon: string,
  testDataId: string,
};

const LocationSection = () => {
  const context = useInstance();
  const state = context[0];
  const dispatch = context[1];
  const { instance: { location } } = state;
  const { itemClass, currentItemClass } = classes;

  const handleChange = (value: TLocation) => {
    dispatch(setInstance(value, 'location'));
    dispatch(setInstance([], 'ips'));
    dispatch(setInstance({}, 'network'));
    dispatch(setInstanceValidation('', 'network'));
    dispatch(setInstance([], 'preselectedIpv4s'));
  };

  const items: TItem[] = [
    {
      title: 'Norway',
      icon: norwayIcon,
      testDataId: 'norway_location',
    },
  ];

  return (
    <div
      id='LocationSection'
      className='col-span-6'
    >
      <TitleSection
        title='Location'
        description='
          A location refers to the geographic location where your instance is created.
          Please be aware that it is not possible to mix compute resources from different location.
        '
      />
      <div className='row mt-8'>
        {items.map((item: TItem) => {
          const { title, icon, testDataId } = item;
          return (
            <div
              key={title}
              className={`${itemClass} ${location === title ? currentItemClass  : ''} all-items-center`}
              onClick={() => handleChange(title)}
              data-testid={testDataId}
            >
              <img
                src={icon}
                alt='norway'
                className='w-[18px] my-[13px] mx-4'
              />
              {title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LocationSection;
