import { useCallback, useEffect } from 'react';

import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { IIpv4 } from 'types';
import { useTenants } from 'context/tenants';
import { apiUrls } from 'helpers/apiUrls';

/**
 * @description useMakeRequestGetIpv4sByClick - a hook that returns a function
 * that makes an API /GET request to get all instances
 * @returns handleGetIpv4s
 */

function useMakeRequestGetIpv4sByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const url = `${apiUrls.ipv4s}${selectedTenantId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const page = 'ips';
  const fetchMethod = 'get';

  // func
  const handleGetIpv4s = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getIpv4s = api.useFetchData<[], Array<IIpv4>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getIpv4s);
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url]);

  return { handleGetIpv4s };
};

/**
 * @description useMakeRequestGetIpv4sImmediately - a hook that makes an API /GET request
 * to immediately get all instances using useEffect
 * @returns null
 */

function useMakeRequestGetIpv4sImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetIpv4s } = useMakeRequestGetIpv4sByClick();

  // hooks
  useEffect(() => {
    if (selectedTenantId) {
      handleGetIpv4s();
    }
    return () => {};
  }, [selectedTenantId, handleGetIpv4s]);
};

export {
  useMakeRequestGetIpv4sByClick,
  useMakeRequestGetIpv4sImmediately,
};
