// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import Info from 'components/Info';
import classesDetails from 'feature/new-instance/keys/key.module.scss';
import { IIpv4, INetwork, TTenant } from 'types';
import useOnClickOutside from 'hooks/useClickOutside';
import CheckIconGreen from 'assets/icons/check-green.svg';
import { TCluster } from 'types';

import CustomInputLabel from './Label';
import classes from './forms.module.scss';

type TProps = {
  dataTestId?: string,
  isShowLabel?: boolean,
  id?: string,
  classNames?: string,
  disabled?: boolean,
  textDisabled?: string,
  selectFeedback?: string,
  // required
  handleChange: (value: string, uuid: string) => void,
  name: string,
  label: string,
  value: string,
  options: TTenant[] | INetwork[] | IIpv4[] | TCluster[],
  optionValue: 'customer_name' | 'network_name' | 'address4' | 'name',
};

/**
 * @description CustomSelect component
 * @param props dataTestId, isShowLabel, id, classNames, disabled, textDisabled, selectFeedback
 * @param handleChange required
 * @param name required
 * @param label required
 * @param value required
 * @param options required
 * @param optionValue required
 * @returns dropdown menu
 */

const CustomSelect = (props: TProps) => {
  // state
  const [openList, setOpenList] = useState<boolean>(false);

  // refs
  const refSelect = useRef(null);

  // props
  const {
    handleChange = () => {},
    name = '',
    label = '',
    value = '',
    dataTestId = '',
    isShowLabel = true,
    id = '',
    classNames = '',
    disabled = false,
    textDisabled = false,
    selectFeedback = '',
    options = [],
    optionValue = 'uuid',
  } = props;

  // style
  const { customSelectClass, customSelectDisabledClass, validationClass } = classes;
  const { listClass, listItem } = classesDetails;
  const defaultItemStyle = `${listClass} z-10 text-sm font-medium w-full`;

  // func
  const statusView = () => {
    if (selectFeedback && !disabled) return `${classNames} ${validationClass} placeholder-customColorDarkBlue`;
    if (disabled) return `${customSelectDisabledClass} ${classNames}`;
    return `${customSelectClass} ${classNames}`;
  };

  const handleClickOutside = () => {
    const el = document.getElementById(`${id ? id : name}`) as HTMLDetailsElement;
    el.open = false;
    setOpenList(false);
  };

  useOnClickOutside(refSelect, handleClickOutside);

  const handleSelect = (value: string, uuid: string) => {
    handleChange(value, uuid);
    handleClickOutside();
  };

  const onToggle = (event: React.MouseEvent<HTMLDetailsElement>) => {
    event.preventDefault();
    setOpenList(!openList);
  };

  return (
    <>
      {isShowLabel ?
        <CustomInputLabel
          name={name}
          label={label}
        /> : null
      }
      <details
        ref={refSelect}
        id={id ? id : name}
        data-testid={dataTestId}
        aria-label={name}
        className='relative'
        open={disabled ? false : openList}
        onClick={onToggle}
      >
        <summary className={statusView()}>
          {disabled ?
            <>
              <p>{ textDisabled ? textDisabled : `Select ${label}` }</p>
              <ChevronDownIcon
                className='h-5 w-5 fill-customGray3 cursor-pointer stroke-[1.5px]'
                aria-hidden='true'
              />
            </>
            :
            <>
              <p>{ !value ? `Select ${label}` : value }</p>
              {openList ?
                <ChevronUpIcon
                  className='h-5 w-5 fill-customColorDarkBlue cursor-pointer stroke-[1.5px]'
                  aria-hidden='true'
                />
                :
                <ChevronDownIcon
                  className='h-5 w-5 fill-customColorDarkBlue cursor-pointer stroke-[1.5px]'
                  aria-hidden='true'
                />
              }
            </>
          }
        </summary>
        <div className={defaultItemStyle}>
          {options?.map((option)  => {
            const { uuid } = option;
            return (
              <div
                key={uuid}
                className={`${listItem} row justify-center items-center`}
                onClick={() => handleSelect(option[optionValue], uuid)}
              >
                <p className='select-none text-s font-medium truncate max-w-sm'>
                  {option[optionValue]}
                </p>
                {option[optionValue] === value ?
                  <img src={CheckIconGreen} alt='check-icon' className='ml-2' /> :
                  ''
                }
              </div>
            );
          })}
        </div>
      </details>
      {selectFeedback &&
        <Info
          status='validation'
          msg={selectFeedback}
        />
      }
    </>
  );
};

export default CustomSelect;
