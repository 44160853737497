import React from 'react';

import Typography from 'components/typography';
import { TDataTestIdsOverviewPageContentMainInfoItems } from 'data-test-ids/types/overview-page';

import useGetItems from '../../../hooks/useGetItemsNetwork';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainInfoItems,
};

/**
  * @description The Items
  * @augments Network_page
  * @augments Content
  * @augments Main_section
  * @augments Info_section
  *
  *
  * @param props dataTestIds
  * @param dataTestIds required
  *
  * @todo dynamic datatestid for items section = title and value
  * @returns div
*/

export default function Items(props: TProps) {
  const { dataTestIds } = props;
  const items = useGetItems();

  return (
    <div
      data-testid={dataTestIds.name}
      className='grid grid-cols-2 gap-y-10 gap-x-28'
    >
      {items.map((item: {title: string, value: string}) =>
        <div
          key={item.title}
          className='flex col'
          data-testid={dataTestIds.itemsSection.name}
        >
          <div className='mb-2'>
            <Typography
              text={item.title}
              fontWeight='medium'
              color='customColorDarkBlue80'
              fontSize='s'
              dataTestId={dataTestIds.itemsSection.title}
            />
          </div>
          <Typography
            text={item.value}
            fontWeight='thin'
            color='customColorDarkBlue80'
            fontSize='s'
            dataTestId={dataTestIds.itemsSection.value}
          />
        </div>,
      )}
    </div>
  );
};
