import { TStatusVm } from 'types';

export default function useCheckVmsStatusForDeletion() {

  const checkVmsStatusForDeletion = (status: TStatusVm) => {

    if(status === 'DELETED' || status === 'DELETION_REQUESTED' || status === 'NETWORK_READY' || status === 'SCHEDULED') {
      return false;
    };

    return true;
  };

  return checkVmsStatusForDeletion;
};
