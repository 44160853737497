import {
  SET_TENANTS,
  SET_SELECTED_TENANT,
  SET_PRESELECTED_TENANT,
  SET_SELECTED_TENANT_ID,
} from 'reducers/tenants';

import {
  TFetchFuncStatus, TTenant,
} from 'types';

export function setPreselectedTenant(preselectedTenant: TTenant) {
  return {
    type: SET_PRESELECTED_TENANT,
    preselectedTenant,
  };
};

export function setSelectedTenant(selectedTenant: TTenant) {
  return {
    type: SET_SELECTED_TENANT,
    selectedTenant,
  };
};

export function setSelectedTenantId(selectedTenantId: any) {
  return {
    type: SET_SELECTED_TENANT_ID,
    selectedTenantId,
  };
};

export function setTenants(
  tenants: string | number | boolean | TTenant[],
  tenantKey: TFetchFuncStatus,
) {
  return {
    type: SET_TENANTS,
    tenants,
    tenantKey,
  };
};

