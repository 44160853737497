import { useEffect, useState } from 'react';

import { api } from 'helpers/api';
import { useTenants } from 'context/tenants';
import { useDashboard } from 'context/dashboard';
import { apiUrls } from 'helpers/apiUrls';
// import checkCache from 'helpers/checkCache';
// import { cacheable } from 'helpers/cacheable';
import { useLocation } from 'react-router-dom';

export default function useGetMetricsNetworks() {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | unknown>(0);
  const [data, setData] = useState<any>({count: 0, clusters: []});

  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  // const url = `${apiUrls.metrics}${selectedTenantId}/networks`;
  const url = `${apiUrls.networks}${selectedTenantId}`;
  const context = useDashboard();
  const dispatch = context[1];
  const { pathname } = useLocation();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      async function loadData() {
        setError('');

        // const cachedResult = pathname === '/' ? false : checkCache('cache-c');
        // let result;

        // if (cachedResult) {
        //   result = cachedResult;
        //   setData(result);
        // } else {
        const request = api.useFetchData<[], {}[]>(url, 'get', signal);
        try {
          setLoading(true);
          setError('');
          const getMetrics = await request;

          const metrics = {
            count: getMetrics?.length,
            clusters: [],
          };
          setData(metrics);
        } catch (error) {
          if (error instanceof Error) {
            setStatus(error?.cause);
            setError(error?.message || '');
          }
          setData([]);
        }
        //   result = await cacheable(() => request, [], 'cache-c');
        //   setData(result);
        //   setLoading(false);
        // }
        setLoading(false);
      }

      loadData();
    }

    return () => {
      controller.abort();
      setData([]);
    };
  }, [url, dispatch, selectedTenantId, pathname]);

  return { error, loading, status, data };
};
