import React, { ChangeEvent } from 'react';
import CustomCheckbox from 'components/input/Checkbox';

import Row from './Row';

type TProps = {
  data: Array<string>,
  handleDeselectItems: (event: ChangeEvent<HTMLInputElement>) => void,
  isAllSelected: boolean,
  isShowCheckbox: boolean,
  handleSelectedAllItems: () => void,
  disabledCheckbox?: boolean,
  dataTestId?: string,
};

export default function Head (props: TProps) {
  const {
    data,
    handleDeselectItems,
    isAllSelected,
    isShowCheckbox = true,
    handleSelectedAllItems,
    disabledCheckbox = false,
    dataTestId,
  } = props;

  const returnPosition = (item: string) => {
    if (item === 'Attached') {
      return 'justify-center pr-[10px]';
    };
    return 'justify-start';
  };

  const styleTable = 'pb-4 text-s font-medium';

  return (
    <thead>
      <Row>
        {isShowCheckbox &&
          <th
            className='pb-4 flex'
          >
            <CustomCheckbox
              type='checkbox'
              name='allIsSelected'
              handleChange={isAllSelected ? handleDeselectItems : handleSelectedAllItems}
              value={''}
              aria-label='isSelected'
              isSelected={isAllSelected || false}
              disabled={disabledCheckbox}
              checkboxState={isAllSelected ? 'Indeterminate' : 'Empty'}
              dataTestId={dataTestId}
            />
          </th>
        }
        {data.map((item) =>(
          <th
            key={item}
            scope='col'
            className={`${styleTable}`}
          >
            <div className={`row ${returnPosition(item)}`}>
              {item}
            </div>
          </th>
        ))}
      </Row>
    </thead>
  );
};
