import { TPageName, TDataTestIdsNewVm } from '../types/new-vm';

function returnIds(): TDataTestIdsNewVm {
  const NEW_VM_INDEX: TPageName = 'new_vm_page';

  const DATA_TEST_IDS_NEW_VM: TDataTestIdsNewVm = {
    newVm: {
      main: {
        header: `${NEW_VM_INDEX}_main_header`,
        vmName: `${NEW_VM_INDEX}_main_vm_name`,
        location: `${NEW_VM_INDEX}_main_location`,
        flavor: `${NEW_VM_INDEX}_main_flavor`,
        images: `${NEW_VM_INDEX}_main_images`,
        network: {
          wrapper: {
            name: `${NEW_VM_INDEX}_network_wrapper_name`,
            title: {
              name: `${NEW_VM_INDEX}_network_title_name`,
            },
            select: {
              name: `${NEW_VM_INDEX}_network_select_name`,
            },
            newNetwork: {
              wrapper: `${NEW_VM_INDEX}_network_wrapper_new_network_name`,
              button: `${NEW_VM_INDEX}_network_modal_form_create_btn`,
              modalForm: {
                portalModal: {
                  name: `${NEW_VM_INDEX}_network_modal`,
                  form: {
                    header: `${NEW_VM_INDEX}_network_new`,
                    btnWrapper: {
                      name: `${NEW_VM_INDEX}_network_btn_wrapper`,
                      btnSubmit: `${NEW_VM_INDEX}_network_submit_btn`,
                      btnCancel: `${NEW_VM_INDEX}_network_cancel_btn`,
                    },
                    content: {
                      wrapper: `${NEW_VM_INDEX}_network_content_wrapper`,
                      inputSelect: `${NEW_VM_INDEX}_network_input_select`,
                      inputText: `${NEW_VM_INDEX}_network_input_text`,
                    },
                  },
                },
              },
            },
            warning: `${NEW_VM_INDEX}_network_warning`,
            error: `${NEW_VM_INDEX}_network_error`,
          },
        },
        authentication: {
          wrapper: {
            name: `${NEW_VM_INDEX}_ssh_keys_wrapper_name`,
            title: `${NEW_VM_INDEX}_ssh_keys_title`,
            note: `${NEW_VM_INDEX}_ssh_keys_note`,
            addKey: {
              wrapper: {
                name: `${NEW_VM_INDEX}_ssh_keys_add_key_wrapper_name`,
                button: `${NEW_VM_INDEX}_ssh_keys_modal_form_create_btn`,
                modalForm: {
                  portalModal: {
                    name: `${NEW_VM_INDEX}_ssh_keys_modal`,
                    form: {
                      header: `${NEW_VM_INDEX}_ssh_keys_new`,
                      btnWrapper: {
                        name: `${NEW_VM_INDEX}_ssh_keys_btn_wrapper`,
                        btnSubmit: `${NEW_VM_INDEX}_ssh_keys_submit_btn`,
                        btnCancel: `${NEW_VM_INDEX}_ssh_keys_cancel_btn`,
                      },
                      content: {
                        wrapper: `${NEW_VM_INDEX}_ssh_keys_content_wrapper`,
                        inputSelect: `${NEW_VM_INDEX}_ssh_keys_input_select`,
                        inputText: `${NEW_VM_INDEX}_ssh_keys_input_text`,
                      },
                    },
                  },
                },
              },
            },
            error: `${NEW_VM_INDEX}_network_wrapper_error`,
          },
        },
        ips: {
          wrapper: {
            name: `${NEW_VM_INDEX}_ips_wrapper_name`,
            title: `${NEW_VM_INDEX}_ips_title`,
            ipv6: `${NEW_VM_INDEX}_ips_ipv6`,
            selectedIps: `${NEW_VM_INDEX}_ips_selected_ips`,
            addIpv4Wrapper: {
              name: `${NEW_VM_INDEX}_ips_add_ipv4_wrapper_name`,
              button: `${NEW_VM_INDEX}_ips_modal_form_create_btn`,
              modalForm: {
                portal: {
                  portalModal: {
                    name: `${NEW_VM_INDEX}_ips_modal`,
                    form: {
                      header: `${NEW_VM_INDEX}_ips_new`,
                      btnWrapper: {
                        name: `${NEW_VM_INDEX}_ips_btn_wrapper`,
                        btnSubmit: `${NEW_VM_INDEX}_ips_submit_btn`,
                        btnCancel: `${NEW_VM_INDEX}_ips_cancel_btn`,
                      },
                      content: {
                        wrapper: `${NEW_VM_INDEX}_ips_content_wrapper`,
                        inputSelect: `${NEW_VM_INDEX}_ips_input_select`,
                        inputText: `${NEW_VM_INDEX}_ips_input_text`,
                      },
                    },
                  },
                },
                warningWrapper: {
                  name: `${NEW_VM_INDEX}_ips_modal_form_warning_wrapper_name`,
                  addIpv4ModalForm: {
                    wrapper: {
                      name: `${NEW_VM_INDEX}_ips_add_ipv4_modal_form_name`,
                      spinner: `${NEW_VM_INDEX}_ips_add_ipv4_modal_form_spinner`,
                      select: `${NEW_VM_INDEX}_ips_select_ipv4_address`,
                      newIp: {
                        name: `${NEW_VM_INDEX}_ips_create_new_ipv4_btn`,
                      },
                      error: `${NEW_VM_INDEX}_ips_add_ipv4_modal_form_error`,
                    },
                  },
                },
              },
            },
          },
        },
      },
      aside: {
        summary: `${NEW_VM_INDEX}_ips_aside_summary`,
      },
      networkNotification: {},
    },
    confirmationDialog: `${NEW_VM_INDEX}_ips_confirmation_dialog`,
    loadingAlert: `${NEW_VM_INDEX}_ips_loading_alert`,
    succesAlert: `${NEW_VM_INDEX}_ips_succes_alert`,
  };

  return DATA_TEST_IDS_NEW_VM;
};

export { returnIds };

// data-testid={dataTestId}
