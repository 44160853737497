import { TTenantsAction, TTenantsReducer } from 'types';

// action types
export const SET_TENANTS = 'TENANTS.SET_TENANTS';
export const SET_SELECTED_TENANT = 'TENANTS.SET_SELECTED_TENANT';
export const SET_SELECTED_TENANT_ID = 'TENANTS.SET_SELECTED_TENANT_ID';
export const SET_PRESELECTED_TENANT = 'TENANTS.SET_PRESELECTED_TENANT';

// variables
const selectedTenantStorageParse = localStorage.getItem('selectedTenantStorage') || '';
const selectedTenantStorage = selectedTenantStorageParse && JSON.parse(selectedTenantStorageParse);
const selectedTenantIdStorage = localStorage.getItem('selectedTenantIdStorage') || '';
const tenantsStorageParse = localStorage.getItem('tenantsStorage') || '';
const tenantsStorage = tenantsStorageParse && JSON.parse(tenantsStorageParse);

export const initialStateTenants: TTenantsReducer = {
  tenants: {
    error: '',
    status: 0,
    loading: false,
    message: '',
    data: tenantsStorage || [],
  },
  selectedTenantId: selectedTenantIdStorage || '',
  selectedTenant: selectedTenantStorage || {
    customer_name: '',
    uuid: '',
    username: '',
  },
  preselectedTenant: selectedTenantStorage || {
    customer_name: '',
    uuid: '',
    username: '',
  },
};

/**
 * @description Tenants reducer function
 * @param state
 * @param action
 * @returns
 */

export function reducerTenants(
  state: TTenantsReducer,
  action: TTenantsAction,
): TTenantsReducer {
  switch (action.type) {
  case SET_TENANTS:
    return {
      ...state,
      tenants: {
        ...state.tenants,
        [action.tenantKey]: action.tenants,
      },
    };
  case SET_SELECTED_TENANT:
    return {
      ...state,
      selectedTenant: action.selectedTenant,
    };
  case SET_SELECTED_TENANT_ID:
    return {
      ...state,
      selectedTenantId: action.selectedTenantId,
    };
  case SET_PRESELECTED_TENANT:
    return {
      ...state,
      preselectedTenant: action.preselectedTenant,
    };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  };
};
