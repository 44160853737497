import React from 'react';

import { useDashboard } from 'context/dashboard';
import { INotification, TFetchFuncPage, TFetchFuncRequestMethod, TModalActionsItemsName } from 'types';
import getVMsStatus, { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';
import Typography from 'components/typography';

import glossary from '../../../data/glossary.json';

type TProps = {
  actionName: TActionsMenuComponent,
  itemsName: TModalActionsItemsName,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  numberOfItems: number,
  errorsObject: INotification[],
  successObject: INotification[],
};

export default function ContentViewMultyItems(props: TProps) {
  // props
  const {
    actionName,
    fetchMethod,
    page,
    numberOfItems,
    errorsObject,
    successObject,
    itemsName,
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { loading } = fetchFunc[page][fetchMethod];
  const isPluralFormName = numberOfItems === 1 ? itemsName : (itemsName + 's');
  const successMsg = `Successfully ${getVMsStatus(actionName).past} ${successObject.length} ${isPluralFormName}`;
  const questionMsg = `${glossary.questions.do_you_really_want_to} ${getVMsStatus(actionName).lowercase} ${numberOfItems} selected ${isPluralFormName}?`;
  const errorMsg = `The following ${errorsObject.length} ${isPluralFormName} cannot be ${getVMsStatus(actionName).past}:`;

  if (loading) {
    return  <Spinner spaceAroundSpinner />;
  };

  return (
    <div className='px-5 py-4'>
      {(successObject.length === 0 && errorsObject.length === 0) &&
        <Typography
          text={questionMsg}
          customStyle='pt-6 pl-1 pb-[10px]'
          fontSize='sm'
          fontWeight='medium'
        />
      }
      {successObject.length !== 0 && <Info status='success' msg={successMsg} />}
      {errorsObject.length !== 0 &&
        <>
          <Typography
            text={errorMsg}
            customStyle={`m-1 ${successObject.length === 0 ? '' : 'pt-7'} pb-7`}
            fontSize='sm'
            fontWeight='medium'
          />
          <div className='max-h-[27rem] overflow-y-scroll'>
            {errorsObject.map((item: INotification) =>
              <div
                className='mb-2'
                key={item.message}
              >
                <Info
                  status='error'
                  msg={item.message}
                />
              </div>,
            )}
          </div>
        </>
      }
    </div>
  );
};
