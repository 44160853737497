import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Typography from 'components/typography';
import Button from 'components/buttons';
import useLogout from 'hooks/useLogout';

import glossary from '../../data/glossary.json';

/**
 * @description user section

 * @returns div
*/

export default function UserSection() {
  // variables
  const { authState } = useOktaAuth();
  const name = authState?.idToken?.claims.name || 'user';
  const email = authState?.idToken?.claims.email || 'user@email.com';
  const { handleLogout } = useLogout();

  return (
    <div className='col'>
      <div className='row justify-between'>
        <Typography
          text='User details'
          customStyle='capitalize self-end pb-1'
          fontSize='sm'
        />
        <Button
          uiStyle='primary'
          title={glossary.log_out}
          customStyle='mb-4 min-w-[12.75rem]'
          handleClick={handleLogout}
        />
      </div>
      <hr />
      <div className='row pt-8 pb-14'>
        <div className='flex-1 ml-2'>
          <Typography
            text='Name'
            fontSize='s'
            fontWeight='medium'
          />
          <Typography
            text={name}
            fontSize='s'
            fontWeight='normal'
          />
        </div>
        <div className='flex-1'>
          <Typography
            text='Email'
            fontSize='s'
            fontWeight='medium'
          />
          <Typography
            text={email}
            fontSize='s'
            fontWeight='normal'
          />
        </div>
        <Button
          uiStyle='secondary'
          customStyle='min-w-[12.75rem]'
        >
          <a
            rel='noreferrer noopener'
            target='_blank'
            href='https://login.northerndata.de/enduser/settings'
            data-testid='test-id-main-header-pop-up-buttons-link-okta'
          >
            {glossary.manage_account_via_okta}
          </a>
        </Button>
      </div>
    </div>
  );
};
