import { setFetchFunc } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { useCallback } from 'react';
import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';

const useCleanState = () => {
  const context = useDashboard();
  const dispatch = context[1];

  const cleanState = useCallback((page: TFetchFuncPage, method: TFetchFuncRequestMethod): void => {
    dispatch(setFetchFunc(0, page, method, 'status'));
    dispatch(setFetchFunc([], page, method, 'data'));
    dispatch(setFetchFunc('', page, method, 'error'));
    dispatch(setFetchFunc(false, page, method, 'loading'));
    dispatch(setFetchFunc('', page, method, 'message'));
  }, [dispatch]);

  return cleanState;
};

export { useCleanState };
