import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/buttons';
import useLogout from 'hooks/useLogout';
import Typography from 'components/typography';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import classes from '../../../index.module.scss';
import glossary from '../../../../../data/glossary.json';

/**
 * @description Buttons component is used in the main header
 * in the popup to show the logout and account settings buttons.
 *
 * @returns div
 */

export default function Buttons() {
  // variables
  const { handleLogout } = useLogout();
  const {
    userDataTextBtnWrapperClass,
  } = classes;
  const { handlePagePaths } = useGetRoutesPagePath();

  return (
    <div
      data-testid='test-id-main-header-pop-up-buttons'
      className={userDataTextBtnWrapperClass}
    >
      <NavLink to={handlePagePaths().profile}>
        <Typography
          text={glossary.profile}
          customStyle='hover:text-primaryColorEasternBlue'
          data-testid='test-id-main-header-pop-up-buttons-profile'
          fontSize='s'
        />
      </NavLink>
      <Button
        customStyle='mt-2'
        handleClick={handleLogout}
        title={glossary.log_out}
        uiStyle='without-style'
        dataTestId='test-id-main-header-pop-up-buttons-log-out'
        size='small'
      />
    </div>
  );
};
