import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { TStatusMaintenanceItem } from 'types';

import classes from './index.module.scss';

type TProps = {
  scheduledMaintenance: TStatusMaintenanceItem[],
  scheduledMaintenanceFullTime: string,
};

export default function ScheduledMaintenanceComponent(props: TProps) {

  const {
    scheduledMaintenance,
    scheduledMaintenanceFullTime,
  } = props;

  const {
    statusScheduledIconBgClass,
    statusScheduledTitleClass,
    statusScheduledTextClass,
    statusScheduledTextLinkClass,
  } = classes;

  return (
    <>
      <div className={statusScheduledIconBgClass}>
        <ExclamationTriangleIcon width='14px' stroke='#DC9224' strokeWidth='1.5' />
      </div>
      <div className='col'>
        <p className={statusScheduledTitleClass}>Scheduled Maintenance</p>
        <div className={statusScheduledTextClass}>
          {scheduledMaintenanceFullTime}
          {scheduledMaintenance?.length > 1 ?
            <div className={statusScheduledTextLinkClass}>
              <a
                href='https://status-cloudservices.northerndata.de'
                rel='noreferrer noopener'
                target='_blank'
                className='cursor-pointer'
              >
              view {scheduledMaintenance?.length - 1} more
              </a>
            </div>
            : null
          }
        </div>
      </div>
    </>
  );
};
