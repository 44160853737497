import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGetSidebarNavLinks } from 'hooks/useGetSidebarNavLinks';

import classes from './Navbar.module.scss';

export default function RoutesComponent() {
  // variables
  const { navLinks } = useGetSidebarNavLinks();
  const {
    activeClass,
    navIconLinkClass,
    navlinkClass,
  } = classes;

  return (
    <>
      {navLinks.map(route => (
        <NavLink
          key={route.path}
          className={navlinkClass}
          activeClassName={activeClass}
          to={route.path}
          exact={route.exact}
        >
          <div className={navIconLinkClass}>
            <img src={route.icon} alt={route.title} />
          </div>
          {route.title}
        </NavLink>
      ))}
    </>
  );
};
