import { TStatusVm } from 'types';

export type TActionsMenuComponent = TStatusVm | 'view' | 'rename' | string;

type TVMsStatus = {
  background: string,
  name: string,
  status: TStatusVm | string,
  lowercase: string,
  action: string | 'test',
  icon: 'on' | 'off' | 'reset' | 'reboot' | 'test' | 'delete' | 'view' | 'rename',
  past: 'powered on' | 'powered off' | 'deleted' | 'rebooted' | 'reseted' | 'tested',
};

/**
 * @since 1.0.0
 * @description A function that accepts the status of an instance and returns an object
 * containing colors and a description of the status
 * @version 0.0.2
 * @see {@link https://northerndata.atlassian.net/wiki/spaces/IAAS/pages/2506784857/Instance+Status}
 * @package helpers
 * @param status - required
 * @returns object
 * @example
 * @todo if instance has been deleted, show the status deleted instead of delete
 */

export default function getVMsStatus(status: TActionsMenuComponent): TVMsStatus {
  if (status === 'SCHEDULED') {
    return {
      background: 'bg-[#8e8e8e]',
      name: 'Scheduled',
      status: 'SCHEDULED',
      lowercase: 'scheduled',
      action: 'test',
      icon: 'test',
      past: 'tested',
    };
  }
  if (status === 'NETWORK_READY') {
    return {
      background: 'bg-[#4daaec]',
      name: 'Network ready',
      status: 'NETWORK_READY',
      lowercase: 'network ready',
      action: 'test',
      icon: 'test',
      past: 'tested',
    };
  }
  if (status === 'REQUESTED') {
    return {
      background: 'bg-[#ecd54d]',
      name: 'Requested',
      status: 'REQUESTED',
      lowercase: 'requested',
      action: 'test',
      icon: 'test',
      past: 'tested',
    };
  }

  if (status === 'RESET_REQUESTED') {
    return {
      background: 'bg-[#ec704d]',
      name: 'Reset',
      status: 'RESET_REQUESTED',
      lowercase: 'reset',
      action: 'Reset',
      icon: 'reset',
      past: 'reseted',
    };
  }
  if (status === 'POWER_OFF') {
    return {
      background: 'bg-[#b49e17]',
      name: 'Power Off',
      status: 'POWER_OFF',
      lowercase: 'power off',
      action: 'Power off',
      icon: 'off',
      past: 'powered off',
    };
  }
  if (status === 'REBOOT_REQUESTED') {
    return {
      background: 'bg-[#276ef1]',
      name: 'Reboot',
      status: 'REBOOT_REQUESTED',
      lowercase: 'reboot',
      action: 'Reboot',
      icon: 'reboot',
      past: 'rebooted',
    };
  }

  if (status === 'DELETED') {
    return {
      background: 'bg-[#ff2222]',
      name: 'Delete',
      status: 'DELETED',
      lowercase: 'delete',
      action: 'Delete',
      icon: 'delete',
      past: 'deleted',
    };
  }
  if (status === 'ERROR') {
    return {
      background: 'bg-[#ff2222]',
      name: 'Error',
      status: 'ERROR',
      lowercase: 'error',
      action: 'test',
      icon: 'test',
      past: 'tested',
    };
  }
  if (status === 'CREATION_ERROR') {
    return {
      background: 'bg-[#ff2222]',
      name: 'Creation Error',
      status: 'CREATION_ERROR',
      lowercase: 'creation error',
      action: 'test',
      icon: 'test',
      past: 'tested',
    };
  }
  if (status === 'DELETION_REQUESTED') {
    return {
      background: 'bg-[#ff2222]',
      name: 'In Deletion',
      status: 'DELETION_REQUESTED',
      lowercase: 'in deletion',
      action: 'test',
      icon: 'test',
      past: 'tested',
    };
  }
  if (status === 'VM_ACTIVE') {
    return {
      background: 'bg-[#7EE896]',
      name: 'Active',
      status: 'VM_ACTIVE',
      lowercase: 'power on',
      action: 'Power on',
      icon: 'on',
      past: 'powered on',
    };
  }
  return {
    background: '',
    name: 'test',
    status: 'test',
    lowercase: 'test',
    action: 'text',
    icon: 'test',
    past: 'tested',
  };
};
