import React from 'react';
import { UserIcon } from '@heroicons/react/20/solid';

import classes from '../../../index.module.scss';
/**
 * @description UserIconComponent is shown in the main header
 * and popup if you click on the icon.
 *
 * @param isCursor required
 *
 * @returns div
 */

type TProps = {
  isCursor: boolean,
  dataTestId: string,
};

export default function UserIconComponent(props: TProps) {
  // props
  const { isCursor, dataTestId } = props;

  // style
  const {
    userIconClass,
  } = classes;

  return (
    <div
      data-testid={dataTestId}
      className={userIconClass}
    >
      <UserIcon
        className={`w-7 stroke-1 fill-[#e0e4eb] ${isCursor ? 'cursor-pointer' : ''}`}
      />
    </div>
  );
};
