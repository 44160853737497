import { useEffect } from 'react';

const useImportScript = (resourceUrl: string, type: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = false;
    script.type = type;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [resourceUrl, type]);
};
export default useImportScript;
