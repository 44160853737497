import { useState } from 'react';

import PortalModal from 'portals/Modal';

import Spinner from './Spinner';

/**
 * @description SpinnerLazyLoading
 * @returns
 */
export function SpinnerLazyLoading () {
  // state
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(true);

  // funcs
  const handleCloseModalWindow = () => {
    setOpenPortalModalWindow(false);
  };

  return(
    <PortalModal
      open={openPortalModalWindow}
      onCancel={handleCloseModalWindow}
      dataTestId='test_id_lazy_loading'
    >
      <Spinner />
    </PortalModal>
  );
};