import React, { ReactNode } from 'react';

import classes from './summary.module.scss';

type TProps = {
  children: ReactNode,
  title: string,
  testDataId: string,
};

export default function SectionWithTags(props: TProps) {
  const { children, title, testDataId } = props;
  const { titleClass } = classes;

  return (
    <div className='col pl-6 py-[10px] font-medium' data-testid={testDataId}>
      <dt className={titleClass}>{title}</dt>
      <dd className='text-customTextBlack2withOpacity1 text-sm row flex-wrap'>
        {children}
      </dd>
    </div>
  );
};
