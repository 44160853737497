import React from 'react';

import { useInstance } from 'context/instance';
import { TImage } from 'types';
import ubuntuImage from 'assets/images/ubuntu-logo.png';
import debianImage from 'assets/images/debian.png';
import rockyImage from 'assets/images/rocky.png';
import mayaImage from 'assets/images/maya-logo.png';
import vmsImage from 'assets/icons/navbar/vms.svg';

import Dropdown from './Dropdown';
import classes from './image.module.scss';

type TProps = {
  imageGroup: TImage,
  selectedImageGroupId: number,
  handleChooseImageGroupId: (id: number) => void,
};

const Item = (props: TProps) => {
  // props
  const {
    imageGroup,
    selectedImageGroupId,
    handleChooseImageGroupId,
  } = props;

  // variables
  const { itemClass } = classes;
  const context = useInstance();
  const state = context[0];
  const { instance: { image: versionOfImage } } = state;
  const activeStyle = versionOfImage.uuid !== '' && imageGroup.name === versionOfImage?.image;

  // func
  const handleShowLogo = (name: string) => {
    if (name === 'ubuntu') return ubuntuImage;
    if (name === 'debian') return debianImage;
    if (name === 'rocky') return rockyImage;
    if (name === 'maya') return mayaImage;
    return vmsImage;
  };

  return (
    <div
      className={[
        itemClass,
        activeStyle ?
          'border-customColorLightGreen bg-green-50' :
          'bg-white border-customColorLightGray',
      ].join(' ')}
      data-testid={imageGroup.name}
    >
      <div className='col all-items-center mt-[15px] mb-[11px]'>
        <img src={handleShowLogo(imageGroup.name)} alt={imageGroup.name} className='w-6 mb-[10px]' />
        <p className='font-medium text-sm text-custom-customColorDarkBlue'>
          {imageGroup.name}
        </p>
      </div>
      <Dropdown
        imagesVersions={imageGroup.versions}
        image={imageGroup}
        selectedImageGroupId={selectedImageGroupId}
        handleChooseImageGroupId={handleChooseImageGroupId}
        imageGroupId={imageGroup.id}
      />
    </div>
  );
};

export default Item;
