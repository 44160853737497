import Item from './Item';

import useGetItemsDetailPage from '../../../hooks/useGetItemsDetailPage';

export default function Items() {
  const { ITEMS } = useGetItemsDetailPage();

  return (
    <div className='grid grid-cols-2 gap-y-10 gap-x-28'>
      {ITEMS.map((item: {title: string, value: string}) =>
        <Item
          key={item.title}
          item={item}
        />,
      )}
    </div>
  );
};
