import { ChangeEvent } from 'react';
import { useDashboard } from 'context/dashboard';

import { setFetchFunc, setNotifi } from 'actions/dashboard';
import { IIpv4, INetwork, ISshKey } from 'types';
import { IVm } from 'types';

import useGetNameAndUuidSelectedItems from './useGetNameAndUuidSelectedItems';

type TPage = 'ips' | 'networks' | 'sshKeys' | 'virtualmachine' | 'baremetal' | 'instances';

export default function useSelectedItems (
  data: IVm[] | IIpv4[] | INetwork[] | ISshKey[] = [],
  page: TPage = 'networks',
) {
  const context = useDashboard();
  const dispatch = context[1];

  const cleanDeletedArrays = () => {
    dispatch(setNotifi('errors', []));
    dispatch(setNotifi('success', []));
    dispatch(setFetchFunc([], page, 'delete', 'data'));
  };

  const setNameAndUuidItems = useGetNameAndUuidSelectedItems(page);

  const handleSelectedItem = (event: ChangeEvent<HTMLInputElement>, uuid: string) => {
    cleanDeletedArrays();
    const {checked, value} = event.target;
    setNameAndUuidItems(value, uuid);

    const newList = data.map((item: IIpv4 | INetwork | ISshKey | IVm) => {
      if (item.uuid === uuid) {
        const updatedItem = {
          ...item,
          isSelected: checked ? true : false,
        };

        return updatedItem;
      }

      return item;
    });
    dispatch(setFetchFunc(newList, page, 'get', 'data'));

    const deletingList = newList.filter(item => item.isSelected === true);
    dispatch(setFetchFunc(deletingList, page, 'delete', 'data'));
  };

  return handleSelectedItem;
};
