import React from 'react';

import RoutesComponent from './RoutesComponent';
import LinksComponent from './LinksComponent';
import classes from './Navbar.module.scss';

export default function Navbar() {

  const {
    navbarClass,
  } = classes;

  return (
    <div className={navbarClass}>
      <div className='col'>
        <RoutesComponent />
        <LinksComponent />
      </div>
    </div>
  );
};
