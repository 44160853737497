import React from 'react';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';

import { useInstance } from 'context/instance';
import { IIpv4 } from 'types';

import useRemoveIp from './useRemoveIp';

import classes from '../instance.module.scss';

import glossary from '../../../data/glossary.json';

export default function SelectedIps({dataTestIds}: {dataTestIds: {}}) {
  const { ipItemClass } = classes;

  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const {
    instance: {
      ips: ipAddresses,
    },
  } = stateInstance;
  const handleRemoveIp = useRemoveIp();

  const { sectionWrapperClass } = classes;

  return (
    <div data-testid={dataTestIds}>
      {ipAddresses?.map((ip: IIpv4)  =>
        <div
          key={ip.address4}
          className={ipItemClass}
        >
          {ip.address4}
          <ArchiveBoxXMarkIcon
            onClick={() => handleRemoveIp(ip.address4)}
            className='w-4 fill-customColorDarkBlue cursor-pointer'
          />
        </div>,
      )}
      {ipAddresses.length === 0 &&
        <div className={`${sectionWrapperClass} justify-start pl-6 bg-customBgLightGray2`}>
          <p className='text-customGray3 mr-6 text-sm font-medium'>{glossary.ipv4}</p>
          <p className='text-sm font-medium'> No {glossary.ipv4s_plural} added </p>
        </div>
      }
      <p className='text-xxs font-normal ml-4 mt-[5px]'>
        Added {ipAddresses.length} {ipAddresses.length === 1 ? glossary.ipv4 : glossary.ipv4s_plural}
      </p>
    </div>
  );
};
