import React from 'react';

import { useDashboard } from 'context/dashboard';
import { ISshKey } from 'types';
import useSelectedItems from 'feature/bulk-operations/delete/useSelectedItems';
import useSelectedOrDeselectedAllRemovingItems from 'hooks/useSelectedOrDeselectedAllRemovingItems';
import { TEST_ID_SSH_KEYS_PAGE_SELECT_ALL_SSH_KEYS_BTN } from 'data-test-ids/data-testid-variables';

import SSHKeysTableComponent from './SSHKeysTableComponent';

type TProps = {
  isShowCheckbox: boolean,
};

export default function SSHKeysListDetails(props: TProps) {
  // props
  const {
    isShowCheckbox,
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.sshKeys.get;
  const dataWithCustomValues: ISshKey[] = data && data.map(
    (item: ISshKey) => (
      {
        ...item,
        isSelected: item.isSelected || false,
        loading: false,
        status: 0,
        message: '',
        error: '',
        isDeleted: false,
        timeoutShow: 100,
        timeoutClose: 500,
        id: item.uuid,
      }
    ),
  );

  const handleSelectItems = useSelectedItems(
    dataWithCustomValues,
    'sshKeys',
  );

  const isAllSelected = dataWithCustomValues.every((key: ISshKey) => key.isSelected);
  const handleSelectedAllItems = useSelectedOrDeselectedAllRemovingItems('sshKeys', true);
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItems('sshKeys', false);

  return (
    <SSHKeysTableComponent
      handleDeselectItems={handleDeselectedAllItems}
      handleSelectedAllItems={handleSelectedAllItems}
      isAllSelected={isAllSelected}
      data={dataWithCustomValues}
      handleSelectItems={handleSelectItems}
      isShowCheckbox={isShowCheckbox}
      dataTestId={TEST_ID_SSH_KEYS_PAGE_SELECT_ALL_SSH_KEYS_BTN} //'select_all'
    />
  );
};
