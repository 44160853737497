import { INotification } from 'types';

import classess from './notification.module.scss';
import NotificationComponent from './NotificationComponent';

type TProps = {
  notifications?: INotification[],
};

export default function Notifications({notifications}: TProps) {

  const {
    notificationWrapperClass,
  } = classess;

  return (
    <>
      {notifications?.map((item: INotification, index) =>
        <div
          aria-live='assertive'
          className={notificationWrapperClass}
          key={index}
        >
          <NotificationComponent
            text={item.type}
            description={item.message}
            timeoutShow={item.timeoutShow}
            timeoutClose={item.timeoutClose}
          />
        </div>,
      )}
    </>
  );
};
