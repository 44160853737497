export const INITIAL_STATE = {
  openEditModal: false,
};

export const OPEN_RENAME_MODAL = 'NETWORK.OPEN_RENAME_MODAL';

export type TState = {
  openEditModal: boolean,
};

export type TOpenModalAction = {
  openEditModal: boolean,
  type: 'NETWORK.OPEN_RENAME_MODAL',
};

export type TReducer = {
  openEditModal: boolean,
};

/**
 * @since 1.0.0
 * @description network reducer
 * @version 0.0.2
 * @package reducers
 * @returns object | error
 * @example
    const [state, dispatch] = useReducer(networkReducer, INITIAL_STATE);
*/

export function networkReducer(state: TState, action: TOpenModalAction)  {
  switch (action.type) {
  case OPEN_RENAME_MODAL: {
    return {
      ...state,
      openEditModal: action.openEditModal,
    };
  }
  default: {
    throw Error('Unknown action: ' + action);
  }}
};
