import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useDashboard } from 'context/dashboard';
import { useCleanState } from 'hooks/useCleanState';
import { dataTestIdsKeys } from 'data-test-ids';
import ModalWrapper from 'components/forms/create-ssh-key';
import PortalNotification from 'portals/Notification';
// import { setNewSshKey } from 'actions/dashboard';

import glossary from '../data/glossary.json';

export default function SshKeyNew() {
  // state
  const [openForm, setOpenForm] = useState<boolean>(true);
  // const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // variables
  const context = useDashboard();
  const state = context[0];
  // const dispatch = context[1];
  const { fetchFunc } = state;
  const { post } = fetchFunc.sshKeys;
  const cleanState = useCleanState();
  const page = 'sshKeys';
  const history = useHistory();

  // func
  useEffect(() => {
    document.title = `${glossary.name} | New ${glossary.ssh_key}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('sshKey', 'post');
      cleanState(page, 'post');
    };
  }, [cleanState]);

  // const handleOpenModalWindow = () => {
  //   cleanState('sshKey', 'post');
  //   setOpenForm(true);
  //   dispatch(
  //     setNewSshKey({
  //       tenant_uuid: '',
  //       name: '',
  //       key: '',
  //     }),
  //   );
  // };

  const handleCloseModalForm = () => {
    // dispatch(
    //   setNewSshKey({
    //     tenant_uuid: '',
    //     name: '',
    //     key: '',
    //   }),
    // );
    // onCancel();
    setOpenForm(false);
    return history.push('/');
  };

  return (
    <>
      {openForm &&
        <ModalWrapper
          onCancel={handleCloseModalForm}
          open={openForm}
          dataTestIds={dataTestIdsKeys.modalForm}
        />
      }
      {post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
