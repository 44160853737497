import React, { useState } from 'react';

import Typography from 'components/typography';
import VmOverviewModal from 'feature/network/components/VmOverviewModal';

type TProps = {
  dataTestIds?: {},
  vmId: string,
};

/**
 * @description The Items component .
 * @param props vmId, dataTestIds
 * @param vmId required
 *
 * @returns div
*/

export default function Item(props: TProps) {
  // state
  const [openModalVMWindow, setOpenModalVMWindow] = useState<boolean>(false);

  // props
  const { dataTestIds, vmId } = props;

  // func
  const handleOpenModal = (vmId: string) => {
    setOpenModalVMWindow(true);
  };

  return (
    <div
      onClick={() => handleOpenModal(vmId)}
      key={vmId}
      className='pl-6 py-4 underline cursor-pointer'
      data-testid={dataTestIds}
    >
      <Typography
        text={vmId}
        fontWeight='thin'
        fontSize='s'
        color='customColorDarkBlue80'
        // dataTestId={dataTestIds}
      />
      {openModalVMWindow &&
        <VmOverviewModal
          open={openModalVMWindow}
          vmUUID={vmId}
          handleOpenModal={setOpenModalVMWindow}
          dataTestIds={dataTestIds}
        />
      }
    </div>
  );
};
