import React from 'react';
import Typography from 'components/typography';

import classes from './index.module.scss';

type TProps = {
  /**
   * @default 'tooltip ui component'
  */
  text?: string,
  /**
   * @default 'test_id_tooltip'
  */
  dataTestId?: string,
  x: number,
  y: number,
  contentRef: any,
};

/**
 * @description The TooltipContainer UI component
 * @param props text, dataTestId
 * @returns ReactNode - Info compoent | Content component
*/

export default function TooltipContainer(props: TProps) {
  // props
  const {
    text='tooltip ui component',
    dataTestId='test_id_tooltip',
    x,
    y,
    contentRef,
  } = props;

  // style
  const { tipClass } = classes;

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        transform: `translate3d(${x-35}px, ${y-5}px, 0)`,
        zIndex: 100,
      }}
    >
      <div
        ref={contentRef}
        data-testid={dataTestId}
        className='text-white'
      >
        <Typography
          text={text}
          customStyle={`${tipClass} capitalize`}
          fontSize='xs'
          fontWeight='medium'
          color='white'
        />
      </div>
    </div>
  );
}
