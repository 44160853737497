import React from 'react';

import { useDashboard } from 'context/dashboard';
import { TDataTestIdsOverviewPageContentMainInfoAsideChildrenItems } from 'data-test-ids/types/overview-page';

import Item from './Item';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainInfoAsideChildrenItems,
};

/**
 * @description The Items component .
 * @param props text, fontWeight, color, textTag, dataTestId, fontSize, customStyle, truncate, width
 * @param text required
 *
 * @returns div
*/

export default function Items(props: TProps) {
  // props
  const { dataTestIds } = props;

  // hooks
  const contextDashboard = useDashboard();

  // variables
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.ip.get;

  return (
    <div className='divide-y divide-customColorLightGray'>
      {data?.vm_uuid &&
        <Item
          key={data.vm_uuid}
          dataTestIds={dataTestIds}
          vmId={data.vm_uuid}
        />
      }
    </div>
  );
};
