import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';

type TProps = {
  customData: any,
  customWidth: number,
  customHeight: number,
  totalNumber: number,
  customSize: number,
  customFontSize: number,
  dy: number,
};

/**
 * @description Chart Pie
 * @returns
 */

export default function ChartPie(props: TProps) {
  // props
  const {
    customData = [],
    customWidth = 57,
    customHeight = 57,
    totalNumber = 0,
    customSize = 14,
    customFontSize = 14,
    dy=5,
  } = props;

  // variables
  const half = customWidth / 2;
  const tooltipStyles = {
    ...defaultStyles,
    borderRadius: 5,
    background: 'white',
    color: '#02061D99',
    fontSize: 9,
  };
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  // func
  const handleMouseOver = (event: any, value: string) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: value,
    });
  };

  const handleGetPercentage = (value: number) => {
    const number = Math.round((value*100)/totalNumber);
    return number;
  };

  return (
    <>
      <svg
        ref={containerRef}
        width={customWidth}
        height={customHeight}
        className='customBoxShadowChartClass'
      >
        <Group
          top={half}
          left={half}
        >
          <Pie
            data={customData}
            pieValue={(data: {value: number, color: string, name: string}) => data?.value}
            outerRadius={half}
            innerRadius={half - customSize}
            padAngle={0}
          >
            {(pie) => {
              return pie.arcs.map((arc: any) => {
                return (
                  <g
                    key={arc.data.name}
                  >
                    <path
                      d={pie.path(arc) || ''}
                      fill={arc.data.color}
                      onMouseOver={
                        (event: any) => handleMouseOver(
                          event,
                          `${arc.data.name} ${handleGetPercentage(arc.data.value)}`,
                        )}
                      onMouseOut={hideTooltip}
                    >
                    </path>
                  </g>
                );
              });
            }}
          </Pie>
          <Text
            textAnchor='middle'
            fill='#000'
            fontSize={customFontSize}
            dy={dy}
          >
            {totalNumber}
          </Text>
        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <>{tooltipData}%</>
        </TooltipInPortal>
      )}
    </>
  );
};
