import React from 'react';

import IconSpinner from 'assets/icons/IconSpinner';

type TSpinner = {
  /**
   * @description creating additional space around the spinner icon
   * @default false;
  */
  spaceAroundSpinner?: boolean
};

/**
 * @description The Spinner component
 * @param props spaceAroundSpinner
 *
 * @returns div, icon spinner
*/

export default function Spinner(props: TSpinner ) {
  // props
  const { spaceAroundSpinner = false } = props;

  // variables
  const height = spaceAroundSpinner ? 'h-[10vh]' : 'h-[50vh]';

  return (
    <div
      data-testid='spinner'
      id='spinner'
      className={`${height} grid place-items-center center `}
    >
      <IconSpinner customStyle='mr-2 w-8 h-8' />
    </div>
  );
};
