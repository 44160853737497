import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setPreselectedRemovingItems } from 'actions/dashboard';
import { IIpv4 } from 'types';

export type TIp = 'ipv6' | 'ipv4';

export default function useSelectedOrDeselectedAllRemovingIps(isSelected: boolean) {

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc } = state;
  const { data } = fetchFunc.ips.get;

  const ipsWithoutVms = data.filter((ip: IIpv4) => !ip.vm_uuid);

  const createNewList = (ip: IIpv4) => {
    if (!ip.vm_uuid) {
      const item = {
        ...ip,
        isSelected: isSelected,
      };
      return item;
    }
    return ip;
  };

  const handlePreselectedItems = () => {
    const newArray = [];

    for(let i = 0; i < ipsWithoutVms.length; i++ ) {
      newArray.push(ipsWithoutVms[i].uuid);
    }

    dispatch(setPreselectedRemovingItems('ips', 'uuid', newArray));
  };

  const handleSelectedAllItems = () => {
    const newList = data.map((ip: IIpv4) => createNewList(ip));
    dispatch(setFetchFunc(newList, 'ips', 'get', 'data'));

    if (isSelected) {
      handlePreselectedItems();
    } else {
      dispatch(setPreselectedRemovingItems('ips', 'uuid', []));
      dispatch(setPreselectedRemovingItems('ips', 'name', []));
    }
  };

  return handleSelectedAllItems;
};
