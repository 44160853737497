import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setPreselectedRemovingItems } from 'actions/dashboard';
import { IVm } from 'types';
import { TActionsDependsOfStatus, TErrorNotifications, TFetchFuncRequestMethod, TSuccessNotifications } from 'types';

import useCreatePromisesForUpdateStatusVms from './useCreatePromisesForUpdateStatusVms';

export default function useUpdateStatusVmsItems(
  setSuccessNotifications: (value: TSuccessNotifications[]) => void,
  setErrorNotifications: (value: TErrorNotifications[]) => void,
  page: 'instances' | 'virtualmachine' | 'baremetal',
  fetchMethod?: TFetchFuncRequestMethod,
  status?: TActionsDependsOfStatus,
) {

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc, preselectedRemovingItems } = state;
  const preselectedRemovingItemsUuid = preselectedRemovingItems[page].uuid;

  const { createPromises, preselectedRemovingItemsPromise } = useCreatePromisesForUpdateStatusVms(page, preselectedRemovingItemsUuid, fetchMethod, status);

  const successArray: TSuccessNotifications[] = [];
  const errorArray: TErrorNotifications[] = [];

  const updateItems = async() => {
    createPromises();

    try {
      dispatch(setFetchFunc(true, page, 'post', 'loading'));
      const r = await Promise.allSettled(preselectedRemovingItemsPromise);

      r.map((item) => {
        if (item.status === 'fulfilled') {
          successArray.push(item.value);
        } else {
          errorArray.push(item.reason);
        }
        return null;
      });
    } catch (error) {
      // console.error('Error:', error);
    }
    dispatch(setPreselectedRemovingItems(page, 'uuid', []));
    dispatch(setPreselectedRemovingItems(page, 'name', []));
    dispatch(setFetchFunc(false, page, 'post', 'loading'));
    setSuccessNotifications(successArray);
    setErrorNotifications(errorArray);

    dispatch(setFetchFunc(successArray, page, 'post', 'message'));
    dispatch(setFetchFunc(errorArray, page, 'post', 'error'));

    const successArrayUuid = successArray.map((object) => object.uuid);

    const getVMList = () => {
      const newList = fetchFunc[page].get.data.map((item: IVm) => {
        if (successArrayUuid.includes(item.uuid)) {
          const updatedItem = {
            ...item,
            isSelected: false,
            status: status,
          };
          return updatedItem;
        } else {
          const resetSelectedItems = {
            ...item,
            isSelected: false,
          };
          return resetSelectedItems;
        }
      });
      return newList;
    };

    dispatch(setFetchFunc(getVMList(), page, 'get', 'data'));
  };

  return updateItems;
};
