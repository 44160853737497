import React from 'react';

import InfoSectionNetwork from 'feature/network/components/InfoSection';
import { TDataTestIdsOverviewPageContentMain } from 'data-test-ids/types/overview-page';
import Header from 'feature/network/components/header';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMain,
};

/**
  * @description The MainSection only for Network page
  *
  * @param props dataTestIds
  * @param dataTestIds required
  * @returns div
*/

export default function MainSection(props: TProps) {
  // props
  const {dataTestIds} = props;

  return (
    <div
      id='main-wrapper'
      data-testid={dataTestIds.name}
    >
      <div
        data-testid={dataTestIds.name}
        className={'lightGrayWrapperClass' }
      >
        <Header
          dataTestIds={dataTestIds.header.networkPage}
        />
      </div>
      <InfoSectionNetwork
        dataTestIds={dataTestIds.info}
      />
    </div>
  );
};
