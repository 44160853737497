import React, { forwardRef, useState } from 'react';

import { useInstance } from 'context/instance';
import { useDashboard } from 'context/dashboard';
import Button from 'components/buttons';
import { useCleanState } from 'hooks/useCleanState';
import { setNewNetwork } from 'actions/dashboard';
import ModalWrapper from 'components/forms/create-network';
import { TDataTestIdsNewVmMainNetworkNewNetwork } from 'data-test-ids/types/new-vm';

import glossary from '../../../data/glossary.json';

const CreateNewNetworkSection = forwardRef<HTMLSelectElement, {handleWarning: (value: string) => void, dataTestIds: TDataTestIdsNewVmMainNetworkNewNetwork}>((props, ref) => {
  const [openForm, setOpenForm] = useState<boolean>(false);

  const { dataTestIds } = props;

  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const {
    instance: {
      flavor,
    },
  } = stateInstance;

  const contextDashboard = useDashboard();
  const dispatchDashboard = contextDashboard[1];
  const cleanState = useCleanState();

  const handleAddNewNetwork = () => {
    cleanState('network', 'post');
    dispatchDashboard(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
    if (flavor.uuid === '') {
      return props.handleWarning(glossary.notifications.errors.please_choose_flavor);
    }
    return setOpenForm(true);
  };

  return (
    <div className='relative'>
      <Button
        type='button'
        handleClick={handleAddNewNetwork}
        uiStyle='secondary'
        title={`${glossary.actions.create} ${glossary.network}`}
        dataTestId={dataTestIds.button}
        customStyle='absolute right-0 top-[-10px]'
        size='small'
      />
      {openForm &&
        <ModalWrapper
          onCancel={() => setOpenForm(false)}
          open={openForm}
          page='New virtual machine'
          dataTestIds={dataTestIds.modalForm}
        />
      }
    </div>
  );
});

export default CreateNewNetworkSection;
