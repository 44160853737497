// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useRef, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import TooltipContainer from 'components/tooltip';

type TProps = {
  /**
   * @default 'tooltip ui component'
  */
  text?: string,
  /**
   * @default 'test_id_tooltip'
  */
  dataTestId?: string,
  targetRect: any,
};

/**
 * @description The PortalTooltip component
 * @returns TooltipContainer
*/

export function PortalTooltip(props: TProps) {
  // state
  const [tooltipHeight, setTooltipHeight] = useState(0);

  // props
  const {
    text='default text',
    targetRect,
  } = props;

  // hooks
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      setTooltipHeight(height);
    }
  }, []);

  // variables
  let tooltipX = 0;
  let tooltipY = 0;

  if (targetRect !== null) {
    tooltipX = targetRect.left;
    tooltipY = targetRect.top - tooltipHeight;
    if (tooltipY < 0) {
      // It doesn't fit above, so place below.
      tooltipY = targetRect.bottom;
    }
  }

  return createPortal(
    <TooltipContainer
      x={tooltipX}
      y={tooltipY}
      contentRef={ref}
      text={text}
    />,
    document.body,
  );
};
