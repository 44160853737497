import { useDashboard } from 'context/dashboard';
import { IIpv4 } from 'types/index';

import { useMakeRequestGetIpv4sImmediately } from './useGetIps';

type TFilterElement = {
  amount: {
    all: number,
    attached: number,
    notAttached: number,
  },
  attached: IIpv4[],
  notAttached: IIpv4[],
};

type TFilterInstances = {
  byClusters: {
    norway: TFilterElement,
    sweden: TFilterElement,
  },
  all: TFilterElement & { ips: IIpv4[]},
};

/**
 * @description useFilterIps - a hook makes API /GET reqeusts to receive all ip v4 addresses,
 * and then sorts them by type of cluster, attached/not attached and returns an object
 * containing the number of elements according to the type, an object with elements and the total number
 * @returns byClusters, all {@link TFilterInstances}
 */

export const useFilterIps = ():TFilterInstances => {
  // hooks
  useMakeRequestGetIpv4sImmediately();

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data } = fetchFunc.ips.get;
  const ipsWithSelectedProp = data.map((item: IIpv4) => ({...item, selected: false}));
  const ipsNorway = ipsWithSelectedProp.filter(
    (ip: IIpv4) => ip.cluster_uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5');
  const ipsSweden = ipsWithSelectedProp.filter(
    (ip: IIpv4) => ip.cluster_uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7');

  const attached = ipsWithSelectedProp.filter((item: IIpv4) => item.vm_uuid);
  const attachedNorway = attached.filter(
    (ip: IIpv4) => ip.cluster_uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5');
  const attachedSweden = attached.filter(
    (ip: IIpv4) => ip.cluster_uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7');

  const noAttached = ipsWithSelectedProp.filter((item: IIpv4) => item.vm_uuid === null);
  const noAttachedNorway = noAttached.filter(
    (ip: IIpv4) => ip.cluster_uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5');
  const noAttachedSweden = noAttached.filter(
    (ip: IIpv4) => ip.cluster_uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7');

  return {
    byClusters: {
      norway: {
        amount: {
          all: ipsNorway.length,
          attached: attachedNorway.length,
          notAttached: noAttachedNorway.length,
        },
        attached: attachedNorway,
        notAttached: noAttachedNorway,
      },
      sweden: {
        amount: {
          all: ipsSweden.length,
          attached: attachedSweden.length,
          notAttached: noAttachedSweden.length,
        },
        attached: attachedSweden,
        notAttached: noAttachedSweden,
      },
    },
    all: {
      amount: {
        all: ipsWithSelectedProp.length,
        attached: attached.length,
        notAttached: noAttached.length,
      },
      ips: ipsWithSelectedProp,
      attached: attached,
      notAttached: noAttached,
    },
  };
};

