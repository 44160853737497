// @ts-nocheck
export async function cacheable<T>(
  fn: () => Promise<T>,
  defaultValue: any,
  cacheName: 'cache-i' | 'cache-f' | 'cache-c',
  key?: string,
) {
  let result;
  try {
    // retrieve the data from backend.
    result = await fn();
    // save the data to localStorage.
    let cache = getCache(cacheName);
    // cache = result; // cache[key] = result;
    if (cacheName === 'cache-f') {
      cache = {
        count: 0,
        use_fe: [{
          id: 0,
          name: 'RTXA6000',
          items: result.filter((item) => item.gpu_type === 'A6000').map((item) =>  ({
            ...item,
            'cluster': {
              'uuid': '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
              'name': 'ndcloud-underground',
            },
          })),
        }],
        display_fe: result,
      };
    } else if (cacheName === 'cache-i') {
      const allUbuntu = {
        'id': 0,
        'name': 'ubuntu',
        'versions': result.filter((img) => img.name.toLowerCase().includes('ubuntu')).map((img) =>  ({
          ...img,
          image_group_id: 0,
          image: 'ubuntu',
        })),
      };
      const allDebian = {
        'id': 2,
        'name': 'debian',
        'versions': result.filter((img) => img.name.toLowerCase().includes('debian')).map((img) =>  ({
          ...img,
          image_group_id: 2,
          image: 'debian',
        })),
      };
      const allRocky = {
        'id': 3,
        'name': 'rocky',
        'versions': result.filter((img) => img.name.toLowerCase().includes('rocky')).map((img) =>  ({
          ...img,
          image_group_id: 3,
          image: 'rocky',
        })),
      };
      const newImages = {
        count: result.length,
        use_fe: [
          allUbuntu,
          allDebian,
          allRocky,
        ],
        display_fe: result,
      };
      cache = newImages;
    } else {
      cache = result;
    }
    localStorage.setItem(cacheName, JSON.stringify(cache));
  } catch {
    // if failed to retrieve the data from backend, try localStorage.
    const cache = getCache(cacheName);
    const cached = cache; // const cached = cache[key];
    // use the cached data if available, otherwise the default value.
    result = cached === undefined ? defaultValue : cached;
  }

  return result;
};

export function getCache(cacheName: 'cache-i' | 'cache-f' | 'cache-c'): any {
  const cache = localStorage.getItem(cacheName) || '{}';
  const cachedResult = cache !== '{}' ? JSON.parse(cache || '{}') : [];
  return cachedResult;
};

export function clearCache(cacheName: 'cache-i' | 'cache-f' | 'cache-c') {
  localStorage.removeItem(cacheName);
};
