import { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import { TStatusVm } from 'types';

const returnCorrectActions = (actions: TActionsMenuComponent[], page: 'vm' | 'virtualmachine'): TActionsMenuComponent[] => {
  return page === 'virtualmachine' ? [...actions, 'view'] : actions;
};

export const getCorrectListOfActions = (status: TStatusVm, page: 'vm' | 'virtualmachine'): TActionsMenuComponent[] => {
  if (status === 'DELETED' || status === 'DELETION_REQUESTED') {
    return ['view'];
  };

  if (status === 'RESET_REQUESTED' || status === 'REBOOT_REQUESTED' || status === 'REQUESTED') {
    const actions: TActionsMenuComponent[] = ['DELETED'];
    return returnCorrectActions(actions, page);
  };

  const checkIsStatusPowerOff = (isStatusPowerOff: 'POWER_OFF' | 'VM_ACTIVE' ): TActionsMenuComponent[] => page === 'virtualmachine' ?
    ['RESET_REQUESTED', 'REBOOT_REQUESTED', isStatusPowerOff, 'DELETED'] :
    [isStatusPowerOff, 'DELETED'];

  if (status === 'POWER_OFF') {
    const actions: TActionsMenuComponent[] = checkIsStatusPowerOff('VM_ACTIVE');
    return returnCorrectActions(actions, page);
  };

  const actions: TActionsMenuComponent[] = checkIsStatusPowerOff('POWER_OFF');
  return returnCorrectActions(actions, page);
};
