import React from 'react';

import { useDashboard } from 'context/dashboard';
import { TDataTestIdsPortalModalFormContent } from 'data-test-ids/types/general-pages/portalModal';

import Inputs from './Inputs';

import Spinner from '../../spinners/Spinner';
import Info from '../../Info';

type TProps = {
  page: 'Networks' | 'New virtual machine',
  dataTestId?: string[] | '',
  dataTestIds: TDataTestIdsPortalModalFormContent,
  nameError: string,
  clusterError: string,
  handleNameError: (value: string) => void,
  handleClusterError: (value: string) => void,
};

export default function Content(props: TProps) {
  const {
    nameError,
    clusterError,
    handleNameError,
    handleClusterError,
    dataTestIds,
    page,
  } = props;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { error, loading } = fetchFunc.network.post;

  if (error) {
    return <Info status='error' msg={error} />;
  };

  if (loading) {
    return <Spinner spaceAroundSpinner />;
  };

  return (
    <div
      data-testid={dataTestIds.wrapper}
      className='pt-8 px-6'
    >
      <Inputs
        page={page}
        nameError={nameError}
        clusterError={clusterError}
        handleNameError={handleNameError}
        handleClusterError={handleClusterError}
        dataTestIds={dataTestIds}
      />
    </div>
  );
};
