import React, { forwardRef, useEffect } from 'react';

import Info from 'components/Info';
import { useDashboard } from 'context/dashboard';
import { useInstance } from 'context/instance';
import { setInstance, setInstanceValidation } from 'actions/instance';
import { TDataTestIdsNewVmMainAuthentication } from 'data-test-ids/types/new-vm';
import { useCleanState } from 'hooks/useCleanState';
import { useTenants } from 'context/tenants';
import { useGetSshKeys } from 'hooks/useGetSshKeys';

import AddKeysSection from './AddKeysSection';
import Content from './Content';

import TitleSection from '../TitleSection';

import glossary from '../../../data/glossary.json';

const Authentication = forwardRef<HTMLDetailsElement, { dataTestIds: TDataTestIdsNewVmMainAuthentication }>((props, ref) => {
  // props
  const { dataTestIds } = props;

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { error } = fetchFunc.sshKeys.get;
  const contextInstance = useInstance();
  const dispatchInstance = contextInstance[1];
  const stateInstance = contextInstance[0];
  const { instance } = stateInstance;
  const cleanState = useCleanState();

  // hooks
  useGetSshKeys();

  useEffect(() => {
    return () => {
      cleanState('sshKey', 'post');
    };
  }, [cleanState]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatchInstance(setInstance([], 'sshKeys'));
      dispatchInstance(setInstanceValidation('', 'sshKey'));
    }
  }, [selectedTenantId, dispatchInstance]);

  return (
    <div
      className='col-span-6 mt-5'
      data-testid={dataTestIds.wrapper.name}
    >
      <TitleSection
        title={glossary.authentication}
        dataTestId={dataTestIds.wrapper.title}
      />
      <Content />
      {instance.sshKeys.length < 6 ?
        <p
          data-testid={dataTestIds.wrapper.note}
          className='flex items-start text-black text-xxxs font-normal mt-5px ml-4'
        >
          You can only choose 6 {glossary.ssh_key}
        </p>
        :
        <p
          data-testid={dataTestIds.wrapper.note}
          className='flex items-start text-customDarkRed text-xxxs font-normal mt-5px ml-4'
        >
          You have already selected the maximum number of {glossary.ssh_keys_plural}
        </p>
      }
      <AddKeysSection
        dataTestIds={dataTestIds.wrapper.addKey}
      />
      {error &&
        <Info
          status='error'
          msg={error}
          dataTestId={dataTestIds.wrapper.error}
        />
      }
    </div>
  );
});

export default Authentication;
