import Button from 'components/buttons';
import Typography from 'components/typography';

import classes from '../overview.module.scss';
import glossary from '../../../data/glossary.json';

/**
 * @description Docs is a section only for overview page
 * @todo ask about bg
 * @returns
 */

export default function DocsSection() {
  // style
  const { docsSectionClass } = classes;

  return (
    <div className={`row ${docsSectionClass}`}>
      <Typography
        text={glossary.descriptions.docs}
        color='white'
        fontSize='xs'
      />
      <Button
        title=''
        uiStyle='primary'
        handleClick={() => {}}
        size='small'
      >
        <a
          rel='noopener noreferrer'
          target='_blank'
          href='https://documenation.scrollhelp.site/docs'
        >
          Here
        </a>
      </Button>
    </div>
  );
};
