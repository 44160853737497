import React, { ChangeEvent, forwardRef, useEffect, useRef } from 'react';

import classes from './forms.module.scss';

type TProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
  name: string,
  type: string,
  value: string,
  classNames?: string,
  isSelected: boolean,
  customInputClass?: string,
  disabled: boolean,
  checkboxState?: 'Checked' | 'Indeterminate' | 'Empty',
  dataTestId?: string,
};

const CustomCheckbox = forwardRef<HTMLInputElement, TProps>((props, ref) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const {
    checkboxUncheckClass,
    checkboxCheckClass,
  } = classes;

  const {
    handleChange,
    name,
    value,
    classNames,
    isSelected,
    customInputClass,
    disabled = false,
    checkboxState = 'Empty',
    dataTestId,
  } = props;

  useEffect(() => {
    if (checkboxRef.current != null) {
      if (checkboxState === 'Checked') {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (checkboxState === 'Empty') {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (checkboxState === 'Indeterminate') {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }
  }, [checkboxState]);

  const inputView = () => {
    if (checkboxState === 'Checked') return checkboxCheckClass;
    return `${checkboxUncheckClass} ${classNames}`;
  };

  return (
    <input
      ref={checkboxRef}
      id={name}
      type='checkbox'
      name={name}
      onChange={handleChange}
      value={value ?? ''}
      aria-label='isSelected'
      checked={isSelected || false}
      className={`peer w-full ${inputView()} ${customInputClass} `}
      disabled={disabled}
      data-testid={dataTestId}
    />
  );
});

export default CustomCheckbox;
