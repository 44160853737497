import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useDashboard } from 'context/dashboard';
import { useCleanState } from 'hooks/useCleanState';
import CreateNewIpv4ModalForm from 'components/forms/create-ipv4';
import { dataTestIdsIps } from 'data-test-ids';
import PortalNotification from 'portals/Notification';

import glossary from '../data/glossary.json';

export default function Ipv4New() {
  // state
  const [openForm, setOpenForm] = useState<boolean>(true);

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { post } = fetchFunc.ip;
  const cleanState = useCleanState();
  const page = 'ips';
  const history = useHistory();

  // func
  useEffect(() => {
    document.title = `${glossary.name} | New ${glossary.ipv4}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('ip', 'post');
      cleanState(page, 'post');
    };
  }, [cleanState]);

  const handleCloseModalForm = () => {
    setOpenForm(false);
    return history.push('/');
  };

  return (
    <>
      {openForm &&
        <CreateNewIpv4ModalForm
          onCancel={handleCloseModalForm}
          open={openForm}
          dataTestIds={dataTestIdsIps.modalForm}
        />
      }
      {post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
