import Typography from 'components/typography';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import LinkOderBareMetal from './LinkOderBareMetal';
import RedirectBtnCreateVm from './RedirectBtnCreateVm';

import glossary from '../../../../data/glossary.json';

type TProps = {
  tabsType: 'all' | 'vms' | 'bms',
};

export const showTwoButtons = () => (
  <div className='col items-center gap-1'>
    <LinkOderBareMetal />
    <Typography
      text='or'
      fontSize='xs'
    />
    <RedirectBtnCreateVm />
  </div>
);

export default function EmptyBlock(props: TProps){
  // props
  const { tabsType } = props;


  const showAllButtons = () => (
    <div className='row mt-12 justify-evenly'>
      <div className='col items-center'>
        <ExclamationCircleIcon
          className='stroke-[#02061D33] w-12 h-12'
        />
        <Typography
          text={glossary.empty_state.no_instances}
          customStyle='my-6'
          fontSize='xs'
          fontWeight='normal'
        />
      </div>
      <div>
        {showTwoButtons()}
      </div>
    </div>
  );

  const renderButtons = () => {
    if(tabsType === 'vms') {
      return <RedirectBtnCreateVm />;
    }

    return <LinkOderBareMetal />;
  };

  const renderWrapperForButton = () => (
    <div className='col mt-12 h-full items-center justify-end'>
      <ExclamationCircleIcon
        className='stroke-[#02061D33] w-12 h-12'
      />
      <Typography
        text={glossary.empty_state.no_instances}
        customStyle='my-6'
        fontSize='xs'
        fontWeight='normal'
      />
      {renderButtons()}
    </div>
  );

  const renderContent = () => {
    if(tabsType === 'all') {
      return showAllButtons();
    }

    return renderWrapperForButton();
  };

  return <>{renderContent()}</>;
};
