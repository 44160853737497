import React from 'react';

import { useInstance } from 'context/instance';
import AlertMsg from 'components/alerts/AlertMsg';
import { TEST_ID_NETWORKS_PAGE_ALERT_SECTION } from 'data-test-ids/data-testid-variables';

export default function AlertSection() {
  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const { instance: { location, selectedCluster: { name } } } = stateInstance;
  const fullNameClusterAndLocation = `${ name } - ${location}`;

  return (
    <AlertMsg typeOfAlert='info'>
      <div
        data-testid={TEST_ID_NETWORKS_PAGE_ALERT_SECTION}
        className='col'
      >
        <p>
          Create Network for:
        </p>
        <p>
          {fullNameClusterAndLocation}
        </p>
      </div>
    </AlertMsg>
  );
};
