import { useDashboard } from 'context/dashboard';
import { matchClusterUUID } from 'helpers/MatchUUIDs';

import glossary from '../data/glossary.json';

export default function useGetItemsNetwork() {
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;
  const cluster = matchClusterUUID(data.cluster_uuid, false);
  const location = matchClusterUUID(data.cluster_uuid, true);

  const ITEMS: {title: string, value: string}[] = [
    {
      title: 'Subnet',
      value: data.prefix6,
    },
    {
      title: glossary.cluster,
      value: cluster,
    },
    {
      title: glossary.uuid,
      value: data.uuid,
    },
    {
      title: 'Region',
      value: location,
    },
  ];

  return ITEMS;
};
