import React, { useState } from 'react';

import Typography from 'components/typography';
import VmOverviewModal from 'feature/network/components/VmOverviewModal';

import { TAssignetInstances } from './Items';

type TProps = {
  dataTestIds?: {},
  vm: TAssignetInstances,
};

/**
 * @description The Items component .
 * @param props vmId, dataTestIds
 * @param vmId required
 *
 * @returns div
*/

export default function Item(props: TProps) {
  // state
  const [openModalVMWindow, setOpenModalVMWindow] = useState<boolean>(false);

  // props
  const { dataTestIds, vm } = props;

  // func
  const handleOpenModal = () => {
    setOpenModalVMWindow(true);
  };

  const instance_id = (vm?.uuid ? vm?.uuid : vm?.vm_uuid) || '';
  const isInstanceName = (vm?.name ? vm?.name : vm?.vm_uuid) || '';

  return (
    <div
      onClick={() => handleOpenModal()}
      key={instance_id}
      className='pl-6 py-4 underline cursor-pointer'
      data-testid={dataTestIds}
    >
      <Typography
        text={isInstanceName}
        fontWeight='thin'
        fontSize='s'
        color='customColorDarkBlue80'
        // dataTestId={dataTestIds}
      />
      {openModalVMWindow &&
        <VmOverviewModal
          open={openModalVMWindow}
          vmUUID={instance_id}
          handleOpenModal={setOpenModalVMWindow}
          dataTestIds={dataTestIds}
        />
      }
    </div>
  );
};
