import React, { useState, useEffect } from 'react';

import SSHKeysList from 'feature/ssh-keys/SSHKeysList';
import { useDashboard } from 'context/dashboard';
import Subheader from 'components/Subheader';
import PageLayout from 'layouts/pages';
import { useCleanState } from 'hooks/useCleanState';
import ModalWindowBulkOperations from 'feature/bulk-operations/delete/ModalWindowBulkOperations';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingItems from 'hooks/useSelectedOrDeselectedAllRemovingItems';
import {
  TEST_ID_SSH_KEYS_PAGE_ADD_SSH_KEY_BTN,
} from 'data-test-ids/data-testid-variables';
import { dataTestIdsKeys } from 'data-test-ids';
import ModalWrapper from 'components/forms/create-ssh-key';
import PortalNotification from 'portals/Notification';
import { setNewSshKey } from 'actions/dashboard';
import { useGetSshKeys } from 'hooks/useGetSshKeys';

import Empty from './Empty';

import glossary from '../data/glossary.json';

export default function SSHKeys() {
  // state
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc } = state;
  const { loading, error, status, data } = fetchFunc.sshKeys.get;
  const cleanState = useCleanState();
  const page = 'sshKeys';
  const fetchMethod = 'get';

  // hooks
  useGetSshKeys();

  useEffect(() => {
    document.title = `${glossary.name} | ${glossary.ssh_keys_plural}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('sshKey', 'post');
      cleanState(page, 'post');
    };
  }, [cleanState]);

  const handleOpenModalWindow = () => {
    cleanState('sshKey', 'post');
    setOpenForm(true);
    dispatch(
      setNewSshKey({
        tenant_uuid: '',
        name: '',
        key: '',
      }),
    );
  };

  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItems('sshKeys', false);

  return (
    <>
      {((!loading && !error) || (status === 404)) &&
        <Subheader
          title={glossary.ssh_key}
          buttonTitle={`${glossary.actions.add} ${glossary.ssh_key}`}
          onClick = {handleOpenModalWindow}
          dataTestId={TEST_ID_SSH_KEYS_PAGE_ADD_SSH_KEY_BTN}
          dataTestIds={dataTestIdsKeys.subheader}
        />
      }
      {openForm &&
        <ModalWrapper
          onCancel={() => setOpenForm(false)}
          open={openForm}
          dataTestIds={dataTestIdsKeys.modalForm}
        />
      }
      {data.length !== 0 &&
        <BulkOperationsSection
          setOpenPortalModalWindow={setOpenPortalModalWindow}
          page='sshKeys'
          handleDeselectedAllItems={handleDeselectedAllItems}
        />
      }
      <PageLayout
        method={fetchMethod}
        page={page}
        renderEmpty={<Empty description={glossary.ssh_keys_plural} />}
        renderSuccess={
          <SSHKeysList
            isShowCheckbox={data.length === 0 ? false : true}
          />
        }
      />
      <ModalWindowBulkOperations
        openPortalModalWindow={openPortalModalWindow}
        setOpenPortalModalWindow={setOpenPortalModalWindow}
        page='sshKeys'
        itemsName={glossary.ssh_key as 'SSH Key'}
      />
      {fetchFunc.sshKey.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
