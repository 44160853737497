import React, { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import { useInstance } from 'context/instance';
import { useDashboard } from 'context/dashboard';
import { setFetchFunc } from 'actions/dashboard';
import Button from 'components/buttons';
import AlertMsg from 'components/alerts/AlertMsg';
import { setInstance } from 'actions/instance';
import { useTenants } from 'context/tenants';

import glossary from '../../../data/glossary.json';

export default function NewIp({dataTestIds}: {dataTestIds: string}) {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [warning, setWarning] = useState<string>('');
  const [msg, setMsg] = useState<string>('');
  const [newIpv4Generated, setNewIpv4Generated] = useState<string>('');

  // variables
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const {
    instance: {
      flavor,
      selectedCluster: {
        uuid: selectedClusterUuid,
      },
    },
  } = stateInstance;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { data: ips } = fetchFunc.ips.get;

  // func
  const handleSubmit = async () => {
    const formData = {
      tenant_uuid: selectedTenantId,
      cluster_uuid: selectedClusterUuid,
    };
    if (!formData.cluster_uuid) {
      return setWarning(glossary.notifications.success.new_ipv4_address_generated);
    };
    setLoading(true);
    setError('');
    setMsg('');
    fetch(`${process.env.REACT_APP_API_URL}/network/ipv4/${formData.cluster_uuid}/${selectedTenantId}`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {'Authorization': `Bearer ${token}`, 'Content-type': 'application/json'},
    })
      .then(response => {
        if(response.ok) {
          setLoading(false);
          setMsg(glossary.notifications.success.new_ipv4_address_generated);
          response.json().then((result) => {
            const correctIpAddressFormat = { ...result, address4: result?.address4.replace('/32', '')};
            setNewIpv4Generated(correctIpAddressFormat.address4);
            dispatchInstance(setInstance(correctIpAddressFormat.address4, 'selectedIpv4'));
            dispatchDashboard(setFetchFunc([...ips, correctIpAddressFormat], 'ips', 'get', 'data'));
          });
        } else {
          setLoading(false);
          response.json().then((result) => {
            let msg = result.detail;
            if (response.status === 422) {
              msg = result.detail[0].msg;
            }
            setError(msg);
          });
        };
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const handleGenerateNewIpv4 = () => {
    if (flavor.uuid === '') {
      setWarning(glossary.notifications.errors.please_choose_flavor);
    } else {
      handleSubmit();
    };
  };

  return (
    <>
      {loading ? <Spinner spaceAroundSpinner /> :
        <>
          <div className='row justify-end items-center'>
            <Button
              type='button'
              handleClick={handleGenerateNewIpv4}
              uiStyle='secondary'
              title={`${glossary.actions.create} ${glossary.ipv4}`}
              customStyle='my-3 flex'
              dataTestId={dataTestIds}
              size='small'
            />
          </div>
          {msg &&
            <AlertMsg typeOfAlert='success'>
              <div className='row justify-between items-center'>
                <div className='col'>
                  <p>{msg}</p>
                  <p className='text-customTextBlack2withOpacity7 text-xxs font-medium'>
                    {newIpv4Generated}
                  </p>
                </div>
                <XMarkIcon
                  className='w-4 fill-[#868686] cursor-pointer'
                  onClick={() => setMsg('')}
                />
              </div>
            </AlertMsg>
          }
          {warning && <Info status='warning' msg={warning} />}
          {error && <Info status='error' msg={error} />}
        </>
      }
    </>
  );
};
