import React from 'react';

import Typography from 'components/typography';
import { useTenants } from 'context/tenants';
import CopyComponent from 'feature/copy-func';

/**
 * @description tenant section

 * @returns div
*/

export default function TenantSection() {
  // variables
  const contextTenants = useTenants();
  const state = contextTenants[0];
  const { tenants, selectedTenantId } = state;
  // const data = localStorage.getItem('tenantsStorage');

  return (
    <div className='col'>
      <Typography
        text={`Tenant${tenants?.data?.length > 1 ? 's' : ''} details`}
        customStyle='capitalize pb-1'
        fontSize='sm'
      />
      <hr />
      <div className='row pt-8 pb-14'>
        <ul className='list-none text-s ml-2'>
          {tenants?.data?.map((tenant) => (
            <li
              key={tenant?.uuid}
              className={`
                border-0 rounded-xl px-6 py-4 bg-[#F1F5F9] mb-2
                ${selectedTenantId === tenant?.uuid ? 'border-customColorLightGreen border-[1px]' : ''}
              `}
            >
              {tenant?.customer_name} <br/>
              <div className='row items-start justify-start'>
                <Typography
                  text={tenant?.uuid}
                  fontSize='xxxs'
                  color='customColorDarkBlue60'
                  dataTestId='test-id-main-header-selected-tenant-uuid'
                  customStyle='mr-2'
                />
                <CopyComponent
                  value={tenant?.uuid}
                  dataTestId='test_id_tenant_uuid'
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
