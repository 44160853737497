import React, { useState, lazy, Suspense } from 'react';

import Button from 'components/buttons';
import { IIpv4 } from 'types';
import UIElementWithTooltip from 'feature/ui-element-with-tooltip';
import IconDetach from 'assets/icons/ips/IconDetach';
import { SpinnerLazyLoading } from 'components/spinners/SpinnerLazyLoading';
import { useDashboard } from 'context/dashboard';
import { setFetchFunc } from 'actions/dashboard';

const DetachIPv4 = lazy(() => import('../../detach-ip'));

type TProps = {
  ipv4object: IIpv4,
};

const defaultIpv4 = {
  uuid: '',
  tenant_uuid: '',
  cluster_uuid: '',
  address4: '',
  vm_uuid: '',
  isSelected: false,
  timeoutShow: 100,
  timeoutClose: 1000,
};

/**
 * @description The DetachIpv4Btn component.
 * @param props: ipv4object
 * @param ipv4object required
 * @returns React Fragment
*/

export default function DetachIpv4Btn(props: TProps) {
  // state
  const [openModalDetachIPv4, setOpenModalDetachIPv4] = useState<boolean>(false);
  const [selectedObjectIpv4, setSelectedObjectIpv4] = useState<IIpv4>(defaultIpv4);

  // props
  const {ipv4object} = props;

  // variables
  const contextDashboard = useDashboard();
  const dispatchDashboard = contextDashboard[1];

  // func
  const handleOpenDetachModal = (ipv4: IIpv4) => {
    setSelectedObjectIpv4(ipv4);
    setOpenModalDetachIPv4(true);
  };

  const handleCloseDetachModal = () => {
    setOpenModalDetachIPv4(false);
    setSelectedObjectIpv4(defaultIpv4);
    dispatchDashboard(setFetchFunc('', 'instance', 'patch', 'error'));
    dispatchDashboard(setFetchFunc(0, 'instance', 'patch', 'status'));
  };

  return (
    <>
      <Button
        type='button'
        uiStyle='without-style'
        handleClick={() => handleOpenDetachModal(ipv4object)}
      >
        <UIElementWithTooltip
          text='Detach'
          element={<IconDetach />}
          dataTestId='vms_page_ips_detach_btn'
        />
      </Button>
      {openModalDetachIPv4 &&
        <Suspense fallback={<SpinnerLazyLoading />}>
          <DetachIPv4
            open={openModalDetachIPv4}
            ipData={selectedObjectIpv4}
            onCancel={handleCloseDetachModal}
            redBtn
          />
        </Suspense>
      }
    </>
  );
};
