// import { useState } from 'react';

import Typography from 'components/typography';
// import { useFilterIps } from 'hooks/useFilterIps';
import getStyleOverviewSection from 'helpers/getStyleOverviewSection';
// import CustomSelect from 'components/input/Select';
import Spinner from 'components/spinners/Spinner';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
// import { useDashboard } from 'context/dashboard';
import Info from 'components/Info';
import useLogout from 'hooks/useLogout';
// import { TSelectedCluster } from 'types/index';
import useGetMetricsIpv4s from 'hooks/useGetMetricsIpv4s';

import classes from '../overview.module.scss';

import glossary from '../../../data/glossary.json';
import ChartPie from '../chartPie';
// import { clusters } from '../clusters';
import EmptyBlock from '../emptyBlock';

type TCustomDataItem = {
  name: string,
  value: number,
  color: string,
};

export default function Ipv4sSection(){
  // state
  // const [selectedCluster, setSelectedCluster] = useState<TSelectedCluster>({
  //   name: '',
  //   uuid: '',
  // });

  // variables
  const { error, loading, status, data } = useGetMetricsIpv4s();

  const { handlePagePaths } = useGetRoutesPagePath();
  const { handleLogout } = useLogout();

  // style
  const { grayWrapperSectionClass, grayWrapperSectionContentClass } = classes;

  // func
  // const returnDataByCluster = () => {
  //   if (selectedCluster.uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5') {
  //     return byClusters?.norway?.amount;
  //   }
  //   if (selectedCluster.uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7' ) {
  //     return byClusters?.sweden?.amount;
  //   }
  //   return all?.amount;
  // };

  // const handleChangeCluster = (value: string, uuid: string) => {
  //   setSelectedCluster({
  //     ...selectedCluster,
  //     name: value,
  //     uuid: uuid,
  //   });
  // };

  // const customData: TCustomDataItem[] = [
  //   {
  //     name: 'unattached',
  //     value: returnDataByCluster()?.notAttached > 0 ? returnDataByCluster()?.notAttached : 0,
  //     color: '#745BE6',
  //   },
  //   {
  //     name: 'attached',
  //     value: returnDataByCluster()?.attached > 0 ? returnDataByCluster()?.attached : 0,
  //     color: '#27A9BF',
  //   },
  // ];

  const customData: TCustomDataItem[] = [
    {
      name: 'unattached',
      value: data.unattached > 0 ? data.unattached : 0,
      color: '#745BE6',
    },
    {
      name: 'attached',
      value: data.attached > 0 ? data.attached : 0,
      color: '#27A9BF',
    },
  ];

  if (status === 403) {
    handleLogout();
    return <Info status='error' msg={error} />;
  };

  return (
    <div className={`${grayWrapperSectionClass}`}>
      <div className='row justify-between items-center mr-6'>
        <Typography
          text={glossary.ipv4s_plural}
          fontSize='sm'
          customStyle='m-6'
        />
        {/* <CustomSelect
          handleChange={handleChangeCluster}
          name={glossary.cluster}
          label='location'
          value={selectedCluster.name === '' ? `All ${glossary.cluster}` : selectedCluster.name}
          selectFeedback=''
          options={clusters}
          optionValue='name'
          disabled={true} //{all?.amount?.all === 0 ? true : false}
          dataTestId='test_id_cluster'
          isShowLabel={false}
        /> */}
      </div>
      <hr />
      {error ?
        <Info status='error' msg={error} /> :
        null
      }
      {(loading) ?
        <Spinner spaceAroundSpinner />
        : null
      }
      <div className={`${grayWrapperSectionContentClass}`}>
        {(!loading && !error) ?
          <>
            {data.count === 0 ?
              <EmptyBlock
                btnTitle={`${glossary.actions.create} ${glossary.ipv4}`}
                redirectTo={handlePagePaths().newIpv4}
              />
              :
              <div className='row items-center justify-between '>
                <div className='basis-6/12'>
                  {customData.map((item: TCustomDataItem) => (
                    <div key={item.name} className='row items-center justify-between py-2'>
                      <div className='row items-center justify-start'>
                        <div className={`w-[10px] h-[10px] mb-[0.1rem] mr-2 rounded-full ${getStyleOverviewSection(item.name).bg}`} />
                        <Typography
                          text={item.name}
                          fontSize='xxxs'
                          fontWeight='medium'
                          color='customColorDarkBlue60'
                        />
                      </div>
                      <Typography
                        text={item.value}
                        fontWeight='medium'
                        fontSize='xxxs'
                        color='customColorDarkBlue60'
                      />
                    </div>
                  ))}
                </div>
                <ChartPie
                  customData={customData}
                  customHeight={57}
                  customSize={10}
                  customWidth={57}
                  totalNumber={data.count}
                  customFontSize={11}
                  dy={5}
                />
              </div>
            }
          </>
          :
          null
        }
      </div>
    </div>
  );
};
