import { useCallback, useEffect } from 'react';

import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { IVm } from 'types';
import { useTenants } from 'context/tenants';
import { setSelectedInstanceType } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { apiUrls } from 'helpers/apiUrls';

/**
 * @description useMakeRequestGetBareMetalByClick - a hook that returns a function
 * that makes an API /GET request to get all instances of type berametal
 * @returns handleGetBareMatel
 */

function useMakeRequestGetBareMetalByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const page = 'baremetal';
  const url = `${apiUrls.instances}${selectedTenantId}?mtype=baremetal`;
  const fetchMethod = 'get';
  const handleFetchRequest = useHandleFetchRequest();
  const context = useDashboard();
  const dispatch = context[1];

  const handleGetBareMatel = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (selectedTenantId) {
      const getBeraMetal = api.useFetchData<[], Array<IVm>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getBeraMetal);
      dispatch(setSelectedInstanceType('baremetal'));
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url, dispatch]);

  return { handleGetBareMatel };
};

/**
 * @description useMakeRequestGetBareMetalImmediately - a hook that makes an API /GET request
 * to immediately get all instances of type berametal using useEffect
 * @returns null
 */

function useMakeRequestGetBareMetalImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetBareMatel } = useMakeRequestGetBareMetalByClick();

  useEffect(() => {
    if (selectedTenantId) {
      handleGetBareMatel();
    }
    return () => {};
  }, [selectedTenantId, handleGetBareMatel]);
};

export {
  useMakeRequestGetBareMetalByClick,
  useMakeRequestGetBareMetalImmediately,
};
