import React, { ReactNode }  from 'react';

import classes from '../index.module.scss';

type TProps = {
  children: ReactNode;
  dataTestId?: string;
};

/**
 * @description The ChildrenSection component is a grey component,
 * accepts a {title} that will be shown in the header and
 * {children} that will be shown in the body section
 * and can also be customized in appearance.
 *
 * @used by Aside component
 *
 * @param props children, dataTestId
 * @param children required
 * @param dataTestId required
 *
 * @returns section
*/

export default function ChildrenSection(props: TProps) {
  const {
    children=<></>,
    dataTestId='section_ui_component',
  } = props;

  const {
    asideSectionClass,
  } = classes;

  return (
    <section
      data-testid={dataTestId}
      className={asideSectionClass}
    >
      {children}
    </section>
  );
};
