import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';

import App from './App';

const analytics = () => {
  const trackId: string = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string;
  ReactGA.initialize(trackId);
};

analytics();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Router>
    <App />
  </Router>,
);
