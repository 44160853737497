import React from 'react';
import { Link } from 'react-router-dom';

import LightFullLogo from 'assets/icons/logo/full/Light';
import ScheduledMaintenance from 'components/maintenance/scheduled/ScheduledMaintenance';
import { TEST_ID_SIDEBAR } from 'data-test-ids/data-testid-variables';
import Typography from 'components/typography';
import { homePage } from 'Routes';

import Navbar from './navbar/Navbar';
import classes from './Sidebar.module.scss';

export default function Sidebar() {
  // variables
  const appVersion = `v${process.env.REACT_APP_VERSION}` || '';

  // style
  const {
    logoClass,
    sidebarClass,
  } = classes;

  return (
    <div
      className={sidebarClass}
      data-testid={TEST_ID_SIDEBAR}
    >
      <div className='items-center'>
        <div className={logoClass}>
          <Link to={homePage}>
            <LightFullLogo color='white' />
          </Link>
        </div>
        <Navbar />
      </div>
      <div>
        <ScheduledMaintenance />
        {process.env.REACT_APP_VERSION ?
          <Typography
            text={appVersion}
            fontSize='xxxs'
            color='white'
            customStyle='row justify-center mt-2'
          />
          : null
        }
      </div>
    </div>
  );
};
