import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon, CheckCircleIcon, InformationCircleIcon, ExclamationTriangleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

import classess from './notification.module.scss';

type TProps = {
  text: 'success' | 'error' | 'warning' | 'info',
  description?: string,
  timeoutShow?: number,
  timeoutClose?: number,
};

export default function NotificationComponent({text, description, timeoutShow=200, timeoutClose=1000}: TProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, timeoutShow);

    return() => {
      setShow(true);
    };
  }, [timeoutShow]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, timeoutClose);

    return() => {
      setShow(false);
    };
  }, [timeoutClose]);

  const {
    notificationContentWrapperClass,
    notificationClass,
    iconWrapperClass,
    buttonClass,
    greenBgClass,
    greenBClass,
    redBgClass,
    redBClass,
    blueBgClass,
    blueBClass,
    yellowBgClass,
    yellowBClass,
  } = classess;

  const setStyle: () => string[] = () => {
    if (text === 'info') {
      return [blueBgClass, blueBClass];
    }
    if (text === 'error') {
      return [redBgClass, redBClass];
    }
    if (text === 'warning') {
      return [yellowBgClass, yellowBClass];
    }
    return [greenBgClass, greenBClass];
  };

  const bgColor = setStyle()[0];
  const borderColor = setStyle()[1];

  const iconsAction = {
    error: ExclamationCircleIcon,
    success: CheckCircleIcon,
    warning: ExclamationTriangleIcon,
    info: InformationCircleIcon,
  };

  const CurrentItemIcon = iconsAction[text];

  return (
    <div
      className={notificationClass}
    >
      <Transition
        show={show}
        as={Fragment}
        enter='transform ease-out duration-300 transition'
        enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
        enterTo='translate-y-0 opacity-100 sm:translate-x-0'
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div
          className={`${borderColor} ${notificationContentWrapperClass}`}
        >
          <div className='p-4'>
            <div className='flex items-center'>
              <div
                className={`${iconWrapperClass}`}
              >
                <CurrentItemIcon className={`h-5 w-5 ${bgColor}`} aria-hidden='true' />
              </div>
              <div className='ml-3 w-0 flex-1 pt-0.5'>
                <p className='text-sm font-medium capitalize'>{text}</p>
                <p className='text-xxs font-medium text-customTextBlack2withOpacity7'>{description}</p>
              </div>
              <div className='ml-4 flex flex-shrink-0'>
                <button
                  type='button'
                  className={buttonClass}
                  onClick={() => setShow(false)}
                >
                  <span className='sr-only'>Close</span>
                  <XMarkIcon
                    className='h-5 w-5 fill-customColorDarkBlue'
                    aria-hidden='true'
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
