import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setPreselectedRemovingItems } from 'actions/dashboard';
import { INetwork, ISshKey } from 'types';

export default function useSelectedOrDeselectedAllRemovingItems(page: 'networks' | 'sshKeys', isSelected: boolean) {

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc } = state;
  const { data } = fetchFunc[page].get;

  const handlePreselectedItems = () => {
    const newArray = [];

    for(let i = 0; i < data.length; i++ ) {
      newArray.push(data[i].uuid);
    }

    dispatch(setPreselectedRemovingItems(page, 'uuid', newArray));
  };

  const handleSelectedAllItems = () => {
    const newList = data.map((item: ISshKey | INetwork) => {
      const updatedItem = {
        ...item,
        isSelected: isSelected,
      };

      return updatedItem;
    });

    dispatch(setFetchFunc(newList, page, 'get', 'data'));

    if (isSelected) {
      handlePreselectedItems();
    } else {
      dispatch(setPreselectedRemovingItems(page, 'uuid', []));
      dispatch(setPreselectedRemovingItems(page, 'name', []));
    }
  };

  return handleSelectedAllItems;
};
