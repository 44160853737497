import { INotification, TErrorNotifications } from 'types';

export default function useCreateErrorsObjects(object: TErrorNotifications[]) {

  const newErrorsArray: INotification[] = object.map((item: TErrorNotifications) => (
    {
      uuid: '',
      code: item.cause,
      message: item.message,
      type: 'error',
      timeoutShow: 500,
      timeoutClose: 1500,
    }
  ));

  return newErrorsArray;
};
