import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useDashboard } from 'context/dashboard';
import { useCleanState } from 'hooks/useCleanState';
import { dataTestIdsNetworks } from 'data-test-ids';
import PortalNotification from 'portals/Notification';
import CreateNewNetworkForm from 'components/forms/create-network';

import glossary from '../data/glossary.json';

export default function NetworkNew() {
  // state
  const [openForm, setOpenForm] = useState<boolean>(true);
  // const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // variables
  const context = useDashboard();
  const state = context[0];
  // const dispatch = context[1];
  const { fetchFunc } = state;
  const { post } = fetchFunc.networks;
  const cleanState = useCleanState();
  const page = 'networks';
  const history = useHistory();

  // func
  useEffect(() => {
    document.title = `${glossary.name} | New ${glossary.network}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('network', 'post');
      cleanState(page, 'post');
    };
  }, [cleanState]);

  // const handleOpenModalWindow = () => {
  //   cleanState('sshKey', 'post');
  //   setOpenForm(true);
  //   dispatch(
  //     setNewSshKey({
  //       tenant_uuid: '',
  //       name: '',
  //       key: '',
  //     }),
  //   );
  // };

  const handleCloseModalForm = () => {
    // cleanState('network', 'post');
    // setOpenForm(true);
    // dispatch(
    //   setNewNetwork({
    //     tenant_uuid: '',
    //     cluster_uuid: '',
    //     network_name: '',
    //   }),
    // );
    setOpenForm(false);
    return history.push('/');
  };

  return (
    <>
      {openForm &&
        <CreateNewNetworkForm
          onCancel={handleCloseModalForm}
          open={openForm}
          page={glossary.networks_plural as 'Networks'}
          dataTestIds={dataTestIdsNetworks.modalForm}
        />
      }
      {post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
