import React from 'react';

import { useDashboard } from 'context/dashboard';
import { TDataTestIdsOverviewPageContentMainInfoAsideChildrenItems } from 'data-test-ids/types/overview-page';

import Item from './Item';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainInfoAsideChildrenItems,
};

/**
 * @description The Items component .
 * @param props text, fontWeight, color, textTag, dataTestId, fontSize, customStyle, truncate, width
 * @param text required
 *
 * @returns div
*/
export type TAssignetInstances = {
  vm_uuid: string,
  name?: string,
  address6: string,
  uuid?: string,
};

export default function Items(props: TProps) {
  // props
  const { dataTestIds } = props;

  // hooks
  const contextDashboard = useDashboard();

  // variables
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;

  return (
    <div className='divide-y divide-customColorLightGray'>
      {data.assigned_addresses6?.map((vm: TAssignetInstances) => {
        const instance = vm?.uuid ? vm?.uuid : vm?.vm_uuid;

        return (
          <Item
            key={instance}
            dataTestIds={dataTestIds}
            vm={vm}
          />
        );
      })}
    </div>
  );
};
