/**
 * @description The IconDetach UI component
 * @param props customStyle
 * @returns svg
*/

const IconDetach = ({customStyle=''}: { customStyle?: string} ) => {

  return (
    <svg
      className={`${customStyle}`}
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
    >
      <path d='M9.24123 5.15918C9.01147 4.92942 8.63896 4.92942 8.4092 5.15918L7.4004 6.16798L6.39159 5.15918C6.16184 4.92942 5.78933 4.92942 5.55957 5.15918C5.32981 5.38894 5.32981 5.76145 5.55957 5.9912L6.56837 7.00001L5.55957 8.00881C5.32981 8.23857 5.32981 8.61108 5.55957 8.84084C5.78933 9.07059 6.16184 9.07059 6.39159 8.84084L7.4004 7.83203L8.4092 8.84084C8.63896 9.07059 9.01147 9.07059 9.24123 8.84084C9.47098 8.61108 9.47098 8.23857 9.24123 8.00881L8.23242 7.00001L9.24123 5.9912C9.47098 5.76145 9.47098 5.38894 9.24123 5.15918Z' fill='#02061D' fillOpacity='0.6'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M7.40039 0C3.5344 0 0.400391 3.13401 0.400391 7C0.400391 10.866 3.5344 14 7.40039 14C11.2664 14 14.4004 10.866 14.4004 7C14.4004 3.13401 11.2664 0 7.40039 0ZM1.57705 7C1.57705 3.78386 4.18425 1.17666 7.40039 1.17666C10.6165 1.17666 13.2237 3.78386 13.2237 7C13.2237 10.2161 10.6165 12.8233 7.40039 12.8233C4.18425 12.8233 1.57705 10.2161 1.57705 7Z' fill='#02061D' fillOpacity='0.6'/>
    </svg>
  );
};

export default IconDetach;
