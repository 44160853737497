import React from 'react';
import { useLocation } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import Typography from 'components/typography';
import AttachIpv4Btn from 'feature/vm/ip-addresses/ipv4s-section/AttachIpv4Btn';

import Ipv4s from './Ipv4s';

import classes from '../../index.module.scss';
import glossary from '../../../../data/glossary.json';

/**
 * @description The Ipv4sSection
 * @returns div
*/

export default function Ipv4sSection() {
  // hooks
  const contextDashboard = useDashboard();
  const location = useLocation();

  // variables
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.instance.get;
  const { status, addresses4 } = data;
  const isInstancesPage = location.pathname.includes('/instances');
  const deleteStatus = status === 'DELETED' || status === 'DELETION_REQUESTED';
  const resetStatus = status === 'RESET_REQUESTED' || status === 'REBOOT_REQUESTED';
  const numberOfIps = addresses4?.length;
  const instanceType = localStorage.getItem('selectedInstanceType');
  const getType = fetchFunc.instance.get.data?.mtype ? fetchFunc.instance.get.data?.mtype : instanceType;

  // style
  const { ipsSectionClass } = classes;

  return (
    <div className={ipsSectionClass}>
      <div className='row'>
        <Typography
          text={glossary.ipv4}
          fontWeight='medium'
          fontSize='s'
        />
        {numberOfIps > 0 &&
          <>
            &nbsp;
            <Typography
              text={`(${numberOfIps})`}
              fontWeight='thin'
              fontSize='s'
            />
          </>
        }
      </div>
      {numberOfIps > 0 ?
        <Ipv4s />
        :
        <Typography
          text={glossary.empty_state.no_ip_addresses}
          fontWeight='thin'
          fontSize='s'
          customStyle='py-4'
        />
      }
      {(getType === 'baremetal') ?
        null :
        <>
          {(isInstancesPage && (!deleteStatus && !resetStatus)) &&
            <AttachIpv4Btn />
          }
        </>
      }
    </div>
  );
};
