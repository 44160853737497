import React from 'react';

type TProps = {
  customStyle?: string,
  blinkerDotBig: string,
  customColor: string,
}

const IconBlinkerDot = (props: TProps ) => {
  const { blinkerDotBig, customColor } = props;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
      <circle cx='10.5' cy='10.5' r='10.5' fill={customColor} fillOpacity='0.3' id={blinkerDotBig} />
      <circle cx='10.5' cy='10.5' r='3.5' fill={customColor}/>
    </svg>
  );
};

export default IconBlinkerDot;
