import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { TEST_ID_ALERT_MSG } from 'data-test-ids/data-testid-variables';
import { ReactNode } from 'react';

import classes from './alert.module.scss';

type TProps = {
  children: ReactNode,
  typeOfAlert: 'info' | 'success',
};

export default function AlertMsg({children, typeOfAlert}: TProps) {
  const { alertClass, successAlertClass } = classes;

  if (typeOfAlert === 'success') {
    return (
      <div className={`${successAlertClass} row ${alertClass} w-full`}>
        <CheckCircleIcon className='w-5 fill-primaryColorEasternBlue'/>
        <div className='ml-18px flex-1'>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div
      data-testid={TEST_ID_ALERT_MSG}
      className={`${alertClass} row rounded text-xs font-medium m-1`}
    >
      <div className='w-7 h-7 bg-customBgLightYellow20 rounded-md all-items-center'>
        <ExclamationTriangleIcon className='w-5 fill-customDarkYellow'/>
      </div>
      <div className='ml-18px'>
        {children}
      </div>
    </div>
  );
};
