import React from 'react';

import HeaderMaintenanceStatuses from 'components/maintenance/header-statuses/HeaderMaintenanceStatuses';

import classes from './index.module.scss';

import Tenants from './components/Tenants';
import UserData from './components/UserData';

/**
 * @description Header is the main heading.
 * It is displayed on all pages.
 * It contains tenants and information about the user.
 * It shows the maintenance/incident statuses that are currently occurring.
 *
 * @returns header
 */

export default function Header() {
  // style
  const { wrapperClass, headerClass } = classes;

  return (
    <header
      data-testid='test-id-main-header'
      className={`${headerClass} ${wrapperClass} justify-between`}
    >
      <HeaderMaintenanceStatuses />
      <div
        data-testid='test-id-main-header-customer-section'
        className='flex flex-row items-center'
      >
        <Tenants />
        <UserData />
      </div>
    </header>
  );
};
