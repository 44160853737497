import { returnIds } from './pages/GeneralPages';
import { returnIds as returnIdsOverviewPage } from './pages/OverviewPage';
import { returnIds as returnIdsNewVm } from './pages/NewVm';

// default
export const dataTestIdsGeneralPages = returnIds('default');

// vms
export const dataTestIdsVms = returnIds('vms_page');
export const dataTestIdsNewVm = returnIdsNewVm();
export const dataTestIdsVm = returnIdsOverviewPage('vm');

// networks
export const dataTestIdsNetworks = returnIds('networks_page');
export const dataTestIdsNetwork = returnIdsOverviewPage('network');

// keys
export const dataTestIdsKeys = returnIds('ssh_keys_page');

// ips
export const dataTestIdsIps = returnIds('ipv4s_page');
