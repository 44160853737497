import React, { forwardRef } from 'react';

import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import useGetImages from 'hooks/useGetImages';
import { useDashboard } from 'context/dashboard';

import Items from './Items';

import TitleSection from '../TitleSection';

import glossary from '../../../data/glossary.json';

const Images = forwardRef<HTMLDivElement>((props, ref) => {
  const { error, loading } = useGetImages();
  const context = useDashboard();
  const state = context[0];
  const { images } = state;
  const imageGroups = images?.use_fe || [];

  const returnContent = () => {
    if (error) return <Info status='error' msg={error} />;
    if (loading) return <Spinner />;
    return <Items imageGroups={imageGroups}/>;
  };

  return (
    <div
      ref={ref}
      className='col-span-6'
    >
      <TitleSection
        title={glossary.image}
        description={glossary.descriptions.the_image_running_on_your_instance_}
      />
      {returnContent()}
    </div>
  );
});

export default Images;
