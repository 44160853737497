import { useLocation } from 'react-router-dom';

import Aside from 'components/aside';
import Ipv6Section from 'feature/vm/ip-addresses/Ipv6Section';
import Ipv4sSection from 'feature/vm/ip-addresses/ipv4s-section';

import Items from './info-items';

import glossary from '../../data/glossary.json';

type TProps = {
  dataTestIds?: {},
};

/**
 * @description The InfoSection component is used by VM page
 * and network page (in modal form)
 * @param props dataTestIds
 * @returns div
*/

export default function InfoSection(props: TProps) {
  // props
  const { dataTestIds } = props;

  // hooks
  const location = useLocation();

  // variables
  const isInstancesPage = location.pathname.includes('/instances');
  const paddingContentDependsFromPage = isInstancesPage ? 'pl-6 pr-0' : 'pl-14 pr-6';

  return (
    <div
      data-testid='test_id_vm_overview_modal_info'
      className={`mt-10 ${paddingContentDependsFromPage} flex row items-start justify-between`}
    >
      <Items />
      <Aside
        title={glossary.ip_addresses_plural}
        dataTestIds={dataTestIds}
      >
        <Ipv6Section />
        <Ipv4sSection />
      </Aside>
    </div>
  );
};
