import { useInstance } from 'context/instance';
import { setInstance } from 'actions/instance';

export default function useRemoveIp() {

  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const {
    instance: {
      ips: ipAddresses,
      preselectedIpv4s,
    },
  } = stateInstance;

  const handleRemoveIp = (address: string) => {
    const newArray = ipAddresses.filter(ip => ip.address4 !== address);
    dispatchInstance(setInstance(newArray, 'ips'));
    const updatePreselectedIps = preselectedIpv4s.filter((ip: string) => ip !== address);
    dispatchInstance(setInstance(updatePreselectedIps, 'preselectedIpv4s'));
  };

  return handleRemoveIp;
};
