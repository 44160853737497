import React, { forwardRef, useState } from 'react';

import Button from 'components/buttons';
import { useCleanState } from 'hooks/useCleanState';
import ModalWrapper from 'components/forms/create-ssh-key';
import { TDataTestIdsNewVmMainAuthenticationAddKey } from 'data-test-ids/types/new-vm';

import glossary from '../../../data/glossary.json';

const AddKeysSection = forwardRef<HTMLDivElement, {dataTestIds: TDataTestIdsNewVmMainAuthenticationAddKey}>((props, ref) => {
  const [openForm, setOpenForm] = useState<boolean>(false);

  const { dataTestIds } = props;

  const cleanState = useCleanState();

  const openCreateSshKeyModalForm = () => {
    cleanState('sshKeys', 'post');
    setOpenForm(true);
  };

  return (
    <div
      className='relative'
      data-testid={dataTestIds.wrapper.name}
    >
      <Button
        type='button'
        handleClick={openCreateSshKeyModalForm}
        uiStyle='secondary'
        title={`${glossary.actions.add} ${glossary.ssh_key}`}
        dataTestId={dataTestIds.wrapper.button}
        customStyle='absolute right-0 top-[-10px] z-0'
        size='small'
      />
      {openForm &&
        <ModalWrapper
          onCancel={() => setOpenForm(false)}
          open={openForm}
          dataTestIds={dataTestIds.wrapper.modalForm}
        />
      }
    </div>
  );
});

export default AddKeysSection;
