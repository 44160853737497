import React, { ChangeEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import LightFullIconLogo from 'assets/icons/logo/full/Light';
import ColorFullIconLogo from 'assets/icons/logo/full/Color.svg';
import Button from 'components/buttons';
import CustomInput from 'components/input/Input';
import { useDashboard } from 'context/dashboard';
import { setIsApiMaintenance } from 'actions/dashboard';
import { TEST_ID_LOGIN_PAGE_SUBMIT_BTN } from 'data-test-ids/data-testid-variables';

import classes from './login.module.scss';

export default function SignIn() {
  // state
  const [error, setError] = useState<string>('');
  const [form, setFrom] = useState<{email: string, password: string}>({
    email: '',
    password: '',
  });

  // variables
  const contextDashboard = useDashboard();
  const dispatchDashboard = contextDashboard[1];
  const stateDashboard = contextDashboard[0];
  const { isApiMaintenance } = stateDashboard;
  const { oktaAuth, authState } = useOktaAuth();
  const { email, password } = form;
  const data = [
    {
      label: 'Username',
      id: 'email',
      name: 'email',
      type: 'text',
      autoComplete: 'email',
      value: email,
      placeholder: 'Enter username',
    },
    {
      label: 'Password',
      id: 'password',
      name: 'password',
      type: 'password',
      autoComplete: 'current-password',
      value: password,
      placeholder: 'Enter password',
    },
  ];

  // styles
  const {
    loginPageWrapper,
    loginPageLeftSide,
    loginPageLeftSideLogoWrapper,
    loginPageFormClass,
    loginPageFormClassTitle,
    loginPageFormClassText,
    loginPageRigthSide,
    loginPageRightSideLogoWrapperWrapper,
    loginPageRightSideLogoWrapper,
    loginPageRightSideVideoBg,
  } = classes;

  // func
  useEffect(() => {
    localStorage.removeItem('selectedTenantIdStorage');
    localStorage.removeItem('selectedTenantStorage');
    localStorage.removeItem('tenantsStorage');
    localStorage.removeItem('cache-i');
    localStorage.removeItem('cache-f');
    localStorage.removeItem('cache-c');

    return () => {};
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFrom({
      ...form,
      [name]: value,
    });
  };

  const onSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      username: email,
      password: password,
    };

    oktaAuth
      .signInWithCredentials(data)
      .then((res) => {
        const sessionToken = res.sessionToken;
        if (!sessionToken) {
          setError('authentication process failed');
        }
        oktaAuth.signInWithRedirect({
          originalUri: '/tenants',
          sessionToken: sessionToken,
        }).then();
      })
      .catch((err) => setError('Authentication process failed'));
  };

  const url = `${process.env.REACT_APP_STATUS_PAGE_API_ENV}/maintenance_api`;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      const data: { status: 'progress' | 'default' } = await fetch(url, {signal}).then(res => res.json());
      dispatchDashboard(setIsApiMaintenance(data.status));
    })();
  }, [dispatchDashboard, url]);

  if (!authState) {
    return <Spinner />;
  };

  if (authState?.isAuthenticated) {
    return <Redirect to={{ pathname: '/' }}/>;
  };

  if(isApiMaintenance === 'progress') {
    return <Redirect to='/maintenance' />;
  };

  const handleCheck = () => {
    (async () => {
      const data: { status: 'progress' | 'default' } = await fetch(url).then(res => res.json());
      dispatchDashboard(setIsApiMaintenance(data.status));
    })();
  };

  return (
    <div onClick={handleCheck} className={loginPageWrapper}>
      <div className={loginPageLeftSide}>
        <div className={loginPageLeftSideLogoWrapper}>
          <img src={ColorFullIconLogo} alt='logo-color' />
        </div>
        <form
          onSubmit={onSubmit}
          className={loginPageFormClass}
        >
          <h1 className={loginPageFormClassTitle}>
            Welcome
          </h1>
          <p className={loginPageFormClassText}>
            Please enter your login data
          </p>
          {data.map(item => (
            <div key={item.id} className='mb-[27px]'>
              <CustomInput
                name={item.name}
                type={item.type}
                value={item.value}
                handleChange={(event) => handleChange(event)}
                placeholder={item.placeholder}
                inputFeedback=''
                inputStatus='error'
                label={item.label}
              />
            </div>
          ))}
          {/* <div className='flex flex-row justify-center items-center'>
          <div>
            <input type="checkbox" name="rememberMe" id="rememberMe" />
            <label htmlFor="rememberMe" className='text-sm p-1'>Remember me</label>
          </div>
          <p className='text-sm pl-3'>Forgot your password?</p>
        </div> */}
          {error && <Info status='error' msg='Authentication failed: Wrong e-mail or password' />}
          <Button
            type='submit'
            customStyle='mt-14 w-full'
            uiStyle='primary'
            title='Login'
            handleClick={onSubmit}
            dataTestId={TEST_ID_LOGIN_PAGE_SUBMIT_BTN}
          />
        </form>
      </div>
      <div className={loginPageRigthSide}>
        <div className={loginPageRightSideLogoWrapperWrapper}>
          <div className={loginPageRightSideLogoWrapper}>
            <LightFullIconLogo color='white'/>
          </div>
        </div>
        <div className={loginPageRightSideVideoBg}></div>
        <video
          loop={true}
          muted={true}
          playsInline={true}
          autoPlay={true}
          id='hero-video'
          className='w-full h-full object-cover'
          data-video-mobile-src='https://26683789.fs1.hubspotusercontent-eu1.net/hubfs/26683789/videos/Cloud_KV12_Contact_compressed_H.264.mp4'
          data-video-desktop-src='https://26683789.fs1.hubspotusercontent-eu1.net/hubfs/26683789/videos/Cloud_KV12_Contact.mp4'
          src='https://26683789.fs1.hubspotusercontent-eu1.net/hubfs/26683789/videos/Cloud_KV12_Contact.mp4'
        >
        </video>
      </div>
    </div>
  );
};
