import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { setFetchFunc, setPreselectedRemovingItems } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { IIpv4, INetwork, ISshKey } from 'types';
import { TActionsDependsOfStatus, TFetchFuncPage, TFetchFuncRequestMethod, TSuccessNotifications } from 'types';
import { IVm } from 'types';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import { useTenants } from 'context/tenants';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import ContentView from './ContentView';

type TProps = {
  actionName: TActionsMenuComponent,
  itemName: string,
  onCancel: () => void,
  redBtn: boolean;
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  uuid: string,
  status?: string, //TActionsDependsOfStatus,
  url: string,
  headerModal: ReactNode,
  handleSubmitButtonName: string,
};

export type TBodyStatusUpdateVm = {
  status: TActionsDependsOfStatus | string,
};

export default function Content(props: TProps) {
  // props
  const {
    url,
    uuid,
    actionName,
    itemName,
    onCancel,
    redBtn = false,
    fetchMethod,
    page,
    status = '',
    headerModal = 'Should be header action modal',
    handleSubmitButtonName = 'Action name',
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc, preselectedRemovingItems } = state;
  const { error, loading, status: statusItem, message } = fetchFunc[page][fetchMethod];
  const handleFetchRequest = useHandleFetchRequest();
  const { handlePagePaths } = useGetRoutesPagePath();
  const hideBtns = statusItem > 0 || loading || error ? true : false;
  const history = useHistory();

  // func
  const getData = () => {
    const statusAfterDetelion: TActionsDependsOfStatus = 'DELETION_REQUESTED';

    if (page === 'ips') {
      return fetchFunc.ips.get.data.filter((item: IIpv4 ) => item.uuid !== uuid);
    };
    if (page === 'sshKeys') {
      return fetchFunc.sshKeys.get.data.filter((item: ISshKey) => item.uuid !== uuid);
    };
    if (page === 'networks') {
      return fetchFunc.networks.get.data.filter((item: INetwork) => item.uuid !== uuid);
    };
    if (page === 'instances' || page === 'virtualmachine' || page === 'baremetal') {
      const newList = fetchFunc[page].get.data.map((item: IVm) => {
        if (item.uuid === uuid) {
          const updatedItem = {
            ...item,
            status: statusAfterDetelion,
          };

          return updatedItem;
        }
        return item;
      });
      return newList;
    };
    if (page === 'instance') {
      const newList: IVm = {...fetchFunc.instance.get.data, status: statusAfterDetelion};
      return newList;
    };
    return [];
  };

  const getUrl = () => {
    if (page === 'sshKeys') {
      return `${url}/${selectedTenantId}/${uuid}`;
    }
    if (fetchMethod === 'post') {
      return `vm/vm/${uuid}/status`;
    }
    return `${url}/${uuid}`;
  };

  const checkIsItemPreselected = () => {
    const isItemPreselected = preselectedRemovingItems[page].uuid.filter((itemUuid: string) => uuid !== itemUuid);
    dispatch(setPreselectedRemovingItems(page, 'uuid', isItemPreselected));
  };

  const handleSubmitDeleteItem = async() => {
    if (selectedTenantId) {
      const request = api.useFetchData<[], TSuccessNotifications>(getUrl(), fetchMethod);
      handleFetchRequest(page, fetchMethod, request);
      const deletedItem: TSuccessNotifications = await request;
      setTimeout(() => {
        if (deletedItem && deletedItem?.code === 200) {
          const newItemsList: (ISshKey | IVm | INetwork | IIpv4)[] | IVm = getData();
          dispatch(setFetchFunc(newItemsList, page, 'get', 'data'));
          dispatch(setFetchFunc(0, page, fetchMethod, 'status'));
          dispatch(setFetchFunc(deletedItem.message, page, fetchMethod, 'message'));
          checkIsItemPreselected();
          if (page === 'ip') {
            onCancel();
            history.push(handlePagePaths().ipv4s);
            return;
          };
          if (page === 'network') {
            onCancel();
            history.push(handlePagePaths().networks);
            return;
          };
          if (page === 'instance') {
            onCancel();
            history.push(handlePagePaths().instances);
            return;
          };
        };
      }, 2000);
    };
  };

  const handleSubmitUpdateVm = async() => {
    if (selectedTenantId) {
      const request = api.useFetchData<TBodyStatusUpdateVm, IVm>(getUrl(), fetchMethod, null, { status });
      handleFetchRequest(page, fetchMethod, request);
      const updatedItem: IVm = await request;
      setTimeout(() => {
        const getListWithCurrentVm: IVm[] =
        (page === 'instances' || page === 'virtualmachine' || page === 'baremetal') ?
          fetchFunc[page].get.data.map((vm: IVm) => {
            if (vm.uuid === updatedItem.uuid) {
              return updatedItem;
            }
            return vm;
          })
          : [];
        const listWithUpdatedVm = page === 'instance' ? updatedItem : getListWithCurrentVm;
        dispatch(setFetchFunc(listWithUpdatedVm, page, 'get', 'data'));
      }, 2000);
    };
  };

  useEffect(() => {
    if (statusItem === 200 && message === '' && error === '') {
      setTimeout(() => {
        onCancel();
      }, 2000);
    }
    return () => {};
  }, [statusItem, message, error, onCancel]);

  return (
    <FormModalTemplate
      handleSubmitButtonName={handleSubmitButtonName}
      onCancel={onCancel}
      handleSubmit={fetchMethod === 'post' ? handleSubmitUpdateVm : handleSubmitDeleteItem}
      hideBtns={hideBtns}
      redBtn={redBtn}
      header={headerModal}
    >
      <ContentView
        actionName={actionName}
        itemName={itemName}
        fetchMethod={fetchMethod}
        page={page}
      />
    </FormModalTemplate>
  );
};
