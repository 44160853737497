import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setNotifi, setPreselectedRemovingItems } from 'actions/dashboard';
import { IIpv4, INetwork, ISshKey } from 'types';
import { IVm } from 'types';
import { TActionsDependsOfStatus, TErrorNotifications, TSuccessNotifications } from 'types';

import useCreatePromises from './useCreatePromises';

export default function useRemoveItems(
  handleCloseModalWindow: () => void,
  page: 'ips' | 'networks' | 'sshKeys' | 'virtualmachine' | 'baremetal' | 'instances',
) {

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc, preselectedRemovingItems } = state;
  const preselectedRemovingItemsUuid = preselectedRemovingItems[page].uuid;

  const { createPromises, preselectedRemovingItemsPromise } = useCreatePromises(page, preselectedRemovingItemsUuid);

  const successArray: TSuccessNotifications[] = [];
  const errorArray: TErrorNotifications[] = [];

  // array of uuids
  const deletedItemArray: string[] = [];

  const filterDeletedItemArray: () => IIpv4[] | INetwork[] | ISshKey[] | IVm[] = () => {
    if (page === 'ips') return fetchFunc.ips.get.data.filter((item: IIpv4) => !deletedItemArray.includes(item.uuid));
    if (page === 'sshKeys') return fetchFunc.sshKeys.get.data.filter((item: ISshKey) => !deletedItemArray.includes(item.uuid));

    return fetchFunc.networks.get.data.filter((item: INetwork) => !deletedItemArray.includes(item.uuid));
  };

  const removeItems = async() => {
    createPromises();

    try {
      dispatch(setFetchFunc(true, page, 'delete', 'loading'));
      const r = await Promise.allSettled(preselectedRemovingItemsPromise);

      r.map((item) => {
        if (item.status === 'fulfilled') {
          const getArrayValueItem = item.value.message.split(' ');
          const getDeletedItemUuid = getArrayValueItem[4];
          successArray.push(item.value);
          deletedItemArray.push(getDeletedItemUuid);
        } else {
          errorArray.push(item.reason);
        }
        return null;
      });
      const filteredArray = filterDeletedItemArray();

      dispatch(setFetchFunc(filteredArray, page, 'get', 'data'));
      dispatch(setFetchFunc(false, page, 'delete', 'loading'));

      dispatch(setNotifi('errors', errorArray));
      dispatch(setNotifi('success', successArray));

      const statusAfterDetelion: TActionsDependsOfStatus = 'DELETION_REQUESTED';

      const newItemList = filteredArray.map((item: IIpv4 | INetwork | ISshKey | IVm) => {
        const updatedItem = {
          ...item,
          isSelected: false,
        };

        return updatedItem;
      });

      const getVMList = () => {
        if (page === 'instances' || page === 'virtualmachine' || page === 'baremetal') {
          const newList = fetchFunc[page].get.data.map((item: IVm) => {
            if (preselectedRemovingItems[page].uuid.includes(item.uuid)) {
              const updatedItem = {
                ...item,
                isSelected: false,
                status: statusAfterDetelion,
              };
              return updatedItem;
            }
            return item;
          });
          return newList;
        };

      };
      const newList = (page === 'instances' || page === 'virtualmachine' || page === 'baremetal') ? getVMList() : newItemList;

      dispatch(setFetchFunc(newList, page, 'get', 'data'));
      // handleCloseModalWindow();
    } catch (error) {
      // console.error('Error:', error);
    }
    dispatch(setPreselectedRemovingItems(page, 'uuid', []));
    dispatch(setPreselectedRemovingItems(page, 'name', []));
    if(page === 'sshKeys') {
      setTimeout(()=>{
        window.location.reload();
      }, 1500);
    }
  };

  return removeItems;
};
