import VmsIcon from 'assets/icons/navbar/vms.svg';
import NetworksIcon from 'assets/icons/navbar/networks.svg';
import SshKeysIcon from 'assets/icons/navbar/ssh-keys.svg';
import Ipv4sIcon from 'assets/icons/navbar/ipv4s.svg';
import OverviewIcon from 'assets/icons/navbar/overview.svg';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import glossary from '../data/glossary.json';

type TRoute = {
  path: string,
  title: string,
  icon: string,
  exact: boolean,
};

/**
 * @description useGetSidebarNavLink - hook
 * @returns nav links for sidebar
 */

export const useGetSidebarNavLinks = () => {
  // variables
  const { handlePagePaths } = useGetRoutesPagePath();
  const navLinks: TRoute[] = [
    {
      path: '/',
      title: glossary.overview,
      icon: OverviewIcon,
      exact: true,
    },
    {
      path: handlePagePaths().instances,
      title: glossary.instances_plural,
      icon: VmsIcon,
      exact: false,
    },
    {
      path: handlePagePaths().networks,
      title: glossary.networks_plural,
      icon: NetworksIcon,
      exact: false,
    },
    {
      path: handlePagePaths().sshKeys,
      title: glossary.ssh_keys_plural,
      icon: SshKeysIcon,
      exact: false,
    },
    {
      path: handlePagePaths().ipv4s,
      title: glossary.ipv4s_plural,
      icon: Ipv4sIcon,
      exact: false,
    },
  ];

  return { navLinks };
};
