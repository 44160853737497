type TProps = {
  title: string,
  nameInput?: boolean,
  description?: string,
  dataTestId?: string,
};

export default function TitleSection(props: TProps) {
  const { title, nameInput, description, dataTestId } = props;
  const nameInputStyle = nameInput ? 'ml-[21px] text-s' : '';
  const defaultClasses = 'font-medium text-base mb-2';

  return (
    <div data-testid={dataTestId} >
      <h3 className={`${defaultClasses} ${nameInputStyle}`}>
        {title}
      </h3>
      <p className='text-xxs font-normal'>{description}</p>
    </div>
  );
};
