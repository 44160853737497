import React from 'react';

import { useDashboard } from 'context/dashboard';
import ActionItem from 'feature/actions/ActionItem';
import Typography from 'components/typography';
import { TDataTestIdsOverviewPageContentMainHeaderNetworkPage } from 'data-test-ids/types/overview-page';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainHeaderNetworkPage,
};

/**
 * @description The Header component is used on the ip page and
 * contains the name and action delete.
 * @augments Ipv4s page
 * @param props dataTestIds
 * @param dataTestIds required
 * @returns React node - LightGrayWrapper component
*/

export default function Header(props: TProps) {
  // props
  const { dataTestIds } = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.ip.get;
  const { handlePagePaths } = useGetRoutesPagePath();

  return (
    <>
      <Typography
        text={data.address4}
        truncate='truncate'
        width={660}
      />
      <div
        data-testid='test_id_ip_page_header_wrapper'
        className='row'
      >
        <ActionItem
          uuid={data.uuid}
          route={handlePagePaths(data.uuid).ipv4}
          url='network/ipv4'
          page='ip'
          fetchMethod='delete'
          actionName='DELETED'
          itemName={data.address4}
          dataTestIds={dataTestIds.buttonDelete}
          typeAction='button-action'
        />
      </div>
    </>
  );
};
