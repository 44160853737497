import { useHistory } from 'react-router-dom';

import Button from 'components/buttons';
import Typography from 'components/typography';

type TProps = {
  btnTitle: string,
  redirectTo: string,
};

export default function EmptyBlock(props: TProps){
  // props
  const { btnTitle, redirectTo } = props;

  // variables
  const history = useHistory();

  // func
  const handleRedirectTo = () => {
    return history.push(redirectTo);
  };

  return (
    <div className='row items-center justify-between'>
      <div className='flex col basis-full self-stretch'>
        <Typography
          text='Field empty'
          customStyle='self-start'
          fontSize='xs'
        />
        <Button
          title={btnTitle}
          uiStyle='primary'
          handleClick={handleRedirectTo}
          customStyle='min-w-[155px] self-end'
          size='small'
        />
      </div>
    </div>
  );
};
