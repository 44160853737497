import { useCallback, useEffect } from 'react';

import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { INetwork } from 'types';
import { useTenants } from 'context/tenants';
import { apiUrls } from 'helpers/apiUrls';

/**
 * @description useMakeRequestGetNetworksByClick - a hook that returns a function
 * that makes an API /GET request to get all networks
 * @returns handleGetNetworks
 */

function useMakeRequestGetNetworksByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const url = `${apiUrls.networks}${selectedTenantId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const page = 'networks';
  const fetchMethod = 'get';

  // func
  const handleGetNetworks = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getNetworks = api.useFetchData<[], Array<INetwork>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getNetworks);
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url]);

  return { handleGetNetworks };
};

/**
 * @description useMakeRequestGetNetworksImmediately - a hook that makes an API /GET request
 * to immediately get all networks using useEffect
 * @returns null
 */

function useMakeRequestGetNetworksImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetNetworks } = useMakeRequestGetNetworksByClick();

  // hooks
  useEffect(() => {
    if (selectedTenantId) {
      handleGetNetworks();
    }
    return () => {};
  }, [selectedTenantId, handleGetNetworks]);
};

export {
  useMakeRequestGetNetworksByClick,
  useMakeRequestGetNetworksImmediately,
};
