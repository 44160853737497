import React from 'react';
import { IVm } from 'types';
import { useDashboard } from 'context/dashboard';
import useSelectedItems from 'feature/bulk-operations/delete/useSelectedItems';
import useSelectedOrDeselectedAllRemovingItemsVms from 'hooks/useSelectedOrDeselectedAllRemovingItemsVms';

import InstancesTableComponent from './InstancesTableComponent';

type TProps = {
  isShowCheckbox: boolean,
};

export default function InstancesList(props: TProps) {
  // props
  const {
    isShowCheckbox,
  } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc, selectedInstanceType } = state;
  const { data } = fetchFunc[selectedInstanceType].get;
  const page = selectedInstanceType; //'instances';

  const dataWithCustomValues: IVm[] = data && data.map(
    (item: IVm) => (
      {
        ...item,
        isSelected: item.isSelected || false,
        loading: false,
        statusResponse: 0,
        message: '',
        error: '',
        isDeleted: false,
        timeoutShow: 100,
        timeoutClose: 500,
        id: item.uuid,
      }
    ),
  );

  const vmsWithCorrectStatus = dataWithCustomValues.filter(
    (vm: IVm) =>
      vm.status === 'VM_ACTIVE' ||
      vm.status === 'POWER_OFF' ||
      vm.status === 'REBOOT_REQUESTED' ||
      vm.status === 'RESET_REQUESTED',
  );
  const isAllSelected = vmsWithCorrectStatus.length !== 0 ?
    vmsWithCorrectStatus.every((vm: IVm) => vm.isSelected) : false;

  const handleSelectedAllItems = useSelectedOrDeselectedAllRemovingItemsVms(true, page);
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItemsVms(false, page);

  const handleSelectItems = useSelectedItems(
    dataWithCustomValues,
    page,
  );

  return (
    <InstancesTableComponent
      handleDeselectItems={handleDeselectedAllItems}
      handleSelectedAllItems={handleSelectedAllItems}
      isAllSelected={isAllSelected}
      data={dataWithCustomValues}
      handleSelectItems={handleSelectItems}
      isShowCheckbox={(selectedInstanceType === 'baremetal') ? false : isShowCheckbox}
    />
  );
};
