import { useCallback, useReducer } from 'react';

import { INetwork } from 'types';
import { useCleanState } from 'hooks/useCleanState';
import { useDashboard } from 'context/dashboard';
import { setFetchFunc } from 'actions/dashboard';

import {
  INITIAL_STATE,
  networkReducer,
  OPEN_RENAME_MODAL,
  TOpenModalAction,
} from '../reducers/NetworkReducer';

/**
 * @since 1.0.0
 * @description This is a function that has its own reducer
 * and opens/closes the rename network form
 * @version 0.0.2
 * @package hooks
 * @returns object
 * @example
    const {
      handleOpenRenameModal,
      handleCloseRenameModal,
      state: rename,
    } = useOpenRenameModal();
*/

export default function useOpenRenameModal() {
  // state
  const [state, dispatch] = useReducer(networkReducer, INITIAL_STATE);

  // variables
  const cleanState = useCleanState();
  const context = useDashboard();
  const dispatchDashboard = context[1];

  // func
  function setOpenEditModal(openEditModal: boolean): TOpenModalAction {
    return {
      type: OPEN_RENAME_MODAL,
      openEditModal,
    };
  };

  const handleOpenRenameModal = useCallback((network: INetwork) => {
    cleanState('network', 'patch');
    dispatch(setOpenEditModal(true));
    const newNetwork = {
      uuid: network.uuid,
      network_name: network.network_name,
      prefix6: network.prefix6,
      cluster_uuid: network.cluster_uuid,
      tenant_uuid: network.tenant_uuid,
      assigned_addresses6: network.assigned_addresses6,
    };
    dispatchDashboard(setFetchFunc(newNetwork, 'network', 'get', 'data'));
  }, [cleanState, dispatchDashboard]);

  const handleCloseRenameModal = useCallback(() => {
    dispatch(setOpenEditModal(false));
  }, []);

  return {
    handleOpenRenameModal,
    handleCloseRenameModal,
    state,
  };
};
