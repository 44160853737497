import React from 'react';

import classes from './forms.module.scss';

type TProps = {
  name: string,
  label: string,
};

export default function CustomInputLabel(props: TProps) {
  const { labelStyle } = classes;
  const { name, label } = props;

  return (
    <label htmlFor={name} className={labelStyle}>
      {label}
    </label>
  );
};
