// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { ReactNode, useRef, useState } from 'react';

import { PortalTooltip } from 'portals/Tooltip';

type TProps = {
  /**
   * @default 'test_id_ui_element_with_tooltip'
  */
  dataTestId?: string,

  // required
  /**
   * @default ReactFragment
   */
  element: ReactNode,
  /**
   * @default 'text'
   */
  text: string,
};

type TTargetRect = {
  left: number | null,
  top: number | null,
  right: number | null,
  bottom: number | null,
};

/**
 * @description The UIElementWithTooltip UI component
 * @param props dataTestId, text, element
 * @param text required
 * @param element required
 * @returns div
*/

export default function UIElementWithTooltip(props: TProps) {
  // state
  const [targetRect, setTargetRect] = useState<TTargetRect | null>(null);

  // props
  const {
    dataTestId='test_id_ui_element_with_tooltip',
    text='text',
    element=<></>,
  } = props;

  // hooks
  const buttonRef = useRef(null);

  // func
  const handlePointerEnter = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTargetRect({
        left: rect.left,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
      });
    }
  };

  const handlePointerLeave = () => {
    setTargetRect(null);
  };

  return (
    <>
      <div
        className='row items-center'
        data-testid={dataTestId}
        ref={buttonRef}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        {element}
      </div>
      {targetRect !== null && (
        <PortalTooltip
          targetRect={targetRect}
          text={text}
        />
      )}
    </>
  );
};
