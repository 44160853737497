import React from 'react';

import { useInstance } from 'context/instance';
import Typography from 'components/typography';
import useSelectFlavorTab from 'hooks/useSelectFlavorTab';
import { ITabsItemFlavor, TFlavorTabs } from 'types/index';

import Items from './Items';
import classes  from './flavor.module.scss';

export type TProps = {
  tabs: {
    id: number,
    name: TFlavorTabs,
    items: Array<ITabsItemFlavor>,
  }[],
};

export default function Tabs(props: TProps) {
  // props
  const { tabs } = props;

  // variables
  const context = useInstance();
  const state = context[0];
  const { handleSelectedFlavorTab } = useSelectFlavorTab();
  const { tabsTitleClass } = classes;
  const getFlavors = tabs?.filter(item => item.name === state.tab)[0];

  return (
    <>
      <div className='flex row w-full border-b border-customBgLightGray'>
        {tabs?.map((element) => {
          const { name } = element;
          return (
            <div
              key={name}
              onClick={
                () => handleSelectedFlavorTab(name, element?.items[0])
              }
              className='cursor-pointer'
            >
              <Typography
                customStyle={`
                  ${name === state.tab ? tabsTitleClass : 'w-[178px]'}
                  all-items-center
                `}
                text={name}
                fontSize='sm'
              />
            </div>
          );
        })}
      </div>
      <Items items={getFlavors?.items} />
    </>
  );
};
