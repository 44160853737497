import { useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import Subheader from 'components/Subheader';
// import InstancesSection from 'feature/overview/sections/instances';
import NetworksSection from 'feature/overview/sections/networks';
import Ipv4sSection from 'feature/overview/sections/Ipv4s';
import SshKeysSection from 'feature/overview/sections/SshKeys';
import DocsSection from 'feature/overview/sections/Docs';
import { useDashboard } from 'context/dashboard';
import useGetMetrics from 'hooks/useGetMetrics';
import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import useLogout from 'hooks/useLogout';
import Typography from 'components/typography';
import { showTwoButtons } from 'feature/overview/sections/instances/emptyBlock';

import classes from '../../feature/overview/overview.module.scss';
import glossary from '../../data/glossary.json';

/**
 * @description OverviewComponent
 * @returns
 */

export default function OverviewComponent() {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { metrics: { instances, networks, ipv4s, ssh_keys } } = stateDashboard;
  const { loading, error, status } = useGetMetrics();
  const { handleLogout } = useLogout();

  // style
  const { overviewGridTemplateClass } = classes;

  // func
  useEffect(() => {
    document.title = `${glossary.name} | ${glossary.overview}`;
  }, []);

  if (status === 403) {
    handleLogout();
    return <Info status='error' msg={error} />;
  };

  if (error) {
    return <Info status='error' msg={error} />;
  }

  if (loading) {
    return <Spinner />;
  };

  return (
    <>
      <Subheader
        title={glossary.descriptions.welcome_to_our_taiga}
        buttonTitle=''
        hideButton={true}
        onClick = {() => {}}
        dataTestId='test_id_overview_title'
        // @ts-ignore
        dataTestIds={{}}
        description={glossary.descriptions.lets_get_start}
      />
      {(instances > 0 || networks > 0 || ipv4s > 0 || ssh_keys > 0) ?
        <div className={`${overviewGridTemplateClass}`}>
          {/* <InstancesSection /> */}
          <Ipv4sSection />
          <NetworksSection />
          <DocsSection />
          <SshKeysSection />
        </div>
        :
        <div className='col py-6 rounded-md bg-[#FBFCFE] border border-customColorLightGray items-center justify-center'>
          <ExclamationCircleIcon
            className='stroke-[#02061D33] w-12 h-12 m-4'
          />
          <Typography
            text={glossary.empty_state.you_dont_have_any_resources_yet}
            customStyle='mb-8'
            fontSize='xs'
            fontWeight='normal'
          />
          {showTwoButtons()}
        </div>
      }
    </>
  );
};
