import React, { useEffect, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import Button from 'components/buttons';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import ContentViewMultyItems from 'feature/bulk-operations/delete/ContentViewMultyItems';
import useCreateErrorsObjects from 'feature/bulk-operations/delete/useCreateErrorsObjects';
import useCreateSuccesObjects from 'feature/bulk-operations/delete/useCreateSuccesObjects';
import { TActionsDependsOfStatus, TErrorNotifications, TSuccessNotifications } from 'types';
import useUpdateStatusVmsItems from 'feature/bulk-operations/delete/useUpdateStatusVmsItems';
import { setPreselectedRemovingItems } from 'actions/dashboard';
import getVMsStatus from 'helpers/getStyleVMStatus';

import useCorrectNameForBtn from '../../hooks/useCorrectNameForBtn';
import glossary from '../../data/glossary.json';

type TProps = {
  page: 'ips' | 'sshKeys' | 'virtualmachine' | 'networks' | 'instances' | 'baremetal',
};

export default function VirtualMachinesActions(props: TProps) {
  // state
  const [openPortalModalWindowUpdateStatusVms, setOpenPortalModalWindowUpdateStatusVms] = useState<boolean>(false);
  const [updateStatusOfVm, setUpdateStatusOfVm] = useState<TActionsDependsOfStatus>('VM_ACTIVE');
  const [successNotifications, setSuccessNotifications] = useState<TSuccessNotifications[]>([]);
  const [errorNotifications, setErrorNotifications] = useState<TErrorNotifications[]>([]);

  // props
  const { page } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];

  const { fetchFunc, preselectedRemovingItems, instanceName } = state;
  const { loading: updateLoading } = fetchFunc[page].post;
  const { data } = fetchFunc[page].get;
  const newErrorsObject = useCreateErrorsObjects(errorNotifications);
  const newSuccessObject = useCreateSuccesObjects(successNotifications);
  const howManyItemsSelected = preselectedRemovingItems[page].uuid.length;
  const isPageOnlyTypeInstances: 'instances' | 'virtualmachine' | 'baremetal'  = (page === 'instances' || page === 'virtualmachine' || page === 'baremetal') ? page : 'virtualmachine';
  const { nameForActionBtn } = useCorrectNameForBtn(page, data);
  const hideBtnsUpdate = updateLoading || (newErrorsObject.length !== 0 || newSuccessObject.length !== 0);
  const isPluralFormName = howManyItemsSelected === 1 ? glossary.virtual_machine : glossary.virtual_machines_plural;
  const handleUpdateStatusVms = useUpdateStatusVmsItems(
    setSuccessNotifications,
    setErrorNotifications,
    isPageOnlyTypeInstances,
    'post',
    updateStatusOfVm,
  );

  // func
  useEffect(() => {
    return () => {
      dispatch(setPreselectedRemovingItems(page, 'uuid', []));
      dispatch(setPreselectedRemovingItems(page, 'name', []));
    };
  }, [dispatch, page]);

  const handleItemsModalUpdateVms = (action: TActionsDependsOfStatus) => {
    setErrorNotifications([]);
    setSuccessNotifications([]);
    setUpdateStatusOfVm(action);
    setOpenPortalModalWindowUpdateStatusVms(true);
  };

  const handleCloseItemsModalUpdateVms = () => {
    setOpenPortalModalWindowUpdateStatusVms(false);
  };

  return (
    <>
      <Button
        uiStyle='gray'
        handleClick={() => handleItemsModalUpdateVms('POWER_OFF')}
        disabled={howManyItemsSelected=== 0 ? true : false}
        customStyle='mr-1'
        size='small'
      >
        Power off {nameForActionBtn}
      </Button>
      <Button
        uiStyle='gray'
        handleClick={() => handleItemsModalUpdateVms('VM_ACTIVE')}
        disabled={howManyItemsSelected=== 0 ? true : false}
        customStyle='mr-1'
        size='small'
      >
        Power on {nameForActionBtn}
      </Button>
      <Button
        uiStyle='gray'
        handleClick={() => handleItemsModalUpdateVms('REBOOT_REQUESTED')}
        disabled={howManyItemsSelected=== 0 ? true : false}
        customStyle='mr-1'
        size='small'
      >
        Reboot {nameForActionBtn}
      </Button>
      <Button
        uiStyle='gray'
        handleClick={() => handleItemsModalUpdateVms('RESET_REQUESTED')}
        disabled={howManyItemsSelected=== 0 ? true : false}
        customStyle='mr-1'
        size='small'
      >
        Reset {nameForActionBtn}
      </Button>
      {openPortalModalWindowUpdateStatusVms &&
        <PortalModal
          open={openPortalModalWindowUpdateStatusVms}
          onCancel={handleCloseItemsModalUpdateVms}
        >
          <FormModalTemplate
            handleSubmitButtonName={getVMsStatus(updateStatusOfVm).action}
            onCancel={handleCloseItemsModalUpdateVms}
            handleSubmit={handleUpdateStatusVms}
            hideBtns={hideBtnsUpdate}
            redBtn={true}
            header={`${getVMsStatus(updateStatusOfVm).action} ${isPluralFormName}`}
          >
            <ContentViewMultyItems
              actionName={updateStatusOfVm}
              itemsName={instanceName}
              fetchMethod='post'
              page={page}
              numberOfItems={howManyItemsSelected || 0}
              errorsObject={newErrorsObject}
              successObject={newSuccessObject}
            />
          </FormModalTemplate>
        </PortalModal>
      }
    </>
  );
};
