import React, { useState } from 'react';
import { TImage } from 'types';

import Item from './Item';

const Items = ({imageGroups}: {imageGroups: TImage[]}) => {
  // state
  const [selectedImageGroupId, setSelectedImageGroupId] = useState<number>(0);

  // variables
  // const { handleSelectedImage, handleSelectedImageGroup } = useSelectImage();

  const handleChooseImageGroupId = (id: number) => {
    // handleSelectedImage(images[0])
    // handleSelectedImageGroup(id);
    setSelectedImageGroupId(id);
  };

  return (
    <div className='row flex-wrap'>
      {imageGroups?.map(imageGroup => (
        <Item
          key={imageGroup.id}
          imageGroup={imageGroup}
          selectedImageGroupId={selectedImageGroupId}
          handleChooseImageGroupId={handleChooseImageGroupId}
        />
      ))}
    </div>
  );
};

export default Items;
