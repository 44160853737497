import { getCache } from 'helpers/cacheable';
import dataClusters from 'data/publicClusters.json';

/**
 * @description useGetListOfClusters
 * @returns
 */

type TElement = {
  uuid: string,
  name: string,
  value?: number,
  location?: {
    city: string,
    country: string,
    region: string,
    full_location: string,
  }
};

export default function getListOfClusters() {
  // valiables
  const allClusters: TElement[] = getCache('cache-c').clusters || [];
  const isUnderground = allClusters.some((x: TElement) => x.uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5');
  const isArcticcircle = allClusters.some((x: TElement) => x.uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7');

  function isNotUnderground() {
    if(!isUnderground){
      return allClusters.push(dataClusters[0]);
    };

    return null;
  };

  function isNotArcticcircle() {
    if(!isArcticcircle){
      return allClusters.push(dataClusters[1]);
    };

    return null;
  };

  isNotUnderground();
  isNotArcticcircle();

  for (var i = 0; i < allClusters.length; i++) {
    delete allClusters[i].value;
  };

  return allClusters;
};
