import React, { useState, lazy, Suspense } from 'react';

import Button from 'components/buttons';
import { useCleanState } from 'hooks/useCleanState';
import { SpinnerLazyLoading } from 'components/spinners/SpinnerLazyLoading';

import glossary from '../../../../data/glossary.json';

const AttachIPv4 = lazy(() => import('../../attachIPv4/AttachIPv4'));

/**
 * @description The AttachIpv4Btn component.
 * @returns React Fragment
*/

export default function AttachIpv4Btn() {
  // state
  const [openModalAttachIPv4, setOpenModalAttachIPv4] = useState<boolean>(false);

  // hooks
  const cleanState = useCleanState();

  // func
  const handleOpenAttachIpv4ModalForm = () => {
    cleanState('ips', 'patch');
    setOpenModalAttachIPv4(true);
  };

  const handleClose = () => {
    setOpenModalAttachIPv4(false);
  };

  return (
    <>
      <Button
        type='button'
        uiStyle='primary'
        handleClick={handleOpenAttachIpv4ModalForm}
        customStyle={'mt-[1.56rem]'}
        title={`Attach ${glossary.ipv4}`}
      />
      {openModalAttachIPv4 &&
        <Suspense fallback={<SpinnerLazyLoading />}>
          <AttachIPv4
            open={openModalAttachIPv4}
            onClose={handleClose}
          />
        </Suspense>
      }
    </>
  );
};
