import { ReactNode, useRef } from 'react';
// import { Suspense, lazy } from 'react';
// import { Redirect } from 'react-router-dom';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';
import { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
// import { SpinnerLazyLoading } from 'components/spinners/SpinnerLazyLoading';
import useOnClickOutside from 'hooks/useClickOutside';
import ActionItem from 'feature/actions/ActionItem';

import classes from './index.module.scss';

// const MenuSection = lazy(() => import('./MenuSection'));

type IMenu = {
  uuid: string,
  lastElement: boolean,
  children?: ReactNode,
  hideLeftMargin?: boolean,
  actions: TActionsMenuComponent[],
  url: string,
  route: string,
  tooltip?: boolean,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  itemName: string,
};

export default function MenuComponent(props: IMenu) {
  // props
  const { uuid, lastElement, children, hideLeftMargin, actions, url, route, page, fetchMethod, itemName } = props;

  const ref = useRef(null);

  const isHideLeftMargin = hideLeftMargin ? '' : 'ml-3';

  const { menuSummaryClass, menuContentClass } = classes;

  const handleClickOutside = () => {
    const el = document.getElementById(`details-${uuid}`) as HTMLDetailsElement;
    el.open = false;
  };

  useOnClickOutside(ref, handleClickOutside);

  const returnActions = (action: TActionsMenuComponent) => {
    if (actions.includes(action)) {
      return(
        <ActionItem
          uuid={uuid}
          url={url}
          key={action}
          route={route}
          page={page}
          fetchMethod={action === 'DELETED' ? 'delete' : fetchMethod}
          actionName={action}
          itemName={itemName}
          typeAction='menu-action'
        />
      );
    }
  };

  return (
    <details
      ref={ref}
      id={`details-${uuid}`}
      data-testid={`action-menu-${uuid}`}
      className={`relative ${isHideLeftMargin} inline-block text-left`}
    >
      <summary>
        <div className={menuSummaryClass}>
          <EllipsisVerticalIcon
            className='h-5 w-5 fill-customColorDarkBlue'
            aria-hidden='true'
          />
        </div>
      </summary>
      {/* <Suspense fallback={<SpinnerLazyLoading />}> */}
      <menu
        onMouseLeave={handleClickOutside}
        className={`${lastElement ? 'bottom-10' : 'top-10'} ${menuContentClass} table-body-cell`}
      >
        {children}
        {actions.map((action: TActionsMenuComponent) => returnActions(action))}
      </menu>
      {/* </Suspense> */}
    </details>
  );
};
