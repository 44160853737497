import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { useInstance } from 'context/instance';
import { ITabsItemFlavor } from 'types';
import { setTab } from 'actions/instance';

import classes from './flavor.module.scss';
import Item from './Item';

type TProps = {
  items: ITabsItemFlavor[],
};

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
};

const headData = ['', 'Storage size', 'Core', 'RAM'];
const headDataNswitch = ['', 'Switches', 'Storage size', 'Core', 'RAM'];

const Items = ({items}: TProps) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [tableHead, setTableHead] = useState<Array<string>>(headData);

  const context = useInstance();
  const dispatch = context[1];
  const state = context[0];
  const { instance: { location }, tab } = state;


  const handleSelectedNvswitch = () => {
    setEnabled(!enabled);
    if (enabled) {
      setTableHead(headData);
      dispatch(setTab('NVIDIA A100'));
    } else {
      setTableHead(headDataNswitch);
      dispatch(setTab('NVIDIA A100 NVSWICH'));
    }
  };

  const theadClass = 'text-s font-medium text-customTextBlack2withOpacity7 text-center';
  const isVisibleSwitches = (tab === 'NVIDIA A100 NVSWICH' || tab === 'NVIDIA RTX A6000 NVSWICH');

  return (
    <div className='column'>
      <div className='row justify-end pt-[10px] pb-[25px]'>
        <p className='text-xs mr-[12px] font-medium'>
          NV switch option
        </p>
        <Switch
          checked={enabled}
          disabled={location === 'Sweden' ? false : true}
          onChange={handleSelectedNvswitch}
          className={classNames(
            classes.switchClass,
            enabled ? 'bg-customColorLightGreen' : 'bg-customColorLightGray ',
          )}
          data-testid='nv_switch_option'
        >
          <span className='sr-only'>Use setting</span>
          <span
            aria-hidden='true'
            className={classNames(
              enabled ? 'translate-x-[9px]' : 'translate-x-0',
              location !== 'Sweden' ? 'bg-[#F5F5F5]': 'bg-white',
            )}
          />
        </Switch>
      </div>
      <table className={classes.tableClass}>
        <thead>
          <tr>
            {tableHead.map((item, index) =>(
              <th
                key={index}
                scope='col'
                className={`${theadClass} ${item === 'Switches' && !isVisibleSwitches ? 'invisible' : ''}`}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items?.map((item: ITabsItemFlavor, index: number) => {
            return <Item key={index} item={item} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Items;
