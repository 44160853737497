import { useDashboard } from 'context/dashboard';
import { IIpv4, INetwork, ISshKey } from 'types';
import { IVm } from 'types';

type TData = (IVm | IIpv4 | INetwork | ISshKey)[];

export default function useCorrectNameForBtn(page: 'ips' | 'virtualmachine' | 'instances' | 'sshKeys' | 'networks' | 'baremetal', data: TData): {isAllSelected: boolean, nameForActionBtn: string | ''} {
  const context = useDashboard();
  const state = context[0];
  const { preselectedRemovingItems } = state;

  const howManyItemsSelected = preselectedRemovingItems[page].uuid.length;

  const isAllSelected = data.every((item: IVm | ISshKey | INetwork | IIpv4) => item.isSelected);
  // const nameForActionBtn = `${howManyItemsSelected === 0 ? '' : howManyItemsSelected} item${howManyItemsSelected > 1 ? 's' : ''}`;
  const nameForActionBtn = `${howManyItemsSelected === 0 ? '' : `(${howManyItemsSelected})`}`;

  return {isAllSelected, nameForActionBtn};
};
