import { INotification, TSuccessNotifications } from 'types';

export default function useCreateSuccesObjects(object: TSuccessNotifications[]) {

  const newSuccesArray: INotification[] = object.map((item: TSuccessNotifications) => ({
    ...item,
    uuid: '',
    type: 'success',
    code: item.code,
    message: item.message,
    timeoutShow: 500,
    timeoutClose: 1500,
  }));

  return newSuccesArray;
};
