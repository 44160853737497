import React from 'react';

import LINKS from './Links';
import classes from './Navbar.module.scss';

export default function LinksComponent() {

  const {
    externalLinksClass,
    navIconLinkClass,
    navlinkClass,
  } = classes;

  return (
    <div className={externalLinksClass}>
      {LINKS.map(link => (
        <div className={navlinkClass} key={link.title}>
          <div className={navIconLinkClass}>
            <img src={link.icon} alt={link.title} />
          </div>
          <a
            rel='noreferrer'
            target='_blank'
            href={link.href}
          >
            {link.title}
          </a>
        </div>
      ))}
    </div>
  );
};
