import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Typography from 'components/typography';
import Button from 'components/buttons';
import { useDashboard } from 'context/dashboard';
import Spinner from 'components/spinners/Spinner';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
// import { useGetSshKeys } from 'hooks/useGetSshKeys';

import classes from '../overview.module.scss';

import glossary from '../../../data/glossary.json';

/**
 * @description SshKeysSection is for overview page, just show a total number of ssh keys for selected tenant
 * @todo show a real number, ask about black color
 * @returns
 */

export default function SshKeysSection() {
  //state
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  // useGetSshKeys();

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const {
    metrics: { ssh_keys },
    // fetchFunc: { sshKeys: { get: { data: ssh_keys } } },
  } = stateDashboard;
  const { handlePagePaths } = useGetRoutesPagePath();
  const history = useHistory();

  // style
  const { grayWrapperSectionClass } = classes;

  // func
  const handleNavigation = () => {
    return history.push(handlePagePaths().newSshKey);
  };

  return (
    <div className={`row ${grayWrapperSectionClass} pl-6`}>
      <Typography
        text={glossary.ssh_keys_plural}
        fontSize='xxs'
      />
      {loading ?
        <Spinner spaceAroundSpinner />
        :
        <>
          {ssh_keys === 0 ?
            <Button
              title={`${glossary.actions.create} ${glossary.ssh_key}`}
              uiStyle='primary'
              handleClick={handleNavigation}
              customStyle='mr-6'
              size='small'
            />
            :
            <Typography
              text={ssh_keys || 0}
              color='black'
              fontSize='xxs'
              customStyle='pr-12'
            />
          }
        </>
      }
    </div>
  );
};
