import React, { ReactNode }  from 'react';

import ChildrenSection from './components/ChildrenSection';
import Header from './components/Header';

type TProps = {
  children: ReactNode;
  title: string;
  customStyle?: string;
  dataTestIds?: {};
};

/**
 * @description The Aside component is a grey component,
 * accepts a {title} that will be shown in the header and
 * {children} that will be shown in the body section
 * and can also be customized in appearance.
 *
 * @used by Network page => ips section,
 *
 * @param props children, title, customStyle
 * @param children required
 * @param title - required
 * @param dataTestIds - required
 *
 * @returns Aside with its own custom header and custom elements (children) in the section
*/

export default function Aside(props: TProps) {
  const {
    children=<></>,
    title='Title',
    customStyle='',
    // dataTestIds={},
  } = props;

  return (
    <aside
      // data-testid={dataTestIds.name}
      className={customStyle}
    >
      <Header
        // dataTestIds={dataTestIds.header}
        title= {title}
      />
      <ChildrenSection
        // dataTestIds={dataTestIds.children}
        children={children}
      />
    </aside>
  );
};
