import React  from 'react';

import Typography from 'components/typography';

import classes from '../index.module.scss';

type TProps = {
  title: string;
  dataTestIds?: {
    name: string,
    title: string,
  };
};

/**
 * @description The Header component is
 *
 * @used by Aside
 *
 * @param props title, customStyle
 * @param title - required
 * @param dataTestIds - required
 *
 * @returns div
*/

export default function Header(props: TProps) {
  const {
    title='Title',
    dataTestIds={
      name: 'header_name',
      title: 'header_title',
    },
  } = props;

  const {
    asideHeaderClass,
  } = classes;

  return (
    <div
      data-testid={dataTestIds.name}
      className={asideHeaderClass}
    >
      <Typography
        text={title}
        fontWeight='medium'
        color='customColorDarkBlue80'
        fontSize='s'
        dataTestId={dataTestIds.title}
      />
    </div>
  );
};
