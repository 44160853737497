import React, { ChangeEvent } from 'react';

import classes from './forms.module.scss';
import CustomInputLabel from './Label';

import Info from '../Info';

type TProps = {
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
  name: string,
  textareaError: string,
  placeholder: string,
  label: string,
  dataTestId?: string,
};

export default function CustomTextarea(props: TProps) {
  const { customTextareaClass, validationClass } = classes;
  const {
    handleChange,
    name,
    textareaError,
    placeholder,
    label,
    dataTestId,
  } = props;

  const textareaView = () => {
    if (textareaError) return `${validationClass} ${customTextareaClass} placeholder-customColorDarkBlue`;
    return customTextareaClass;
  };

  return (
    <>
      <CustomInputLabel
        name={name}
        label={label}
      />
      <textarea
        data-testid={dataTestId}
        name={name}
        id={name}
        rows={3}
        className={`${textareaView()}`}
        placeholder={placeholder}
        defaultValue=''
        onChange={handleChange}
      />
      {textareaError && <Info status='validation' msg={textareaError} />}
    </>
  );
};
