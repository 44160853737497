import React, { useReducer, useRef }  from 'react';

import Flavor from 'feature/new-instance/flavor/Flavor';
import Images from 'feature/new-instance/images/Images';
import Summary from 'feature/new-instance/summary/Summary';
import InstanceName from 'feature/new-instance/Name';
import Authentication from 'feature/new-instance/keys/Authentication';
import Network from 'feature/new-instance/network/Network';
import Ips from 'feature/new-instance/ips/Ips';
import LocationSection from 'feature/new-instance/location-section/LocationSection';
import { initialState, reducer } from 'reducers/instance';
import { InstanceContext } from 'context/instance';
import HeaderWithBreadcrumbs from 'components/HeaderWithBreadcrumbs';
import PortalNotification from 'portals/Notification';
import { useDashboard } from 'context/dashboard';
import SuccesAlert from 'feature/new-instance/summary/SuccesAlert';
import LoadingAlert from 'feature/new-instance/summary/LoadingAlert';
import Aside from 'components/aside';
import { dataTestIdsNewVm } from 'data-test-ids';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import classes from './create-new-vm.module.scss';
import ConfirmationDialog from './ConfirmationDialog';

import glossary from '../../data/glossary.json';

/**
 * @description The CreateInstance component.
 * @returns div
*/

export default function CreateInstance() {
  // hooks
  const [state, dispatch] = useReducer(reducer, initialState);

  const nameRef = useRef<HTMLInputElement | null>(null);
  const flavorsRef = useRef<HTMLDivElement | null>(null);
  const imagesRef = useRef<HTMLDivElement | null>(null);
  const sshKeysRef = useRef<HTMLDetailsElement | null>(null);
  const networksRef = useRef<HTMLDetailsElement | null>(null);
  const contextDashboard = useDashboard();

  // variables
  const { asideSummaryClass } = classes;
  const stateDashboard = contextDashboard[0];
  const { fetchFunc, instanceName } = stateDashboard;
  const { loading, message } = fetchFunc.instance.post;
  const { handlePagePaths } = useGetRoutesPagePath();

  return (
    <>
      <InstanceContext.Provider value={[state, dispatch, '']}>
        <div className='flex-1 col pb-10'>
          <div className='flex flex-1 items-stretch pb-14'>
            <main className='flex-1'>
              <div className='mt-5 md:mt-0 md:col-span-2'>
                <div className='grid grid-cols-6 gap-14'>
                  <div className='col-span-6'>
                    <HeaderWithBreadcrumbs
                      headerTitle={`Create ${instanceName}`}
                      route={handlePagePaths().instances}
                      routeName={`Back to ${glossary.instances_plural}`}
                    />
                  </div>
                  <InstanceName ref={nameRef}/>
                  <LocationSection />
                  <Flavor ref={flavorsRef}/>
                  <Images ref={imagesRef}/>
                  <Network
                    ref={networksRef}
                    dataTestIds={dataTestIdsNewVm.newVm.main.network}
                  />
                  <Authentication
                    ref={sshKeysRef}
                    dataTestIds={dataTestIdsNewVm.newVm.main.authentication}
                  />
                  <Ips
                    dataTestIds={dataTestIdsNewVm.newVm.main.ips}
                  />
                  {/* <Startup /> */}
                </div>
              </div>
            </main>
            <Aside
              title={instanceName}
              customStyle={`${asideSummaryClass} top-0 h-1/2 ml-10 mt-[22px]`}
            >
              <Summary
                nameRef={nameRef}
                flavorsRef={flavorsRef}
                imagesRef={imagesRef}
                sshKeysRef={sshKeysRef}
                networksRef={networksRef}
              />
            </Aside>
          </div>
          {fetchFunc.network.post.message &&
            <PortalNotification
              text='success'
              isOneNitification={true}
            />
          }
          {fetchFunc.sshKey.post.message &&
            <PortalNotification
              text='success'
              isOneNitification={true}
            />
          }
        </div>
      </InstanceContext.Provider>
      {fetchFunc.instance.post.message === '' && <ConfirmationDialog />}
      {loading && <LoadingAlert loading={loading} />}
      {message && <SuccesAlert />}
    </>
  );
};
