import { Link } from 'react-router-dom';

import Button from 'components/buttons';

import glossary from '../../../../data/glossary.json';

export default function LinkOderBareMetal(){
  // variables
  const redirectTo = 'https://northerndata.atlassian.net/servicedesk/customer/portal/12/group/82/create/286';
  const btnTitleBm = `Order ${glossary.bare_metal}`;

  return (
    <Button
      title=''
      uiStyle='primary'
      handleClick={() => {}}
      customStyle='min-w-[155px]'
      size='small'
    >
      <Link
        to={{ pathname: redirectTo }}
        target='_blank'
      >
        {btnTitleBm}
      </Link>
    </Button>
  );
};
