import React, { useState } from 'react';

import Typography from 'components/typography';
import { useTenants } from 'context/tenants';
import CopyComponent from 'feature/copy-func';
import CustomSelect from 'components/input/Select';
import { getCache } from 'helpers/cacheable';
import Button from 'components/buttons';
import useGetImages from 'hooks/useGetImages';
import { useGetFlavors } from 'hooks/useGetFlavors';
import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import glossary from 'data/glossary.json';
import useGetMetricsNetworks from 'hooks/useGetMetricsNetworks';
import getListOfClusters from 'helpers/getListOfClusters';

/**
 * @description Uuids section
 * @returns div
*/

export default function UuidsSection() {
  // state
  const [selectedUuids, setSelectedUuids] = useState<string>('Tenants');

  // variables
  const contextTenants = useTenants();
  const state = contextTenants[0];
  const { tenants } = state;
  const images = getCache('cache-i');
  const flavors = getCache('cache-f');
  const clusters = getListOfClusters();
  const test = [
    {
      uuid: '0',
      customer_name: glossary.images_plural,
      username: '',
    },
    {
      uuid: '1',
      customer_name: glossary.flavors_plural,
      username: '',
    },
    {
      uuid: '2',
      customer_name: glossary.tenants_plural,
      username: '',
    },
    {
      uuid: '3',
      customer_name: glossary.clusters_plural,
      username: '',
    },
  ];

  // hooks
  const { loading: loadingImages, error: errorImages } = useGetImages();
  const { loading: loadingFlavors, error: errorFlavors } = useGetFlavors();
  const { loading: loadingClusters, error: errorClusters } = useGetMetricsNetworks();

  // func
  const handleChange = (value: string, uuid: string) => {
    setSelectedUuids(value);
  };

  const returnUuids = () => {
    if (selectedUuids === 'Images') return [JSON.stringify(images.display_fe, null, 2), images.display_fe];
    if (selectedUuids === 'Flavors') return [JSON.stringify(flavors.display_fe, null, 2), flavors.display_fe];
    if (selectedUuids === 'Clusters') return [JSON.stringify(clusters, null, 2), clusters];
    return [JSON.stringify(tenants.data, null, 2), tenants.data];
  };

  const handleTextFile = () => {
    const element = document.createElement('a');
    //pass data from localStorage API to blob
    const textFile = new Blob([JSON.stringify(returnUuids()[1])], {type: 'application/json'});
    element.href = URL.createObjectURL(textFile);
    element.download = `${selectedUuids}_UUID.json`;
    document.body.appendChild(element);
    element.click();
  };

  const renderContent = () => {
    if (selectedUuids === 'Images' && loadingImages){
      return <Spinner />;
    };

    if (selectedUuids === 'Flavors' && loadingFlavors){
      return <Spinner />;
    };

    if (selectedUuids === 'Clusters' && loadingClusters){
      return <Spinner />;
    };

    if (selectedUuids === 'Images' && errorImages && !loadingImages) {
      return (
        <Info
          status='error'
          msg={errorImages}
        />
      );
    };

    if (selectedUuids === 'Flavors' && errorFlavors && !loadingFlavors) {
      return (
        <Info
          status='error'
          msg={errorFlavors}
        />
      );
    };

    if (selectedUuids === 'Clusters' && errorClusters && !loadingClusters) {
      return (
        <Info
          status='error'
          msg={errorFlavors}
        />
      );
    };

    return (
      <>
        <div className='font-normal text-s text-customColorDarkBlue80 overflow-x-auto max-w-[45rem]'>
          {returnUuids()[0]}
        </div>
        <div className='absolute top-10 right-10 '>
          <CopyComponent
            value={returnUuids()[0]}
            dataTestId='test_id_selected_uuid'
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <Typography
        text='UUIDs'
        customStyle='pb-1'
        fontSize='sm'
      />
      <hr />
      <div className='pt-8 pb-14'>
        <Typography
          text={glossary.descriptions.uuids}
          customStyle='mb-4 ml-2'
          fontSize='s'
          color='customColorDarkBlue80'
          fontWeight='normal'
        />
        <div className='row justify-between mb-8'>
          <CustomSelect
            handleChange={handleChange}
            name='uuids'
            label='uuids'
            value={selectedUuids}
            isShowLabel={false}
            options={test}
            optionValue='customer_name'
            id='uuids'
            classNames='min-w-[16.5rem]'
            disabled={false}
            dataTestId='test-id-main-header-selected-items-uuids'
          />
        </div>
        <div className='row'>
          <pre className='flex-1 p-10 border rounded-md bg-slate-50 relative'>
            {renderContent()}
          </pre>
          <Button
            uiStyle='primary'
            handleClick={handleTextFile}
            title='Download'
            customStyle='ml-8 min-w-[12.75rem]'
          />
        </div>
      </div>
    </div>
  );
};
