import React from 'react';

import { useDashboard } from 'context/dashboard';
import Aside from 'components/aside';
import { TDataTestIdsOverviewPageContentMainInfoAside } from 'data-test-ids/types/overview-page';

import Items from './Items';
import NoVms from './NoVms';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainInfoAside,
};

/**
  * @description The VmsSection
  * @augments Network_page
  * @augments Content
  * @augments Main_section
  *
  *
  * @param props dataTestIds
  * @param dataTestIds required
  * @returns Aside component
*/

export default function VmsSection(props: TProps) {
  // props
  const { dataTestIds } = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;

  return (
    <Aside
      title='Attached to Instances'
      dataTestIds={dataTestIds}
    >
      {data.assigned_addresses6?.length === 0 ?
        <NoVms dataTestIds={dataTestIds.children.noVms}/>
        :
        <Items dataTestIds={dataTestIds.children.items} />
      }
    </Aside>
  );
};
