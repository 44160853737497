import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';

import { TDataTestIdsOverviewPageContentHeader } from 'data-test-ids/types/overview-page';

import Typography from './typography';

type TProps = {
  /**
   * @description route - where the user will be redirected to
   */
  route: string,
  /**
   * @description the name of the route that the user clicks on to be redirected
   */
  routeName: string,
  headerTitle?: string,
  dataTestIds?: TDataTestIdsOverviewPageContentHeader,
};

/**
 * @description The HeaderWithBreadcrumbs component
 * @augments Network_page
 *
 * @param props route, pathName, headerTitle, dataTestIds
 * @param route required
 * @param pathName required
 * @param headerTitle required
 * @param dataTestIds required
 *
 * @returns div
*/

export default function HeaderWithBreadcrumbs(props: TProps) {
  // props
  const {
    route,
    routeName,
    headerTitle,
    dataTestIds={
      name: 'header_with_breadcrumbs_ui_component_name',
      title: 'header_with_breadcrumbs_ui_component_title',
    },
  } = props;

  // variables
  const styleTitle = 'mt-2 tracking-[-0.21px] leading-normal';

  return (
    <div
      data-testid={dataTestIds.name}
      className='mt-7'
    >
      <nav className='row items-center'>
        <ChevronLeftIcon className='w-3'/>
        <NavLink exact to={route}>
          <Typography
            text={routeName}
            fontSize='xxs'
            fontWeight='thin'
            customStyle='cursor-pointer'
            color='customColorDarkBlue80'
          />
        </NavLink>
      </nav>
      {headerTitle &&
        <Typography
          text={headerTitle}
          dataTestId={dataTestIds.title}
          fontWeight='medium'
          customStyle={styleTitle}
        />
      }
    </div>
  );
};
