import { useEffect, useState } from 'react';

import { api } from 'helpers/api';
import { useTenants } from 'context/tenants';
import { useDashboard } from 'context/dashboard';
import { apiUrls } from 'helpers/apiUrls';
import { IIpv4 } from 'types/index';

/**
 * @description useGetMetricsIpv4s - a function that makes a request
 * for the number of ipv4s and returns the correct status of the request
 * @returns error, loading, status, data
 */

export default function useGetMetricsIpv4s() {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | unknown>(0);
  const [data, setData] = useState<any>({count: 0, clusters: []});

  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  // const url = `${apiUrls.metrics}${selectedTenantId}/ipv4s`;
  const url = `${apiUrls.ipv4s}${selectedTenantId}`;
  const context = useDashboard();
  const dispatch = context[1];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (selectedTenantId) {
      async function loadData() {
        const request = api.useFetchData<[], IIpv4[]>(url, 'get', signal);
        try {
          setLoading(true);
          const getMetrics = await request;
          const attached = getMetrics.filter((item: IIpv4) => item.vm_uuid);
          const noAttached = getMetrics.filter((item: IIpv4) => item.vm_uuid === null);

          const metrics = {
            count: getMetrics.length,
            unattached: noAttached.length,
            attached: attached.length,
          };
          setData(metrics);
          setError('');
        }
        catch (error) {
          if (error instanceof Error) {
            setStatus(error?.cause);
            setError(error?.message || '');
          }
          setData([]);
        }
        finally {
          setLoading(false);
        }
      }

      loadData();
    }

    return () => {
      controller.abort();
      setData([]);
    };
  }, [url, dispatch, selectedTenantId]);

  return { error, loading, status, data };
};
