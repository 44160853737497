import React, { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';

import { useInstance } from 'context/instance';
import { setInstance, setInstanceValidation } from 'actions/instance';
import CustomInput from 'components/input/Input';
import { useTenants } from 'context/tenants';

import classes from './instance.module.scss';

import glossary from '../../data/glossary.json';

const InstanceName = forwardRef<HTMLInputElement>((props, ref) => {
  // state
  const [isWarning, setIsWarning] = useState<boolean>(false);

  // variables
  const context = useInstance();
  const state = context[0];
  const dispatch = context[1];
  const validationStyle = context[2];
  const { instance: { name }, validation: { name: validationName } } = state;
  const { warningClass } = classes ;
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  useEffect(() => {
    if(!name.trim()) {
      dispatch(setInstanceValidation('', 'name'));
      dispatch(setInstance('', 'name'));
      dispatch(setInstance(glossary.notifications.errors.please_enter_name, 'error'));
    } else {
      dispatch(setInstanceValidation(false, 'name'));
    }
  }, [name, dispatch]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatch(setInstance('', 'name'));
      dispatch(setInstanceValidation('', 'name'));
    }
  }, [selectedTenantId, dispatch]);

  useEffect(() => {
    if (name.length === 64) {
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
  }, [name]);

  // func
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement> ) => {
    const value = event.target.value;
    if (value === ' ') {
      dispatch(setInstanceValidation('', 'name'));
      dispatch(setInstance('', 'name'));
      dispatch(setInstance(value, 'name'));
      return;
    }
    dispatch(setInstance(value, 'name'));
  }, [dispatch]);

  const showMsg = useCallback(() => {
    if (isWarning) return glossary.notifications.errors.character_limit_reached_64_;
    if (validationName) return glossary.notifications.errors.please_enter_name;
    return '';
  }, [isWarning, validationName]);

  return (
    <div
      id='Instance Description'
      className='min-w-[310px] relative'
    >
      <CustomInput
        inputFeedback={showMsg()}
        inputStatus={isWarning ? 'warning' : 'validation'}
        label='Name'
        type='text'
        maxLength={64}
        // showMaxLengthValue
        ref={ref}
        name='name-instance'
        value={name}
        handleChange={(event) => handleChange(event)}
        placeholder='Name'
        classNames={`${ isWarning ? warningClass : ''}${ validationName ? validationStyle : ''}`}
        required={true}
        testDataId='vm_name_input_field'
      />
    </div>
  );
});

export default InstanceName;
