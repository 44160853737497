import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import Table from 'components/table-component/Table';
import BodyRow from 'components/table-component/Cell';
import { matchClusterUUID } from 'helpers/MatchUUIDs';
import MenuComponent from 'components/menu';
import { IIpv4 } from 'types';
import Row from 'components/table-component/Row';
import CustomCheckbox from 'components/input/Checkbox';
import CopyComponent from 'feature/copy-func';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import glossary from '../../data/glossary.json';

type TProps = {
  handleSelectItems: (event: ChangeEvent<HTMLInputElement>, uuid: string) => void,
  data: IIpv4[],
  handleDeselectItems: () => void,
  isAllSelected: boolean,
  isShowCheckbox: boolean,
  handleSelectedAllItems: () => void,
  dataTestId: string,
};

export default function IPv4TableComponent({
  handleSelectItems,
  data,
  handleDeselectItems,
  isAllSelected,
  isShowCheckbox,
  handleSelectedAllItems,
  dataTestId,
}: TProps) {

  const headData = [glossary.ipv4, glossary.cluster, 'Attached'];
  const commonClassesIsIpAttached = 'text-center w-10 bg-opacity-[0.15] rounded-md';
  const ipsWithoutVms = data.every((ip: IIpv4) => ip.vm_uuid) ? true : false;
  const { handlePagePaths } = useGetRoutesPagePath();

  return (
    <Table
      handleDeselectItems={handleDeselectItems}
      headData={headData}
      isAllSelected={isAllSelected}
      isShowCheckbox={isShowCheckbox}
      handleSelectedAllItems={handleSelectedAllItems}
      disabledCheckbox={ipsWithoutVms}
      dataTestId={dataTestId}
    >
      <tbody className='divide-y divide-gray-200 bg-white'>
        {data?.map((ip: IIpv4) => {
          const element = (number: number) => ip.uuid === data.at(number)?.uuid;
          const lastElement = element(-1) || element(-2) ? true : false;
          return (
            <Row key={ip.uuid}>
              <BodyRow>
                <div className='mx-6 flex'>
                  <CustomCheckbox
                    type='checkbox'
                    name='isSelected'
                    handleChange={(event) => handleSelectItems(event, ip.uuid)}
                    value={ip.address4}
                    aria-label='isSelected'
                    isSelected={ip.isSelected || false}
                    disabled={!ip.vm_uuid ? false : true}
                  />
                </div>
              </BodyRow>
              <BodyRow>
                <div className='row'>
                  <Link to={handlePagePaths(ip.uuid).ipv4}>
                    <>{ip.address4}</>
                  </Link>
                  <CopyComponent
                    value={ip.address4}
                    dataTestId='test_id_ipv4_page_ipv4_address_tooltip'
                  />
                </div>
              </BodyRow>
              <BodyRow>
                <div>
                  {matchClusterUUID(ip?.cluster_uuid, false)}
                </div>
              </BodyRow>
              <BodyRow>
                <div className='all-items-center'>
                  {ip.vm_uuid ?
                    <div className={`text-[#7EE896] bg-[#7EE896] ${commonClassesIsIpAttached}`}>
                      Yes
                    </div>
                    :
                    <div className={`bg-[#ec4d4d] text-[#ec4d4d] ${commonClassesIsIpAttached}`}>
                      No
                    </div>
                  }
                </div>
              </BodyRow>
              <BodyRow>
                <MenuComponent
                  uuid={ip.uuid}
                  lastElement={lastElement}
                  actions={!ip.vm_uuid ? ['DELETED', 'view'] : ['view']}
                  url='network/ipv4'
                  route={handlePagePaths(ip.uuid).ipv4}
                  page='ips'
                  fetchMethod='delete'
                  itemName={ip.address4}
                />
              </BodyRow>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};
