import React from 'react';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';

import classes from './summary.module.scss';

type TProps = {
  name: string,
  handleRemoveTag: (value: string) => void;
  value: string,
};

export default function Tag(props: TProps) {
  const { name, handleRemoveTag, value } = props;
  const { tagClass } = classes;

  return (
    <div className={tagClass}>
      <p className='text-xs font-medium truncate w-14'>{name}</p>
      <div onClick={() => handleRemoveTag(value)}>
        <ArchiveBoxXMarkIcon
          className='w-4 fill-customTextBlack2withOpacity1 cursor-pointer hover:fill-customDarkRed'
        />
      </div>
    </div>
  );
};
