import DocIcon from 'assets/icons/navbar/doc.svg';
import SupportIcon from 'assets/icons/navbar/support.svg';
import StatusIcon from 'assets/icons/navbar/status.svg';
import APIIcon from 'assets/icons/navbar/api.svg';

const LINKS: {
  href: string,
  title: 'Documentation' | 'Support' | 'Status' | 'Swagger API',
  icon: string,
}[] = [
  {
    href: 'https://documenation.scrollhelp.site/docs',
    title: 'Documentation',
    icon: DocIcon,
  },
  {
    href: 'https://northerndata.atlassian.net/servicedesk/customer/portal/12',
    title: 'Support',
    icon: SupportIcon,
  },
  {
    href: 'https://status-cloudservices.northerndata.de',
    title: 'Status',
    icon: StatusIcon,
  },
  {
    href: 'https://api.nd-cloud.com/docs#',
    title: 'Swagger API',
    icon: APIIcon,
  },
];

export default LINKS;
