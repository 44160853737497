import React, { useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { IModalForm } from 'types';
import { api } from 'helpers/api';
import { INetwork, INewNetwork } from 'types';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setFetchFunc, setNewNetwork } from 'actions/dashboard';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { useInstance } from 'context/instance';
import { TDataTestIdsPortalModal } from 'data-test-ids/types/general-pages/portalModal';
import { setInstance, setInstanceValidation } from 'actions/instance';
import { useTenants } from 'context/tenants';
import { apiUrls } from 'helpers/apiUrls';

import Content from './Content';

import glossary from '../../../data/glossary.json';

type TProps = IModalForm & {
  dataTestIds: TDataTestIdsPortalModal,
  page: 'Networks' | 'New virtual machine',
};

export default function CreateNewNetworkForm({onCancel, open, page, dataTestIds}: TProps) {
  // state
  const [nameError, setNameError] = useState<string>('');
  const [clusterError, setClusterError] = useState<string>('');

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc, newNetwork } = stateDashboard;
  const { loading, status } = fetchFunc.network.post;
  const { data: allNetworks } = fetchFunc.networks.get;
  const fetchMethod = 'post';
  const handleFetchRequest = useHandleFetchRequest();
  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const { instance: { location } } = stateInstance;
  const uuid = location === 'Sweden' ?
    'bb7b0a68-265f-4734-a4ea-89911b2ddff7' :
    '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5';
  const clusterUuid = page === 'Networks' ? newNetwork.cluster_uuid : uuid;
  const url = `${apiUrls.networks}${clusterUuid}/${selectedTenantId}?network_name=${newNetwork.network_name}`;
  const hideBtns = status > 0 || loading ? true : false;

  // func
  const handleSubmit = async () => {
    const newNetworkWithDefaultCluster = {
      network_name: newNetwork.network_name,
      cluster_uuid: uuid,
      tenant_uuid: selectedTenantId,
    };

    if (!newNetwork.network_name) {
      return setNameError(glossary.notifications.errors.please_enter_name);
    };
    if (page === 'Networks' && !newNetwork.cluster_uuid) {
      return setClusterError(glossary.notifications.errors.please_select_cluster);
    };

    const newNetworkParams = page === 'Networks' ? newNetwork : newNetworkWithDefaultCluster;

    if (selectedTenantId) {
      const request = api.useFetchData<INewNetwork, INetwork>(url, fetchMethod, null, newNetworkParams);
      handleFetchRequest('network', fetchMethod, request);
      const getNewNetwork: INetwork = await request;
      dispatchDashboard(setFetchFunc([...allNetworks, getNewNetwork], 'networks', 'get', 'data'));
      dispatchInstance(setInstance(getNewNetwork, 'network'));
      dispatchInstance(setInstanceValidation(false, 'network'));
      dispatchInstance(setInstance('', 'error'));
      onCancel();
    };
  };

  const handleCloseModalForm = () => {
    dispatchDashboard(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
    onCancel();
  };

  return (
    <PortalModal
      open={open}
      onCancel={handleCloseModalForm}
      dataTestId={dataTestIds.portalModal.name}
    >
      <FormModalTemplate
        handleSubmitButtonName={glossary.actions.create}
        onCancel={handleCloseModalForm}
        handleSubmit={handleSubmit}
        header={`${glossary.actions.create} ${glossary.network}`}
        hideBtns={hideBtns}
        dataTestIds={dataTestIds.portalModal.form}
      >
        <Content
          handleClusterError={setClusterError}
          handleNameError={setNameError}
          nameError={nameError}
          clusterError={clusterError}
          page={page}
          dataTestIds={dataTestIds.portalModal.form.content}
        />
      </FormModalTemplate>
    </PortalModal>
  );
};
