import { useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { useInstance } from 'context/instance';
import useOnClickOutside from 'hooks/useClickOutside';
import { TVersion, TImage } from 'types';
import useSelectImage from 'hooks/useSelectImage';

import classes from './image.module.scss';

type TProps = {
  imagesVersions: TVersion[],
  image: TImage,
  selectedImageGroupId: number,
  handleChooseImageGroupId: (id: number) => void,
  imageGroupId: number,
};

const Dropdown = (props: TProps) => {
  // state
  const [openDropdown, setOpenDropdown] = useState(false);

  // props
  const { imagesVersions, imageGroupId, handleChooseImageGroupId, image, selectedImageGroupId } = props;

  // variables
  const ref = useRef(null);
  const context = useInstance();
  // const dispatch = context[1];
  const state = context[0];
  const { instance: { image: versionOfImage }, imageGroup } = state;
  const { handleSelectedImageGroup, handleSelectedImage } = useSelectImage();


  const activeStyle = (versionOfImage.uuid !== '') && (imageGroupId === imageGroup);
  const dropDownValue = activeStyle ? versionOfImage.name : 'Version';

  // style
  const {
    selectItemClass,
    selectedVersionClass,
    dropdownItemClass,
    dropdownBodyClass,
    openClass,
    dropdownDefaultValueClass,
    dropdownBodyMultyClass,
  } = classes;

  // func
  const handleClickOutside = () => {
    setOpenDropdown(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleOpenDropdown = (imageGroupId: number) => {
    handleChooseImageGroupId(imageGroupId);
    setOpenDropdown(true);
  };

  const handleChooseVesionOfImageAndImageGroup = (version: TVersion) => {
    // @ts-ignore
    handleSelectedImageGroup(version.image_group_id);
    // @ts-ignore
    handleSelectedImage(version);
    setOpenDropdown(false);
  };


  return (
    <>
      <button
        type='button'
        className={selectItemClass}
        onClick={() => handleOpenDropdown(image.id)}
      >
        <p className={`truncate w-44 text-start ${!activeStyle ? dropdownDefaultValueClass : null}`}>
          {dropDownValue}
        </p>
        {openDropdown && image.id === selectedImageGroupId ?
          <ChevronUpIcon className={`w-5 h-5 ${openDropdown ? openClass : ''}`}/> :
          <ChevronDownIcon className={`w-5 h-5 ${openDropdown ? openClass : ''}`}/>
        }
      </button>
      {openDropdown && image.id === selectedImageGroupId &&
        <div ref={ref} className={`${dropdownBodyClass} ${imagesVersions.length > 1 ? dropdownBodyMultyClass : ''} ${openDropdown && openClass} z-10`}>
          {imagesVersions.map((version: TVersion) => (
            <div
              key={version.uuid}
              className={`${versionOfImage.uuid === version.uuid ? selectedVersionClass : ''} ${dropdownItemClass} ${imagesVersions.length === 1 ? 'rounded-md' : ''}`}
              onClick={versionOfImage.uuid === version.uuid ? () => setOpenDropdown(false) : () => handleChooseVesionOfImageAndImageGroup(version)}
              data-testid={version.uuid}
            >
              {version.name}
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default Dropdown;
