import React from 'react';

import CopyComponent from 'feature/copy-func';

type TProps = {
  isShowIpv4: boolean,
  ipv6: string,
  ipv4: string,
  isIpv4sMoreThan1: string | null,
};

export default function RowIps(props: TProps) {
  // props
  const {
    isShowIpv4 = false,
    ipv6 = '',
    ipv4 = '',
    isIpv4sMoreThan1 = null,
  } = props;

  // variables
  const ipv4WithoutPrefix = ipv4?.replace('/32', '');
  const ipv6WithoutPrefix = ipv6?.replace('/64', '');

  return (
    <div className='col py-2 pr-2'>
      <div className='row'>
        <div className='text-customColorDarkBlue60'>{ipv6}</div>
        <CopyComponent
          value={ipv6WithoutPrefix}
          dataTestId='test_id_vms_page_ipv6_tooltip'
        />
      </div>
      {isShowIpv4 &&
        <div className='row'>
          <div className='text-customColorDarkBlue60'>
            {ipv4}{isIpv4sMoreThan1}
          </div>
          <CopyComponent
            value={ipv4WithoutPrefix}
            dataTestId='test_id_vms_page_ipv4_tooltip'
          />
        </div>
      }
    </div>
  );
};
