import {
  SET_TAB, SET_INSTANCE,
  SET_INSTANCE_VALIDATION,
  SET_FLAVORS,
  SET_FLAVORS_FILTERS,
  SET_IMAGE_GROUP,
} from 'reducers/instance';
import { ITabsItemFlavor } from 'types';

type TInstanceKey =
  'isLeavePage' |
  'name' |
  'sshKeys' |
  'network' |
  'image' |
  'location' |
  'ips' |
  'notAttachedIpv4s' |
  'selectedIpv4' |
  'preselectedIpv4s' |
  'flavor' |
  'startup' |
  'error' |
  'selectedCluster' |
  'sshKey'

export function setTab(tab: string) {
  return {
    type: SET_TAB,
    tab,
  };
};

export function setInstance(instance: {}, key: TInstanceKey) {
  return {
    type: SET_INSTANCE,
    instance,
    key,
  };
};

export function setInstanceValidation(validation: {}, key: string) {
  return {
    type: SET_INSTANCE_VALIDATION,
    validation,
    key,
  };
};

export function setFlavors(flavors: ITabsItemFlavor[]) {
  return {
    type: SET_FLAVORS,
    flavors,
  };
};

export function setFlavorsFilters(
  filterFlavors: ITabsItemFlavor[],
  key: 'A6000' | 'H100' | 'A100',
) {
  return {
    type: SET_FLAVORS_FILTERS,
    filterFlavors,
    key,
  };
};

export function setImageGroup(imageGroup: string | number) {
  return {
    type: SET_IMAGE_GROUP,
    imageGroup,
  };
}
