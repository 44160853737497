import { useTenants } from 'context/tenants';
import { setSelectedTenantId, setSelectedTenant, setPreselectedTenant } from 'actions/tenants';

/**
 * @description useChangeTenant - a hook which calls a function that changes the tenant
 * and writes a new object to reducer and localStorage
 * @returns changeTenant, resetTenant
 */

export function useChangeTenant() {
  // variables
  const context = useTenants();
  const state = context[0];
  const dispatch = context[1];
  const { selectedTenant, tenants: { data } } = state;

  // func
  const changeTenant = (name: string, uuid: string) => {
    dispatch(setSelectedTenantId(uuid));
    dispatch(setSelectedTenant({...selectedTenant, uuid: uuid, customer_name: name}));
    dispatch(setPreselectedTenant({...selectedTenant, uuid: uuid, customer_name: name}));
    localStorage.setItem('tenantsStorage', JSON.stringify(data));
    localStorage.setItem('selectedTenantIdStorage', uuid);
    localStorage.setItem('selectedTenantStorage', JSON.stringify({
      ...selectedTenant,
      uuid: uuid,
      customer_name: name,
    }));
    localStorage.removeItem('cache-i');
    localStorage.removeItem('cache-f');
  };

  /**
   * @description resetTenant
   * clears the preselected tenant and saves the old tenant to setPreselectedTenant,
   * used on the new vm page when switching to other pages on the cancel click
   */
  const resetTenant = () => {
    dispatch(setPreselectedTenant({...selectedTenant}));
  };

  return { changeTenant, resetTenant };
};
